import React from 'react';
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import Select from '@material-ui/core/Select';
import { Input, InputLabel } from '@material-ui/core';
import MultipleSelect from '../../../Pictures/RegionSelect'
import FormControl from '@material-ui/core/FormControl';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import UsergroupSelect from '../../../Pictures/UsergroupSelect';

class AddOffers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desc: "",
            price: "",
            email: "",
            category: "",
            regions:[],
            usergroups:[],
            OneOffer:[],
            isNew: false,
            selectedRegions: [],
            SelectedUsergroups: [],
            updatedRegion: [],
            updatedUsergroups: [],
            categories:[]
        }
    }
    componentWillMount() {
        this.props.GetAllUserGroup();
        this.props.GetAllRegion();
        this.props.GetAllCategory();
        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.props.GetOffersById(this.props.match.params.id);
        }
        else {
            this.setState({
                OneOffer: {
                    "regions": [],
                    "usergroups": [],
                    "desc": "",
                    "price": 0,
                    "category": "",
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.usergroups) {
            this.setState({ usergroups: nextProps.usergroups[0] });
          }
          if (nextProps.regions) {
            this.setState({ regions: nextProps.regions[0] });
          }
          if (nextProps.categories) {
            this.setState({ categories: nextProps.categories[0] });
          }
        if (nextProps.OneOffer.length > 0 & this.state.isNew) {
            this.setState({ OneOffer: nextProps.OneOffer[0] });
            this.setState({
                desc: nextProps.OneOffer[0]?.desc,
                price: nextProps.OneOffer[0]?.price,
                category: nextProps.OneOffer[0]?.category,
                updatedRegion: nextProps.OneOffer[0]?.regions,
                updatedUsergroups: nextProps.OneOffer[0]?.usergroups
            })
            if(this.state.SelectedUsergroups.length < 1){
                nextProps.OneOffer[0].usergroups.forEach(element => {
                  this.getLabelUserGroups(element)
                });
              }
              if(this.state.selectedRegions.length < 1){
                nextProps.OneOffer[0].regions.forEach(element => {
                  this.getLabelRegions(element)
                });
              }
    }
    }
    saveHandler = () => {
        if (this.state.isNew) {
            var data = {
                "Id": this.props.match.params.id,
                "regions": this.state.updatedRegion,
                "usergroups": this.state.updatedUsergroups,
                "desc": this.state.desc,
                "price": this.state.price,
                "category": this.state.category,
            }
            this.props.EditOffers(data);
        }
        else {
            var data = {
                "regions": this.state.updatedRegion,
                "usergroups": this.state.updatedUsergroups,
                "desc": this.state.desc,
                "price": this.state.price,
                "category": this.state.category,
            }
            this.props.AddOffers(data);
        }
    }
    handleRegion = (ids) => {
        this.setState({ updatedRegion: ids });
    }
    handleUsergroup = (ids) => {
        this.setState({ updatedUsergroups: ids });
    }
    handleForm = (event, val) => {
        if (val  === "desc") {
            this.setState({ desc: event.target.value })
        }
        else if (val  === "price") {
            this.setState({ price: event.target.value })
        }

    }
    handleChange =(event)=>{
        this.setState({category: event.target.value});
    }
    getLabelUserGroups = (value) => {
        const index = this.state.usergroups?.findIndex((d) => d._id === value);
        var ref = this.state.SelectedUsergroups;
        if (index !== -1) {
          ref.push(this.state.usergroups[index]._id);
        }
        this.setState({ SelectedUsergroups: ref })
      };
      getLabelRegions = (value) => {
        const index = this.state.regions?.findIndex((d) => d._id === value);
        var ref = this.state.selectedRegions;
        if (index !== -1) {
          ref.push(this.state.regions[index]._id);
        }
        this.setState({ selectedRegions: ref })
      };
    render() {
        return (<div>  <PageMenu heading="Add Offers"
                      open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                    backArrow={true}
                    backTitle={'Offers'}
                    backUrl={'/offer'}
                    SaveBtn={true}
                    saveHandler={this.saveHandler} />
                <div className={`upper-form ${this.props.open?"upper-form-open":""}`}>
                    <div className="form-head">
                        <span>Basic Info</span>
                    </div>
                    <div className="admin-form">
                        <div className="flex">
                            <InputLabel>BESCHRIJVING  </InputLabel>
                            <Input type="text"
                                value={this.state.desc}
                                onChange={(event) => this.handleForm(event, "desc")} />
                        </div>
                        <div className="flex">
                            <InputLabel>€ </InputLabel>
                            <Input type="number"
                                value={this.state.price}
                                step=".01"
                                onChange={(event) => this.handleForm(event, "price")} />
                        </div>
                        <FormControl className="formControl">
        <InputLabel htmlFor="age-native-simple">CATEGORIE  </InputLabel>
        <Select
          native
          value={this.state.category}
          onChange={this.handleChange}
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
         {this.state.categories?.map(x=>
             <option value={x._id}>{x.name}</option>)}
        </Select>
      </FormControl>
                        
                        {/* <InputLabel>BESTEMMING </InputLabel>

                        <FormControl>
                            <RadioGroup>
                                <FormControlLabel value="Bu" control={<Radio color="primary" />} label="Bu" />
                                <FormControlLabel value="Cu" control={<Radio color="primary" />} label="Cu" />
                            </RadioGroup>
                        </FormControl> */}

                      
                    {this.state.regions?.length > 0 && this.state.selectedRegions ?
                       <MultipleSelect handleRegion={this.handleRegion}
                       regions={this.state.regions}
                       selectedRegion={this.state.selectedRegions}
                       label="Select Region" />
                        :null}

                    {this.state.usergroups?.length > 0 && this.state.SelectedUsergroups ?
                        <UsergroupSelect handleRegion={this.handleUsergroup}
                            regions={this.state.usergroups}
                            selectedRegion={this.state.SelectedUsergroups}
                            label="UserGroup" />
                        : null}
                    </div>
                </div>
            
        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        usergroups: state.OfferReducer.usergroups,
        regions: state.OfferReducer.regions,
        OneOffer: state.OfferReducer.OneOffer,
        categories:state.OfferReducer.categories
    });

const mapDispatchToProps = dispatch => {
    return {
        GetOffersById: (id) => dispatch(actionCreators.GetOffersById(id)),
        EditOffers: (data) => dispatch(actionCreators.EditOffers(data)),
        AddOffers: (data) => dispatch(actionCreators.AddOffers(data)),
        GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
        GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
        GetAllCategory: () => dispatch(actionCreators.GetAllCategory())
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOffers))