import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import James from '../Assets/Images/avatar/james.jpg'
import jane from '../Assets/Images/avatar/jane.jpg'



class ChatSidebar extends React.Component{
    render(){
        return(
            <div className={`chat-sidebar ${this.props.toggle?"chat-sidebar-open":""}`}>
                <div className="chat-sidebar-top">
                    <h3>Maarten Sole</h3>
                </div>
                <div className="chat-sidebar-contacts">
                    <div className="chat">
                        <div className="chat-heading">
                            <p>Chat</p>
                            {this.props.chatData.map(x=>
                                <div className="chat-list" onClick={()=>this.props.handleChat(x.name)}>
                                    <Avatar style={{position:"absolute",  display:"inline-flex"}} alt={x.name} src={James} />
                                    <p className="name">{x.name}<span  className="date">{x.date}</span></p>
                                </div>
                                )}
                        </div>
                    </div>
                    <div className="contacts">
                    <div className="chat-heading">
                            <p>Contact</p>
                            {this.props.chatDataContact.map(x=>
                                <div className="chat-list" onClick={()=>this.props.handleChat(x.name)}>
                                     <Avatar style={{position:"absolute", display:"inline-flex"}} alt={x.name} src={jane} />
                                    <p className="name">{x.name}<span  className="date">{x.date}</span></p>
                                </div>
                                )}
                        </div>

                    </div>
                </div>

            </div>
        )
    }
    }
export default ChatSidebar;