import React from "react";
import SideNavPage from "../../Sidebar/SideNavPage";
import PageMenu from "../../page-menu/PageMenu";
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Input, InputLabel } from "@material-ui/core";
import UsergroupSelect from "../../Pictures/UsergroupSelect";
import SubcategoriesSelect from "../../Pictures/SubcategoriesSelect";

class AddCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserGroup: [],
      sub_cat: [],
      Name: "",
      isNew: false,
      OneCategory: [],
      UpdatedUserGroup: [],
      UpdatedRegion: [],
      SelectedRegion: [],
      usergroups: [],
      SelectedUsergroup: [],
      subcategories: [],
      selectedSUbCategory: [],
      UpdatedSUbCategory: [],
    };
  }
  componentWillMount() {
    this.props.GetAllUserGroup();
    this.props.GetAllSubCategories();
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.props.GetCategoryById(this.props.match.params.id);
    } else {
      this.setState({
        OneCategory: {
          usergroups: [],
          sub_cat: [],
          Name: "",
        },
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.subcategories) {
      this.setState({ subcategories: nextProps.subcategories[0] });
    }
    if ((nextProps.OneCategory.length > 0) & this.state.isNew) {
      this.setState({ OneCategory: nextProps.OneCategory[0] });
      this.setState({
        Name: nextProps.OneCategory[0]?.name,
       
      });
      var us = [];
      nextProps.OneCategory[0].subCategory.forEach(element => {
        us.push(element?._id);
      });
      this.setState({UpdatedSUbCategory:us})
      var ug = [];
      nextProps.OneCategory[0].userGroup.forEach(element => {
        ug.push(element?._id);
      });
      this.setState({UpdatedUserGroup:ug});


      if(this.state.selectedUserGroup.length < 1){
      nextProps.OneCategory[0].userGroup.forEach(element => {
        this.getLabelUserGroups(element?._id)
      });
    }
    if(this.state.selectedSUbCategory.length < 1){
      nextProps.OneCategory[0].subCategory.forEach(element => {
        this.getLabelSubCategory(element?._id)
      });
    }
    }
  }
  handleForm = (event, val) => {
    if (val === "Name") {
      this.setState({ Name: event.target.value });
    }
  };
  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  saveHandler = () => {
    if (this.state.isNew) {
      var data = {
        Id: this.props.match.params.id,
        userGroup: this.state.UpdatedUserGroup,
        subCategory: this.state.UpdatedSUbCategory,
        name: this.state.Name,
      };
      this.props.UpdateCategory(this.props.match.params.id, data);
    } else {
      var data = {
        userGroup: this.state.UpdatedUserGroup,
        subCategory: this.state.UpdatedSUbCategory,
        name: this.state.Name,
      };
      this.props.AddCategory(data);
    }
  };
  handleUsergroup = (ids) => {
    this.setState({ UpdatedUserGroup: ids });
  };
  handleRegion = (ids) => {
    this.setState({ UpdatedRegion: ids });
  };
  handleSUbCategory = (ids) => {
    this.setState({ UpdatedSUbCategory: ids });
  };
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    var ref =  this.state.selectedUserGroup;
    if (index !== -1) {
      ref.push(this.state.usergroups[index]._id);
    }
    this.setState({selectedUserGroup:ref})
  };
  getLabelSubCategory= (value) => {
    const index = this.state.subcategories?.findIndex((d) => d._id === value);
    var ref =  this.state.selectedSUbCategory;
    if (index !== -1) {
      ref.push(this.state.subcategories[index]._id);
    }
    this.setState({selectedSUbCategory:ref})
  };
  render() {
    return (
      <div>
        <PageMenu
          heading="Add Categories"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          backArrow={true}
          backTitle={"Categories"}
          backUrl={"/categories"}
          SaveBtn={true}
          saveHandler={this.saveHandler}
        />
        <div
          className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}
        >
          <div className="form-head">
            <span>Basic Info</span>
          </div>
          <div className="admin-form">
            <div className="flex">
              <InputLabel>CATEGORIE</InputLabel>
              <Input
                type="text"
                value={this.state.Name}
                onChange={(event) => this.handleForm(event, "Name")}
              />
            </div>

            {this.state.usergroups?.length > 0 &&
            this.state.selectedUserGroup ? (
              <UsergroupSelect
                handleRegion={this.handleUsergroup}
                regions={this.state.usergroups}
                selectedRegion={this.state.selectedUserGroup}
                label="GEBRUIKERS GROEPEN"
              />
            ) : null}
            {this.state.subcategories?.length > 0 &&
            this.state.selectedSUbCategory ? (
              <SubcategoriesSelect
                handleRegion={this.handleSUbCategory}
                regions={this.state.subcategories}
                selectedRegion={this.state.selectedSUbCategory}
                label="Subcategorieën"
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  OneCategory: state.CategoryReducer.OneCategory,
  usergroups: state.CategoryReducer.usergroups,
  subcategories: state.CategoryReducer.subcategories,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetCategoryById: (id) => dispatch(actionCreators.GetCategoryById(id)),
    UpdateCategory: (id, data) =>
      dispatch(actionCreators.UpdateCategory(id, data)),
    AddCategory: (data) => dispatch(actionCreators.AddCategory(data)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
    GetAllSubCategories: () => dispatch(actionCreators.GetAllSubCategories()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCategories)
);
