import React from 'react';
import DropFile from './DropFile'
import ImageCard from './ImageCard'
import {Row, Col} from 'reactstrap';


export default class Image extends React.Component {
    constructor(props){
        super(props)
        this.state={
            isShowImage:true,
        }
    }
    handleRemoveCard=()=>{
        this.setState({isShowImage:false})
    }
    render() {
        return (
            <div>
                <Row>
                {this.props.isNew && this.state.isShowImage ?
                <Col md="6" sm="12">
                    <div className="image-card">
                        <ImageCard handleRemoveCard={this.handleRemoveCard} img={`http://api.beelicensed.com/uploads/${this.props.imageFileName}`} />
                    </div>
                    </Col>
                    : null}
                    <Col md="6" sm="12">
                   <div className="drop-file">
                    <DropFile onFormSubmit={this.props.onFormSubmit}
                        onChange={this.props.onChange}
                        handleFileAdded={this.props.handleFileAdded}
                        file={this.props.file} />
                </div>
                </Col>
                </Row>
            </div>
        )
    }
}