import { axiosClient } from "../../../../helper";
import { appStatusAction } from "../../../../reducer";

// Loyality Actions
export function DeleteBULoyaltyById(id) {
  return (dispatch) => {
    return axiosClient()
      .post(`/DeletBULoyalty`, { Id: id })
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(DeleteBULoyaltyByIdSuccess(res.data));
        dispatch(appStatusAction("BU Loyalty Successfully Deleted"));
      });
  };
}

export function DeleteBULoyaltyByIdSuccess(data) {
  return {
    type: "DELETE_LOYALTY",
    DeleteBuLoyalty: data,
  };
}

export function GetBULoyaltyById(id) {
  return (dispatch) => {
    return axiosClient()
      .post(`/GetBULoyaltyById`, { Id: id })
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetBULoyaltyByIdSuccess(res.data.data));
      });
  };
}

export function GetBULoyaltyByIdSuccess(data) {
  return {
    type: "GET_ONE_LOYALTY",
    OneBuLoyalty: data,
  };
}
export function EditBULoyaltyById(data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/EditBULoyalty`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(EditBULoyaltyByIdSuccess(res.data.data));
        dispatch(appStatusAction("BU Loyalty Successfully Edited"));
      });
  };
}

export function EditBULoyaltyByIdSuccess(data) {
  return {
    type: "Edit_LOYALTY",
    OneBuLoyalty: data,
  };
}
export function getBuLoyalty() {
  return (dispatch) => {
    return axiosClient()
      .get(`/GetAllBULoyalty`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(getBuLoyaltySuccess(res.data.data));
      });
  };
}

export function getBuLoyaltySuccess(data) {
  return {
    type: "GET_LOYALTY",
    BuLoyalty: data,
  };
}

export function GetAllRegion() {
  return (dispatch) => {
    return axiosClient()
      .get(`/GetAllRegion`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllRegionSuccess(res.data.data));
      });
  };
}

export function GetAllRegionSuccess(data) {
  return {
    type: "GET_REGION",
    regions: data,
  };
}
export function AddBULoyalty(data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/AddBULoyalty`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(AddBULoyaltySuccess(res.data.data));
        dispatch(appStatusAction("BU Loyalty Successfully Added"));
      });
  };
}

export function AddBULoyaltySuccess(data) {
  return {
    type: "ADD_LOYALTY",
    OneBuLoyalty: data,
  };
}
