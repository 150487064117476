let defaultState = {
    BuLoyalty: [],
    OneBuLoyalty: [],
    DeleteBuLoyalty: [],
    regions:[],
}

const BuReducer = (state = defaultState, action) => {
    switch (action.type) {

        case "GET_LOYALTY":
            return {
                ...state,
                BuLoyalty: [action.BuLoyalty]
            }
        case "GET_ONE_LOYALTY":
            return {
                ...state,
                OneBuLoyalty: [action.OneBuLoyalty]
            }
        case "Edit_LOYALTY":
            return {
                OneBuLoyalty: [action.OneBuLoyalty]
            }
        case "DELETE_LOYALTY":
            return {
                ...state,
                DeleteBuLoyalty: [action.DeleteBuLoyalty]
            }
        case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }

        default:
            return {
                ...state
            }
    }
}
export default BuReducer;