import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

class ChatApp extends React.Component{
    render(){
        return(
            <div className={`chat-body ${this.props.toggle?"chat-body-close":""}`}>
                {this.props.isSelected?
                <div className="chat-body-top">
                    <div>
                    <ListAltOutlinedIcon onClick={this.props.handleToggle} style={{color:"#fff"}} />
                    </div>
                    <div style={{width:"100%"}}>
                <Avatar style={{position:"absolute"}} alt={this.props.name} src="/static/images/avatar/1.jpg" />
                </div>
                    <div>
                     <h2 className="heading">{this.props.name}</h2>
                     </div>
                </div>
                :null}
                <div className="chat-body-area">
                <div className="chat-body-inner">
                    {!this.props.isSelected?<div className="before-msg">
                         <p>
                         Select a contact to start a conversation!.
                         </p>
                         <button onClick={this.props.handleToggle}>Select a contact to start a conversation!.</button>
                    </div>
                    :null}
                    {this.props.chat.map(x=>
                     <div className="chat-area-inner">
                        <div className={x.type}>
                            <p>{x.message}</p>
                       </div>
                       </div>
                        )}
           </div>
           {this.props.isSelected?
           <div className="send-message">
               <input value={this.props.value} onChange={(event)=>this.props.handleInput(event)} type="text" placeholder="Write Message Here!"/>
               <button onClick={this.props.handleSend}>Send</button>
           </div>
           :null}
                </div>

            </div>
        )
    }
    }
export default ChatApp;