import React, { useState } from "react";
import { MDBInput, MDBBtn } from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "./Login.css";
import { useDispatch } from "react-redux";
import { UserLogin } from "../../reducer";

function Login() {
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setFormValues({ ...formValues, username: event.target.value });
  };
  const handleChangePass = (event) => {
    setFormValues({ ...formValues, password: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(UserLogin(formValues));
  };

  return (
    <div>
      <div className="LoginContainer">
        <div className="LoginInner">
          <div className="coupon_image"></div>

          <form className="LoginForm" onSubmit={handleSubmit}>
            <br />
            <br />
            <MDBInput
              label="gebruikersnaam"
              icon="user"
              group
              type="Text"
              validate
              error="wrong"
              onChange={handleChange}
              success="right"
              required
              name="User"
            />
            <MDBInput
              label="wachtwoord"
              icon="lock"
              group
              type="password"
              minLength="9"
              validate
              required
              name="Pass"
              onChange={handleChangePass}
            />
            <div className="text-center mb-3">
              <MDBBtn
                type="Submit"
                gradient="blue"
                rounded
                className="btn-block z-depth-1a"
              >
                Inloggen{" "}
              </MDBBtn>
            </div>
          </form>
          <div className="form-footer footer-signin">
            {/* <div align="center">
                <p className="text-info">
                  <small>
                    <span className="bracket-link">
                      [ &nbsp; <Link to="/register"> registreren </Link>&nbsp;]
                    </span>{" "}
                    &nbsp;{" "}
                    <span className="bracket-link">
                      {" "}
                      [ &nbsp; <Link to="/forgot"> wachtwoord vergeten </Link>
                      &nbsp;]
                    </span>{" "}
                  </small>
                </p>{" "}
                ©auteursrecht{" "}
                <Link to="/" target="_blank">
                  {" "}
                  couponcard.nl{" "}
                </Link>{" "}
                2016, 2020{" "}
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
