import React ,{Component} from 'react'

import {Container,Row,Col} from 'reactstrap';
import { Button,Divider } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TotalSales from './TotalSales';
import './Insight.css'


class Insight extends Component{
render(){
    return(
        <div>
            
            <Container className={`navinner dashbaord ${this.props.open?"drawerOpen" : ""}`}>
                <Row>
                    <Col md="12">
                        <div style={{float:"right",padding:"20px"}}>
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button >Week</Button>
            <Button >Month</Button>
          </ButtonGroup>
                        </div>
                    </Col>
                </Row>
                <div style={{padding:"20px"}}>
                    <h2>BU</h2>
                </div>
                <Row>
                    <Col md="6">
                    <TotalSales
							label={'Number of BU'}
                            chartdata={[250, 310, 150, 420, 250, 450,34,56,234,23,34,34]}
                            usergroup={true}
                            color='red'
							labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July','Aug','Sep','Dec']}
								/>
                    </Col>
               
                    <Col md="6">
                    <TotalSales
                            label={'Number of CU Linked to BU'}
                            color='red'
                            usergroup={true}
                            BU={true}
				            chartdata={[130, 310, 150, 308, 250, 345,34,56,23,23,134,34]}
							labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July','Aug','Sep','Dec']}
								/>
                    </Col>
                </Row>
                <Divider />
                <div style={{padding:"20px"}}>
                    <h2>CU</h2>
                </div>
                <Row>
                    <Col md="6">
                    <TotalSales
                            label={'Number of CU by Usergroup'}
                            color='blue'
                            usergroup={true}
                            BU={false}
				            chartdata={[130, 310, 150, 308, 250, 345,34,56,23,23,134,34]}
							labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July','Aug','Sep','Dec']}
								/>
                    </Col>
                    <Col md="6">
                    <TotalSales
                            label={'Number of CU by Region'}
                            color='blue'
                            usergroup={true}
                            BU={false}
				            chartdata={[130, 310, 150, 308, 250, 345,34,56,23,23,134,34]}
							labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July','Aug','Sep','Dec']}
								/>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md="12">
                    <TotalSales
                            label={'Subscription Earning'}
                            color='grey'
				            chartdata={[233, 310, 150, 308, 23, 23,34,56,23,231,123,34]}
							labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July','Aug','Sep','Dec']}
								/>
                    </Col>
                </Row> */}
               

            </Container>
        </div>
    )
}
}
export default Insight;