import React from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Input, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import './Make.scss'


export default function BasicInfo(props) {

    const [value, setValue] = React.useState('Nee');
    const [hand, setHand] = React.useState(false);
    const [auto, setAuto] = React.useState(true);
    const [autoValue, setAutoValue] = React.useState("auto");
    const [name, setName] = React.useState('');
    const [n, setN] = React.useState(0);
    const [keys, setKeys] = React.useState();
    const [keyValue, setKeyValue] = React.useState();
    const [testPoint, setTestPoint] = React.useState();
    const [questions, setQuestions] = React.useState([]);
    const [totalQuestion, settotalQuestion] = React.useState(props.questionsByTopic?.length);
    const [occ, setOccurences] = React.useState();
    const [selectedQuestionCounter, setSelectedQuestionCounter] = React.useState(0);
    const [selectedQuestion, SetselectedQuestion] = React.useState();




    const handleRadioChange = (event) => {

        setAutoValue(event.target.value);
        if (event.target.value === "auto") {
            setHand(false);
            setAuto(true);
        }
        else if (event.target.value === "hand") {
            setHand(true);
            setAuto(false);
        }

    };
    const handleRadioChangeAsk = (event) => {

        setValue(event.target.value);
        props.handleAskRadio(event);

    };
    const autoTestSetting = () => {
        var question = props.questionsByTopic;
        //  var set = [];
        //  question.forEach(element => {
        //      set.push(element.points)
        //      console.log(element)
        //  });
        //  console.log(set);



        var occurences = {};
        for (var i = 0; i < question?.length; i++) {
            if (typeof occurences[question[i].points] == "undefined") {
                occurences[question[i].points] = 1;
            } else {
                occurences[question[i].points]++;
            }
        }
        setN(props.questionsByTopic?.length);
        setKeys(Object.keys(occurences));
        setKeyValue(Object.values(occurences));
        setOccurences(occurences);
    }
    const handleForm = (event) => {
        autoTestSetting()
        if (event.target.value > props.questionsByTopic?.length) {
            alert("Number cannot exceeded limit");
            return;
        }
        settotalQuestion(event.target.value)


    }
    const handleTestMake = (event, x, st) => {
        // console.log(event.target.value)
        // console.log(occ)
        // console.log("keys[event.target.value]",occ[event.target.value])

        if (st === "point_selected") {
            var j = 0;
            for (var i = 0; i < props.questionsByTopic?.length; i++) {
                if (props.questionsByTopic[i].points.toString() === event.target.value.toString()) {
                    if (questions.indexOf(props.questionsByTopic[i]._id) >= 0) {
                        continue;
                    }
                    setQuestions(questions.concat(props.questionsByTopic[i]._id));
                    props.setQuestion(questions);
                    return;
                }
            }
        }
        console.log(questions)

        if (st === "total_question") {
            if (selectedQuestionCounter > totalQuestion) {
                alert("Question cannot exceeded the limit you describe above");
                return;
            }
            SetselectedQuestion(event.target.value)
            console.log(event.target.value)
            setSelectedQuestionCounter(selectedQuestionCounter + event.target.value)
        }

    }
    React.useEffect(() => {
        autoTestSetting()
    }, []);
    return (
        <div>

            <FormControl>
                <RadioGroup value={autoValue} onChange={handleRadioChange}>
                    <FormControlLabel value="auto" control={<Radio color="primary" />} label="Automatisch" />
                    <FormControlLabel value="hand" control={<Radio color="primary" />} label="Handmatig" />
                </RadioGroup>
            </FormControl>
            <div>

                {auto ?
                    <div className="make-text">
                        <div className="flex">
                            <InputLabel>TOTAAL AANTAL VRAGEN <span style={{ color: "red" }}> ALRIGHT YOU ARE GOOD TO GO TOTAL QUESTIONS ARE: {props.questionsByTopic?.length}</span></InputLabel>
                            <input type="number"
                                value={totalQuestion}
                                onChange={(event) => handleForm(event)}
                                min={1}
                                max={props.questionsByTopic?.length}
                            />
                            <div style={{ marginTop: "20px" }}><span style={{ color: "red" }}>Limit Cannot Exceed from: {props.questionsByTopic?.length}</span></div>

                        </div>

                        {[...Array(totalQuestion)]?.map((x, i) =>
                            <div>
                                <div className="text-field">

                                    <FormControl className="formControl">
                                        <InputLabel htmlFor="age-native-simple">PUNTEN  </InputLabel>
                                        <Select
                                            native
                                            value={testPoint}
                                            key={i}
                                            onChange={(event, x) => handleTestMake(event, i, "point_selected")}
                                            inputProps={{
                                                name: 'age',
                                                id: 'age-native-simple',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {keys?.map(key =>
                                                <option value={key}>{key}</option>
                                            )}

                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="text-field text-field-front">

                                    <FormControl className="formControl">
                                        <InputLabel htmlFor="age-native-simple">AANTAL VRAGEN   </InputLabel>
                                        <Select
                                            native
                                            value={selectedQuestion}
                                            key={"totalQuestion" + i.toString()}
                                            onChange={(event, x) => handleTestMake(event, i, "total_question")}
                                            inputProps={{
                                                name: 'totalQuestion',
                                                id: 'age-nativ',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {[...Array(1)]?.map((X, i) =>
                                                <option value={i + 1}>{i + 1}</option>
                                            )}

                                        </Select>
                                    </FormControl>


                                    {/* <InputLabel>AANTAL VRAGEN </InputLabel>
        <Input type="number"
            value={name}
             /> */}
                                </div>
                            </div>
                        )}


                        {/* <div className="text-field">
        <InputLabel>PUNTEN    </InputLabel>
        <Input type="number"
            value={name}
             />  
     
             </div>
             <div className="text-field text-field-front">
        <InputLabel>AANTAL VRAGEN </InputLabel>
        <Input type="number"
            value={name}
             />
             </div>
             <div className="text-field">
        <InputLabel>PUNTEN    </InputLabel>
        <Input type="number"
            value={name}
             />  
             </div>
             <div className="text-field text-field-front">
        <InputLabel>AANTAL VRAGEN </InputLabel>
        <Input type="number"
            value={name}
             />
             </div>
             <div className="text-field">
        <InputLabel>PUNTEN    </InputLabel>
        <Input type="number"
            value={name}
             />  
             </div>
             <div className="text-field text-field-front">
        <InputLabel>AANTAL VRAGEN </InputLabel>
        <Input type="number"
            value={name}
             />
             </div>
             <div className="text-field">
        <InputLabel>PUNTEN    </InputLabel>
        <Input type="number"
            value={name}
             />  
             </div>
             <div className="text-field text-field-front">
        <InputLabel>AANTAL VRAGEN </InputLabel>
        <Input type="number"
            value={name}
             />
             </div> */}

                        <div className="flex" style={{ marginTop: "40px" }}>
                            <InputLabel>BENODIGDE SCORE IN %   </InputLabel>
                            <Input type="number"
                                value={props.passing}
                                onChange={(event) => props.handleForm(event, "passing")}
                            />
                        </div>
                        <InputLabel>VRAGEN </InputLabel>

                        <FormControl>
                            <RadioGroup value={props.askValue} onChange={(event) => handleRadioChangeAsk(event)}>
                                <FormControlLabel value="Nee" control={<Radio color="primary" />} label="vernieuw    " />
                                <FormControlLabel value="Ja" control={<Radio color="primary" />} label="dezelfde" />
                            </RadioGroup>
                        </FormControl>
                        {value === "Ja" ? <div>
                            <InputLabel>SHUFFLE</InputLabel>
                            <FormControl>
                                <RadioGroup value={props.ShuffleValue} onChange={(event) => props.handleRadioShuffle(event)}>
                                    <FormControlLabel value="Nee" control={<Radio color="primary" />} label="dezelfde volgorde" />
                                    <FormControlLabel value="Ja" control={<Radio color="primary" />} label="nieuwe volgorde" />
                                </RadioGroup>
                            </FormControl>
                        </div> : null}
                    </div>

                    : null}
                {hand ? <ul>
                    {props.testQuestions?.map(x =>
                        <li>{x.sub_topic}</li>)}
                </ul> : null}
                {hand ? <div>
                    <div className="flex" style={{ marginTop: "40px" }}>
                        <InputLabel>BENODIGDE SCORE IN %   </InputLabel>
                        <Input type="number"
                            value={name}
                        />
                    </div>
                    <FormControl>
                        <RadioGroup value={"Ja"}>
                            {/* value={value} onChange={(event) => handleRadio(event)} */}
                            <FormControlLabel value="Nee" control={<Radio color="primary" />} label="vernieuw    " />
                            <FormControlLabel value="Ja" control={<Radio color="primary" />} label="dezelfde" />
                        </RadioGroup>

                    </FormControl>
                    <InputLabel>SHUFFLE </InputLabel>
                    <FormControl>
                        <RadioGroup>
                            <FormControlLabel value="def" control={<Radio color="primary" />} label="dezelfde volgorde" />
                            <FormControlLabel value="nie" control={<Radio color="primary" />} label="nieuwe volgorde" />
                        </RadioGroup>
                    </FormControl>

                </div> : null}
            </div>

        </div>
    )
}