import { axiosClient } from '../../../../../helper';
import { appStatusAction } from '../../../../../reducer';


// Loyality Actions
export function DeletQuestion(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletQuestion`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletQuestionSuccess(res.data));
            dispatch(appStatusAction("Question Successfully Deleted"));
        })
    }
}

export function DeletQuestionSuccess(data) {
    return {
        type: "DELETE_QUESTION",
        DeleteQuestion:data
    }
}
export function DeletAnswerEQ(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletAnswerEQ`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletAnswerEQSuccess(res.data));
            dispatch(appStatusAction("Answer Successfully Deleted"));
        })
    }
}

export function DeletAnswerEQSuccess(data) {
    return {
        type: "DELETE_ANSWER",
        DeleteAnswer:data
    }
}

export function GetQuestionById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetQuestionById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetQuestionByIdSuccess(res.data.data));
        })
    }
}

export function GetQuestionByIdSuccess(data) {
    return {
        type: "GET_ONE_QUESTION",
        OneQuestion:data
    }
}
export function GetAnswerEQById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetAnswerEQById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAnswerEQByIdSuccess(res.data.data));
        })
    }
}

export function GetAnswerEQByIdSuccess(data) {
    return {
        type: "GET_ONE_ANSWER",
        OneAnswer:data
    }
}
export function EditQuestion(data){
    return (dispatch) => {

        return axiosClient().post(`/EditQuestion`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditQuestionSuccess(res.data.data));
            dispatch(appStatusAction("Question Successfully Edited"));
        })
    }
}

export function EditQuestionSuccess(data) {
    return {
        type: "EDIT_QUESTION",
        OneQuestion:data
    }
}
export function EditAnswerEQ(data){
    return (dispatch) => {

        return axiosClient().post(`/EditAnswerEQ`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditAnswerEQSuccess(res.data.data));
            dispatch(appStatusAction("Answer Successfully Edited"));
        })
    }
}

export function EditAnswerEQSuccess(data) {
    return {
        type: "EDIT_ANSWER",
        OneAnswer:data
    }
}

export function AddQuestion(data){
    return (dispatch) => {

        return axiosClient().post(`/AddQuestion`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddQuestionSuccess(res.data.data));
            dispatch(appStatusAction("Question Successfully Added"));
        })
    }
}

export function AddQuestionSuccess(data) {
    return {
        type: "ADD_QUESTION",
        OneQuestion:data
    }
}
export function AddAnswerEQ(data){
    return (dispatch) => {

        return axiosClient().post(`/AddAnswerEQ`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddAnswerEQSuccess(res.data.data));
            dispatch(appStatusAction("Answer Successfully Added"));
        })
    }
}

export function AddAnswerEQSuccess(data) {
    return {
        type: "ADD_ANSWER",
        OneQuestion:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}
export function GetAllQuestions() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllQuestions`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllQuestionsSuccess(res.data.data));
        })
    }
}

export function GetAllQuestionsSuccess(data) {
    return {
        type: "GET_QUESTIONS",
        questions:data
    }
}
export function GetAllRegion() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllRegionSuccess(res.data.data));
        })
    }
}

export function GetAllRegionSuccess(data) {
    return {
        type: "GET_REGION",
        regions:data
    }
}