import React from 'react'
import PageMenu from '../../../page-menu/PageMenu'
import * as actionCreators from '../../store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../../Tables/PoppingtimeInvoiceTable'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import head from '../head'
import '../../Consumer.scss'


const headCells = [
  { id: 'invoiceNumber', numeric: false, disablePadding: true, label: 'InvoiceNumber' },
  { id: 'OrderNumber', numeric: false, disablePadding: true, label: 'orderNumber' },
  { id: 'Status', numeric: false, disablePadding: true, label: 'status' },
  { id: 'Grand Total', numeric: false, disablePadding: true, label: 'grand_total' },
  { id: 'CreatedAt', numeric: false, disablePadding: true, label: 'createdAt' },
];
class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      InvoicesData: [],
    }
    window.pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  componentWillMount() {
    this.props.poppingtimeInvoices();
  }
  componentWillReceiveProps(nextProps) {
  if (nextProps.InvoicesData) {
    console.log("nextProps.InvoicesData",nextProps.InvoicesData[0])
    this.setState({ InvoicesData: nextProps.InvoicesData[0] });
  }
}
getSubCategoriesNames = (data) => {
    const index = this.state.InvoicesData.findIndex((d) => d._id === data);
    if (index !== -1) {
      return this.state.InvoicesData[index];
    }
}
table = (data, columns) => {
  return {
    table: {
      headerRows: 1,
      body: this.buildTableBody(data, columns),
    },
  }
}
buildTableBody = (data, columns) => {
  var body = []

  body.push(columns)

  data.forEach(function (row) {
    var dataRow = []

    columns.forEach(function (column) {
      dataRow.push(row[column].toString())
    })
    body.push(dataRow)
  })
  return body;
}
Print = async (i) => {
  let a = this.getSubCategoriesNames(i);
  let {
    status,
    CUpaymentStatus,
    CU,
    grand_total,
    gst,
    total,
    type,
    discount,
    invoiceNumber,
    orderNumber,
    BookedSlots,
    ArrivalTime,
    ProductId,

  } = a;

  let n = new Date(orderNumber)
  let d =
    n.getFullYear() +
    '-' +
    (n.getMonth() + 1) +
    '-' +
    n.getDate() +
    '  ' +
    n.getHours() +
    ':' +
    n.getMinutes() +
    ':' +
    n.getSeconds()


  let Total = 0
  let obj = []
  if (ProductId.length > 0 && BookedSlots.length > 0 && BookedSlots.length === ProductId.length)
    BookedSlots.forEach((element, index) => {
      let obj2 = {
        Product: ProductId[index].Name,
        Date: ArrivalTime,
        Aantal: element,
        User: CU.username,
        Total: (element * ProductId[index].Price)
      }

      Total = a.grandTotal
      obj.push(obj2)
    });
  let tab = this.table(obj, ['Product', 'Date', 'Aantal', 'Total']);
  var docDefinition = {
    content: {
      margin: [0, 100, 0, 50],
      columns: [
        {//${/*this.state.data[e].key*/} 
          stack: [
            { text: 'Factuur', style: 'header' },
            { text: `${invoiceNumber}`, style: 'SUBheader' },
            { text: '  ' },
            { text: '  ' },
            { text: '  ' },
            { text: `Betaaldatum:${d}` },
            { text: ` Ordernummer:${orderNumber}` },
            { text: type !== "gift"?` Status: ${status}`:"" },
            { text: ` Payment: ${CUpaymentStatus}` },
            { text: '  ' },
            { text: '  ' },
            { text: type !== "gift"?`${CU.profile?.companyName}`:"" },
            { text: type !== "gift"?`tav: ${CU.username} ` :""},//${user_name}
            { text: type !== "gift"?`${CU.profile?.street} ${CU.profile?.houseNo} ${CU.profile?.town}`:"" },
            { text: type !== "gift"?` ${CU.profile?.postalCode} ${CU.profile?.city}`:"" },
            { text: type !== "gift"?`${CU.profile?.province}  ${CU.profile?.country}`:"" },
            // { text: `period:` },
            { text: '  ' },
            type !== "gift"?tab:"",
            { text: '  ' },
            { text: '  ' },
            { text: '  ' },
            { text: '  ' },
            {
              text: `Subtotaal:                                                                                                            ${total.toFixed(2)}`,
            },
            {
              text: `Discount:                                                                                                              ${discount.toFixed(2)}`,
            },
            {
              text: `BTW 0%:                                                                                                               ${gst.toFixed(2)}`,
            },

            {
              text: `Totaal:                                                                                                                  ${grand_total.toFixed(2)}`,
            },
          ],
        },
      ],
    },
    header: [{ image: head, width: 250, margin: 15 }],

    footer: [
      {
        text:
          'Poppingtime - Wijngaardenlaan 17 - 2252 XJ Voorschoten  Kvk 28104652 - BTW NL818216864B02 -                                                                 bankreknr. NL37 INGB 0004 6739 78 tnv Bureau Integra BV - e info@poppingtime.com',
        style: 'anotherStyle',
      },
    ],

    styles: {
      header: {
        fontSize: 22,
        bold: true,
        italics: true,
      },
      SUBheader: {
        fontSize: 22,
        italics: true,
      },
      defaultStyle: {
        lineHeight: 2,
        font: 'Courier',
      },
      anotherStyle: {
        italics: true,
        alignment: 'center',
      },
    },
  }
  console.log("docDefinition",docDefinition)
  pdfMake.createPdf(docDefinition).open()
}
  render() {
    return (
      <div>
        <div>
          <PageMenu heading="Poppingtime User Invoices"
            open={this.props.open ? "page-menu-open" : null}
            AddNew={false}
            btnhead={'Consumer'}
            AddNewLink={'/consumer/new'} />
          <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
            <Table headCells={headCells}
             rows={this.state.InvoicesData}
             Print={this.Print}
            // editLink={'consumer'}
            />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    InvoicesData: state.ConsumerReducer.InvoicesData,
  });

const mapDispatchToProps = dispatch => {
  return {
    poppingtimeInvoices: () => dispatch(actionCreators.poppingtimeInvoices()),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoice))