import { axiosClient } from "../../../../../../helper";
import { appStatusAction } from "../../../../../../reducer";

// Loyality Actions
export function DeleteTokenClick(id) {
  return (dispatch) => {
    return axiosClient()
      .delete(`/deleteTokenClick/${id}`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(DeleteTokenClickSuccess(res.data));
        dispatch(appStatusAction("Token / Click Successfully Deleted"));
      });
  };
}

export function DeleteTokenClickSuccess(data) {
  return {
    type: "DELETE_TOKEN",
    DeleteTocken: data,
  };
}

export function GetTokenClickById(id) {
  return (dispatch) => {
    return axiosClient()
      .post(`/getTokenClick?id=${id}`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetTokenClickByIdSuccess(res.data.result[0]));
      });
  };
}

export function GetTokenClickByIdSuccess(data) {
  return {
    type: "GET_ONE_TOKEN",
    OneToken: data,
  };
}
export function EditTokenClick(data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/EditTokenClick/${data.Id}`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(EditTokenClickSuccess(res.data.update));
        dispatch(appStatusAction("Token / Click Successfully Edited"));
      }).catch(e => {
        dispatch(appStatusAction(e.response?.data?.error[0]));
    })
  };
}

export function EditTokenClickSuccess(data) {
  return {
    type: "EDIT_TOKEN",
    OneToken: data,
  };
}

export function AddTokenClick(data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/AddTokenClick`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(AddTokenClickSuccess(res.data.data));
        dispatch(appStatusAction("Token / Click Successfully Added"));
      });
  };
}

export function AddTokenClickSuccess(data) {
  return {
    type: "ADD_TOKEN",
    OneToken: data,
  };
}
export function GetAllTokenClick() {
  return (dispatch) => {
    return axiosClient()
      .post(`/getTokenClick`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllTokenClickSuccess(res.data.result));
      });
  };
}

export function GetAllTokenClickSuccess(data) {
  return {
    type: "GET_TOKEN",
    tokens: data,
  };
}
export function GetAllUserGroup() {
  return (dispatch) => {
    return axiosClient()
      .get(`/getUserGroup`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllUserGroupSuccess(res.data.result));
      });
  };
}
export function GetAllUserGroupSuccess(data) {
  return {
    type: "GET_USERGROUP",
    usergroups: data,
  };
}

export function GetAllRegion() {
  return (dispatch) => {
    return axiosClient()
      .get(`/GetAllRegion`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllRegionSuccess(res.data.data));
      });
  };
}

export function GetAllRegionSuccess(data) {
  return {
    type: "GET_REGION",
    regions: data,
  };
}
export function GetCategoryById(id){
  return (dispatch) => {

      return axiosClient().get(`/getCategory?id=${id}`).then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          console.log("res.data.data",res.data.result)
          dispatch(GetCategoryByIdSuccess(...res.data.result));
      })
  }
}

export function GetCategoryByIdSuccess(data) {
  return {
      type: "GET_ONE_CATEGORY",
      OneCategory:data
  }
}
export function GetAllCategory() {
  return (dispatch) => {
      return axiosClient().get(`/getCategoryByUserGroup/5f5b30c6eac6dc0862c1ff8b`).then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          dispatch(GetAllCategorySuccess(res.data.result));
      })
  }
}

export function GetAllCategorySuccess(data) {
  return {
      type: "GET_CATEGORY",
      categories:data
  }
}
export function GetAllSubCategories() {
  return (dispatch) => {
      return axiosClient().get(`/getSubCategory`).then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          dispatch(GetAllSubCategoriesSuccess(res.data.result));
      })
  }
}

export function GetAllSubCategoriesSuccess(data) {
  return {
      type: "GET_SUBCATEGORY",
      subcategories:data
  }
}
