let defaultState = {
    usergroups: [],
    DeleteToken: [],
    OneToken: [],
    tokens: [],
    regions: [],
    categories:[],
    OneCategory:[]
}

const TokenReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_TOKEN":
            return {
                ...state,
                DeleteToken: [action.DeleteToken]
            }
        case "GET_ONE_TOKEN":
            return {
                ...state,
                OneToken: [action.OneToken]
            }
        case "EDIT_TOKEN":
            return {
                ...state,
                OneToken: [action.OneToken]
            }
        case "ADD_TOKEN":
            return {
                ...state,
                OneToken: [action.OneToken]
            }
        case "GET_TOKEN":
            return {
                ...state,
                tokens: [action.tokens]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
        case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }
        case "GET_SUBCATEGORY":
            return {
                ...state,
                subcategories: [action.subcategories],
            };
            case "GET_CATEGORY":
            return {
                categories: [action.categories],
            };
            case "GET_ONE_CATEGORY":
                return {
                    ...state,
                    OneCategory: [action.OneCategory],
                };
        default:
            return {
                ...state
            }
    }
}
export default TokenReducer;