import axios from "axios";

 export const BASE_URL = "http://api.beelicensed.com/";
// export const BASE_URL = "http://localhost:51000/";
//export const BASE_URL = "http://2c577a9cd35e.ngrok.io/";

export function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };

  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token;
    return config;
  });

  // instance.defaults.headers.common[
  //   "Authorization"
  // ] = store.getState().mainStore.accessToken;

  return instance;
}
