import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import { Input, InputLabel } from '@material-ui/core';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class AddAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ans: "",
            order: "",
            isNew:false,
            OneAnswer:[],
        }
    }
    componentWillMount() {
        if (this.props.match.params.aid != "new") {
            
        console.log(this.props.match.params.aid);
            this.setState({ isNew: true });
            this.props.GetAnswerEQById(this.props.match.params.aid);
        }
        else {
            this.setState({
                OneAnswer: {
                    "ans":"",
                    "order":0
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.OneAnswer.length > 0 & this.state.isNew) {
            this.setState({ OneAnswer: nextProps.OneAnswer[0] });
            this.setState({ ans: nextProps.OneAnswer[0]?.ans,
                order: nextProps.OneAnswer[0]?.order
                })
        }
    }
    saveHandler = () => {
        if (this.state.isNew) {
          var data = {
            "Id": this.props.match.params.aid,
            "ans": this.state.ans,
            "order":this.state.order,
            
          }
          this.props.EditAnswerEQ(data);
        }
        else {
          var data = {
            "Id":this.props.match.params.id,
            "AnsId":this.props.match.params.aid,
            "ans": this.state.ans,
            "order":this.state.order,
          }
          this.props.AddAnswerEQ(data);
        }
      }
    handleForm = (event, val) => {
        if (val  === "ans") {
            this.setState({ ans: event.target.value })
        }
        else if (val  === "order") {
            this.setState({ order: event.target.value })
        }

    }

    render() {
        return (<div>
            

            

                <PageMenu heading={this.state.table1?"Add Extra Vragen":"Answers"}
                    open={this.props.open?"page-menu-open":null}
                    backArrow={true}
                    AddNew={false}
                    toggle={false}
                    backTitle={'Extra Vragen'}
                    backUrl={`/extra-vragen/${this.props.match.params.id}`}
                    SaveBtn={true}
                    saveHandler={this.saveHandler} />

                 
                <div className={`upper-form ${this.props.open?"upper-form-open":""}`}>
                    <div className="form-head">
                        <span>Basic Info</span>
                    </div>
                    <div className="admin-form">
                        <div className="flex">
                            <InputLabel>Answer </InputLabel>
                            <Input type="text"
                                value={this.state.ans}
                                onChange={(event) => this.handleForm(event, "ans")} />
                        </div>
                        <div className="flex">
                            <InputLabel>Order</InputLabel>
                            <Input type="number"
                                value={this.state.order}
                                onChange={(event) => this.handleForm(event, "order")} />
                        </div>
                        
                    </div>
                </div>
        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneAnswer: state.ExtraVragenReducer.OneAnswer,
    });
  
  const mapDispatchToProps = dispatch => {
    return {
        GetAnswerEQById: (aid) => dispatch(actionCreators.GetAnswerEQById(aid)),
        EditAnswerEQ: (data) => dispatch(actionCreators.EditAnswerEQ(data)),
        AddAnswerEQ: (data) => dispatch(actionCreators.AddAnswerEQ(data)),
    }
  
  }
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAnswer))