import React from 'react'
import { InputLabel } from '@material-ui/core';
import UsergroupSelect from '../../../Pictures/UsergroupSelect'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';



export default function BasicInfo(props) {
    return (
        <div>
            {props.usergroups?.length > 0 && props.selectedUserGroup ?
                <UsergroupSelect handleRegion={props.handleUsergroup}
                    regions={props.usergroups}
                    selectedRegion={props.selectedUserGroup}
                    label="GEBRUIKERS GROEPEN" />
                : null}


            <InputLabel>ACTIEF  </InputLabel>

            <FormControl>
                    <RadioGroup value={props.ACTIEF} onChange={props.handleRadioACTIEF}>
                        <FormControlLabel value="Nee" control={<Radio color="primary" />} label="Nee" />
                        <FormControlLabel value="Ja" control={<Radio color="primary" />} label="Ja" />
                    </RadioGroup>
                </FormControl>
        </div>
    )
}