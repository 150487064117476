import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";
import Widget1 from "../Widgets/Widgets";
import Notification from "../snackbar/Notification";

class Dashboard extends Component {
  render() {
    return (
      <div>
        <Notification />
        <Container
        style={{maxWidth:"1300px"}}
          className={`navinner dashbaord ${
            this.props.open ? "drawerOpen" : ""
          }`}
        >
          <Row>
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Conversion" number="492" />
            </Col>
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Total Customers" number="1200" />
            </Col>
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Total Clients" number="300" />
            </Col>
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Total Visits" number="492" />
            </Col>
          </Row>
          <Row className="row-widget">
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Total Orders" number="1200" />
            </Col>
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Quizes Solved" number="300" />
            </Col>
            <Col md="4" lg="3" sm="6">
              <Widget1 heading="Pending Clients" number="492" color="danger" />
            </Col>
            <Col md="4" lg="3" sm="6">
              <Widget1
                heading="Blocked Customers"
                number="1200"
                color="danger"
              />
            </Col>
          </Row>

          {/* <Row className="row-widget">
            
          </Row> */}
        </Container>
      </div>
    );
  }
}
export default Dashboard;
