import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import PageMenu from '../../page-menu/PageMenu'
import ImageGallery from 'react-image-gallery';
import Reviews from './Reviews'
import axios from 'axios';
import './Index.scss'
import "react-image-gallery/styles/css/image-gallery.css";


const images = [
    {
        original: 'https://chateauyorvique.com/wp-content/uploads/2020/01/Buy_Singapore_Chateau_Mouton_Rothschild_2000-600x600.jpg',
        thumbnail: 'https://chateauyorvique.com/wp-content/uploads/2020/01/Buy_Singapore_Chateau_Mouton_Rothschild_2000-600x600.jpg',
    },
    {
        original: 'https://chateauyorvique.com/wp-content/uploads/2020/01/Buy_Singapore_Chateau_Mouton_Rothschild_2000-600x600.jpg',
        thumbnail: 'https://chateauyorvique.com/wp-content/uploads/2020/01/Buy_Singapore_Chateau_Mouton_Rothschild_2000-600x600.jpg',
    },
    {
        original: 'https://chateauyorvique.com/wp-content/uploads/2020/01/Buy_Singapore_Chateau_Mouton_Rothschild_2000-600x600.jpg',
        thumbnail: 'https://chateauyorvique.com/wp-content/uploads/2020/01/Buy_Singapore_Chateau_Mouton_Rothschild_2000-600x600.jpg',
    },
];

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: [],
            products:[],
            productID:[],
            cartItems:[]
        }
    }
    componentWillMount(){
        this.setState({productID:this.props.match.params.id})
        this.setState({cartItems:localStorage.getItem("cartItem") || []});
        axios.post(`http://api.beelicensed.com//GetProductById`,{Id:this.props.match.params.id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
             this.setState({products:res.data.data})
        })
    }
    getImages = (images) =>{
        var img = []
        // if(images){
        // images.forEach(element => {
            img.push({
                "original":images,
                "thumbnail":images,
            })
        // });
    // }
            return img;
    }
   AddIntoCart = (prodId)=>{ 
    var data = this.state.cartItems;
    console.log(data)
    data = data + "," + prodId;
    console.log(data)
    console.log("",prodId)
    localStorage.setItem("cartItem",data)
    this.setState({cartItems:data})
   
   }
    render() {
        return (
            <div>
                 <PageMenu heading="Provider Products Details" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 btnhead={'Products'}
                 AddNewLink={'/provider-products/new'}/>
                   <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Row>
                        <Col>
                            <div className="product-detail-page">
                                <Container>
                                    <Row>
                                        <Col md="6">
                                            <ImageGallery items={this.getImages(this.state.products?.Img)} showFullscreenButton={false} showNav={false} showPlayButton={false} />
                                        </Col>
                                        <Col md="6">
                                            <div className="product-left-sidebar">
                                                <h1 className="product-brand">{""}</h1>
                                                <h1 className="product-title">{this.state.products.Name}</h1>
                                                <h5 className="product-availability">Available in stock</h5>
                                                <hr />
                                                <p style={{ color: "#45A29E", }}>Add to Wish List</p>
                                                <h5 className="product-sku">SKU: {"KUH"}</h5>
                                                <h4 className="product-sku">Category: {"Bottle"}</h4>
                                                <h5 style={{ color: "#45A29E", }}>$ {this.state.products.Price}</h5>
                                                <hr />
                                                {/* <div>
                                                    <p style={{ display: "inline" }}>Size:</p>
                                                    <button className="button-size" >XS</button>
                                                    <button className="button-size">S</button>
                                                    <button className="button-size">M</button>
                                                </div> */}
                                                {/* <div style={{ marginTop: "20px" }}>
                                                    <p style={{ display: "inline" }}> Color:</p>
                                                    <button style={{ backgroundColor: "white" }} className="button-color"></button>
                                                    <button style={{ backgroundColor: "black" }} className="button-color"></button>
                                                    <button style={{ backgroundColor: "#ccc" }} className="button-color"></button>
                                                </div> */}
                                                {/* <div className="prod-buttons">
                                                    <button 
                                                     onClick={()=> this.AddIntoCart(this.state.products._id)} 
                                                    className="add-cart"><span>Add to Cart</span></button>
                                                    <button className="buy-now">Buy Now</button>
                                                </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        <Col>
                                        {this.state.products?.Reviews?.map(x=>
                                        <Reviews stars={x.stars} Description={x.Description} dateAdded={x.dateAdded}/>
                                            )}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                    </div>
            </div>
        )
    }
}