import { combineReducers } from "redux";
import ConsumerReducer from "../component/Consumer/store/reducer/index";
import BuReducer from "../component/Business/store/reducer/index";
import RegionReducer from "../component/general/region/store/reducer/index";
import UsergroupReducer from "../component/general/user-group/store/reducer/index";
import CategoryReducer from "../component/general/categories/store/reducer/index";
import SubCategoryReducer from "../component/general/sub-categories/store/reducer/index";
import FAQReducer from "../component/general/faqs/store/reducer/index";
import ExtraVragenReducer from "../component/general/extra-vragen/store/reducer/index";
import BUImageReducer from "../component/Pictures/store/reducer/index";
import TestQuestionReducer from "../component/general/tests/question-answer/store/reducer/index";
import TestReducer from "../component/general/tests/test/store/reducer/index";
import TokenReducer from "../component/general/pricing/token/store/reducer/index";
import OfferReducer from "../component/general/pricing/offers/store/reducer/index";
import SubReducer from "../component/general/pricing/subscription/store/reducer/index";
import ResultReducer from "../component/general/tests/result/store/reducer/index";
import CSVReducer from "../component/general/upload-csv/store/reducer/index";
import ProviderProductReducer from '../component/Products/ProviderProducts/store/reducer/index'
import ProviderReducer from '../component/Business/Provider/store/reducer/index'
import { axiosClient } from "../helper";

const appState = {
  message: "",
  accessToken: "",
  userId: "",
  username: "",
  email: "",
  role: "",
};

const appReducer = (state = appState, action) => {
  switch (action.type) {
    case "APP_STATUS":
      return {
        ...state,
        message: action.message,
      };
    case "LOGIN": {
      return {
        ...state,
        accessToken: action.accessToken,
        userId: action.userId,
        username: action.username,
        email: action.email,
        role: action.role,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export function appStatusAction(message) {
  return {
    type: "APP_STATUS",
    message: message,
  };
}

function loginAction(token, id, username, email, role) {
  return {
    type: "LOGIN",
    accessToken: token,
    userId: id,
    username: username,
    email: email,
    role: role,
  };
}

export function logoutAction() {
  localStorage.removeItem("token");
  localStorage.removeItem("expiresIn");
  return {
    type: "LOGOUT",
  };
}

function checkAuthTimeOut(expTime) {
  return function (dispatch) {
    setTimeout(() => {
      dispatch(logoutAction());
    }, expTime);
  };
}

export function CheckAuthState() {
  return function (dispatch) {
    const accessToken = localStorage.getItem("token") || "";
    if (!accessToken) {
      dispatch(logoutAction());
    } else {
      const expiresIn = localStorage.getItem("expiresIn") || "0";
      const expirationDate = new Date(parseInt(expiresIn));
      if (expirationDate < new Date()) {
        dispatch(logoutAction());
      } else {
        const data = { jwtToken: accessToken };
        axiosClient()
          .post("/checkUserStatus", data)
          .then((res) => {
            console.log(res.data.user._id)
            dispatch(
              loginAction(
                data.jwtToken,
                res.data.user._id,
                res.data.user.username,
                res.data.user.email,
                res.data.user.role
              )
            );
          })
          .catch((err) => {
            console.log(err);
            dispatch(logoutAction());
          });
        dispatch(
          checkAuthTimeOut(expirationDate.getTime() - new Date().getTime())
        );
      }
    }
  };
}

export function UserLogin(data) {
  return async (dispatch) => {
    try {
      const res = await axiosClient().post(`/login`, data);
      const expirationDate = new Date().getTime() + res.data.expiresIn;
      if (res.data.user.role !== "admin") {
        dispatch(appStatusAction("Loggin Failed..."));
        return;
      }
      dispatch(
        loginAction(
          res.data.accessToken,
          res.data.user._id,
          res.data.user.username,
          res.data.user.email,
          res.data.user.role
        )
      );
      localStorage.setItem("token", res.data.accessToken);
      localStorage.setItem("expiresIn", expirationDate.toString());

      dispatch(appStatusAction("Logged in Successfully."));
    } catch (err) {
      console.log(err);
      return dispatch(appStatusAction("Loggin Failed..."));
    }
  };
}

const reducers = combineReducers({
  ProviderReducer,
  appReducer,
  ConsumerReducer,
  BuReducer,
  RegionReducer,
  UsergroupReducer,
  CategoryReducer,
  SubCategoryReducer,
  FAQReducer,
  ExtraVragenReducer,
  BUImageReducer,
  TestQuestionReducer,
  TestReducer,
  TokenReducer,
  OfferReducer,
  SubReducer,
  ResultReducer,
  CSVReducer,
  ProviderProductReducer
});

export default reducers;
