import React from "react";
import SideNavPage from "../../Sidebar/SideNavPage";
import PageMenu from "../../page-menu/PageMenu";
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Table from "../../Tables/CategoryTable";

function createData(
  a,
  p,
  REGIO,
  BIJNAAM,
  GEBRUIKERSGROEPEN,
  BEGINPOSTCODE,
  EINDIGENDEPOSTCODE,
  PRODUCTENPERREGIO
) {
  return {
    a,
    p,
    REGIO,
    BIJNAAM,
    GEBRUIKERSGROEPEN,
    BEGINPOSTCODE,
    EINDIGENDEPOSTCODE,
    PRODUCTENPERREGIO,
  };
}

const rows = [
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
  createData("abc", "Learning", "Bed"),
];

const headCells = [
  { id: "CATEGORIE", numeric: false, disablePadding: true, label: "CATEGORIE" },
  {
    id: "GEBRUIKERS",
    numeric: true,
    disablePadding: false,
    label: "GEBRUIKERS GROEP",
  },
  {
    id: "SUBCATEGORIEËN",
    numeric: true,
    disablePadding: false,
    label: "SUB CATEGORIEËN",
  },
];

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      DeleteCategory: [],
      Delstatus: 0,
      subcategories:[],
      usergroups:[]
    };
  }
  componentWillMount() {
    this.props.GetAllCategory();
    this.props.GetAllUserGroup();
    this.props.GetAllSubCategories();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.subcategories) {
      this.setState({ subcategories: nextProps.subcategories[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
    }
  }
  handleDelete = (selected) => {
    var data = this.state.categories;
    selected.forEach((element) => {
      this.props.DeleteCategoryByID(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    });
    this.setState({ categories: data });
  };
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].title;
    }
    return value;
  };
  getLabelSubCategory = (value) => {
    const index = this.state.subcategories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.subcategories[index].name;
    }
    return value;
  };
  render() {
    return (
      <div>
        
        {/* <Container className={`navinner ${this.props.open?"drawerOpen" : "  "}`}> */}
        <PageMenu
          heading="Categories"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={true}
          btnhead={"Categories"}
          AddNewLink={"/categories/new"}
        />
        <div
          className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}
        >
         {this.state.categories && this.state.usergroups?.length > 0  && this.state.subcategories?.length > 0?
          <Table
            headCells={headCells}
            handleDelete={this.handleDelete}
            rows={this.state.categories}
            getLabelUserGroups={this.getLabelUserGroups}
            getLabelSubCategory={this.getLabelSubCategory}
            editLink={"categories"}
          />
          :null}
        </div>
        {/*   */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  categories: state.CategoryReducer.categories,
  DeleteCategory: state.CategoryReducer.DeleteCategory,
  usergroups: state.CategoryReducer.usergroups,
  subcategories: state.CategoryReducer.subcategories,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    DeleteCategoryByID: (id) => dispatch(actionCreators.DeleteCategoryByID(id)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
    GetAllSubCategories: () => dispatch(actionCreators.GetAllSubCategories()),
    
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Categories)
);
