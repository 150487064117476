import React from 'react';
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import { Input, InputLabel } from '@material-ui/core';
import MultipleSelect from '../../../Pictures/RegionSelect'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import UsergroupSelect from '../../../Pictures/UsergroupSelect';

class AddSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            tax:0,
            phoneNumber: "",
            email: "",
            password: "",
            CU: false,
            BU: false,
            userType: "",
            punten: 0,
            OneSub: [],
            regions: [],
            usergroups: [],
            isNew: false,
            selectedRegions: [],
            SelectedUsergroups: [],
            updatedRegion: [],
            updatedUsergroups: [],
        }
    }
    componentWillMount() {
        this.props.GetAllUserGroup();
        this.props.GetAllRegion();
        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.props.GetSubscriptionById(this.props.match.params.id);
        }
        else {
            this.setState({
                OneSub: {
                    "regions": [],
                    "usergroups": [],
                    "desc": "",
                    "perMonth": 0,
                    "months": 0,
                    "userType": "",
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.usergroups) {
            this.setState({ usergroups: nextProps.usergroups[0] });
        }
        if (nextProps.regions) {
            this.setState({ regions: nextProps.regions[0] });
        }
        if (nextProps.OneSub.length > 0 & this.state.isNew) {
            this.setState({ OneSub: nextProps.OneSub[0] });
            this.setState({
                desc: nextProps.OneSub[0]?.desc,
                months: nextProps.OneSub[0]?.months,
                perMonth: nextProps.OneSub[0]?.perMonth,
                updatedRegion: nextProps.OneSub[0]?.regions,
                tax:nextProps.OneSub[0]?.tax,
                updatedUsergroups: nextProps.OneSub[0]?.usergroups,
                userType: nextProps.OneSub[0]?.userType
            })
            if(this.state.SelectedUsergroups.length < 1){
                nextProps.OneSub[0].usergroups.forEach(element => {
                  this.getLabelUserGroups(element)
                });
              }
              if(this.state.selectedRegions.length < 1){
                nextProps.OneSub[0].regions.forEach(element => {
                  this.getLabelRegions(element)
                });
              }
        }
    }
    saveHandler = () => {
        if (this.state.isNew) {
            var data = {
                "Id": this.props.match.params.id,
                "regions": this.state.updatedRegion,
                "usergroups": this.state.updatedUsergroups,
                "desc": this.state.desc,
                "perMonth": this.state.perMonth,
                "tax":this.state.tax,
                "months": this.state.months,
                "userType": this.state.userType,
            }
            this.props.EditSubscription(data);
        }
        else {
            var data = {
                "regions": this.state.updatedRegion,
                "usergroups": this.state.updatedUsergroups,
                "desc": this.state.desc,
                "perMonth": this.state.perMonth,
                "tax":this.state.tax,
                "months": this.state.months,
                "userType": this.state.userType,
            }
            this.props.AddSubscription(data);
        }
    }
    handleRegion = (ids) => {
        this.setState({ updatedRegion: ids });
    }
    handleUsergroup = (ids) => {
        this.setState({ updatedUsergroups: ids });
    }
    handleForm = (event, val) => {
        if (val === "desc") {
            this.setState({ desc: event.target.value })
        }
        else if (val === "tax") {
            this.setState({ tax: event.target.value })
        }
        else if (val === "months") {
            this.setState({ months: event.target.value })
        }
        else if (val === "perMonth") {
            this.setState({ perMonth: event.target.value })
        }

    }
    handleChange = (event) => {
        if (event.target.value === "bu-user") {
            this.setState({ BU: true });
            this.setState({ CU: false });
            this.setState({ userType: "bu-user" })

        }
        if (event.target.value === "cu-user") {
            this.setState({ BU: false });
            this.setState({ CU: true });
            this.setState({ userType: "cu-user" })
        }
    }
    getLabelUserGroups = (value) => {
        const index = this.state.usergroups?.findIndex((d) => d._id === value);
        var ref = this.state.SelectedUsergroups;
        if (index !== -1) {
          ref.push(this.state.usergroups[index]._id);
        }
        this.setState({ SelectedUsergroups: ref })
      };
      getLabelRegions = (value) => {
        const index = this.state.regions?.findIndex((d) => d._id === value);
        var ref = this.state.selectedRegions;
        if (index !== -1) {
          ref.push(this.state.regions[index]._id);
        }
        this.setState({ selectedRegions: ref })
      };
    render() {
        return (<div>
            <PageMenu heading="Add Subscription "
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                backArrow={true}
                backTitle={'Subscription '}
                backUrl={'/subscription'}
                SaveBtn={true}
                saveHandler={this.saveHandler} />
            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span>Basic Info</span>
                </div>
                <div className="admin-form">
                    <div className="flex">
                        <InputLabel>Naam </InputLabel>
                        <Input type="text"
                            value={this.state.desc}
                            onChange={(event) => this.handleForm(event, "desc")} />
                    </div>
                    <div className="flex">
                        <InputLabel>Maanden</InputLabel>
                        <Input type="number"
                            value={this.state.months}
                            onChange={(event) => this.handleForm(event, "months")} />
                    </div>
                    <div className="flex">
                        <InputLabel>p/maand</InputLabel>
                        <Input type="number"
                            placeholder="$"
                            value={this.state.perMonth}
                            onChange={(event) => this.handleForm(event, "perMonth")} />
                    </div>
                    <div className="flex">
                        <InputLabel>Tax</InputLabel>
                        <Input type="number"
                            placeholder="Tax %"
                            value={this.state.tax}
                            onChange={(event) => this.handleForm(event, "tax")} />
                    </div>

                    <InputLabel>User </InputLabel>

                    <FormControl>
                        <RadioGroup value={this.state.userType} onChange={this.handleChange}>
                            <FormControlLabel value="bu-user" control={<Radio color="primary" />} label="Bu" />
                            <FormControlLabel value="cu-user" control={<Radio color="primary" />} label="Cu" />
                        </RadioGroup>
                    </FormControl>

                    {this.state.regions?.length > 0 && this.state.selectedRegions ?
                        //    console.log(this.state.selectedRegions)


                        <MultipleSelect handleRegion={this.handleRegion}
                            regions={this.state.regions}
                            selectedRegion={this.state.selectedRegions}
                            label="Select Region" />

                        : null}

                    {this.state.usergroups?.length > 0 && this.state.SelectedUsergroups ?
                        <UsergroupSelect handleRegion={this.handleUsergroup}
                            regions={this.state.usergroups}
                            selectedRegion={this.state.SelectedUsergroups}
                            label="UserGroup" />
                        : null}
                </div>
            </div>

        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        usergroups: state.SubReducer.usergroups,
        regions: state.SubReducer.regions,
        OneSub: state.SubReducer.OneSub,
    });

const mapDispatchToProps = dispatch => {
    return {
        GetSubscriptionById: (id) => dispatch(actionCreators.GetSubscriptionById(id)),
        EditSubscription: (data) => dispatch(actionCreators.EditSubscription(data)),
        AddSubscription: (data) => dispatch(actionCreators.AddSubscription(data)),
        GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
        GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSubscription))