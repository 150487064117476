let defaultState = {
    usergroups: [],
    DeleteTest: [],
    OneResult: [],
    results: [],
}

const ResultReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_TEST":
            return {
                ...state,
                DeleteTest: [action.DeleteTest]
            }
        case "GET_ONE_TEST":
            return {
                ...state,
                OneTest: [action.OneTest]
            }
        case "EDIT_TEST":
            return {
                ...state,
                OneTest: [action.OneTest]
            }
        case "ADD_TEST":
            return {
                ...state,
                OneTest: [action.OneTest]
            }
        case "GET_RESULTS":
            return {
                ...state,
                results: [action.results]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }

        default:
            return {
                ...state
            }
    }
}
export default ResultReducer;