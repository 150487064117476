import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
// import * as actionCreators from './store/action/index';
// import { withRouter } from 'react-router-dom';
// import { connect } from "react-redux";
import Table from '../../../Tables/OrderedSubscriptionTable'

const headCells = [
    { id: 'Id', numeric: false, disablePadding: true, label: 'Id' },
    { id: 'SubscriptionNumber', numeric: false, disablePadding: true, label: 'Subscription Number' },
    { id: 'UserID', numeric: false, disablePadding: false, label: 'User ID' },
    { id: 'Usergroup', numeric: false, disablePadding: true, label: 'Usergroup' },
    { id: 'amount', numeric: false, disablePadding: true, label: 'Amount Paid'},
    { id: 'status', numeric: false, disablePadding: true, label: 'Status'},
    { id: 'AdminApproval', numeric: false, disablePadding: true, label: 'Admin Approval'},
  ];

 const data = [{"Id":"1","SubscriptionNumber":'5ee489863464450784b4f43a',"UserID":"John","Usergroup":"Shopkeeper",
                "amount":"45€","status":"paid","AdminApproval":"pending"},
                {"Id":"2","SubscriptionNumber":'5ec2cd87df56da4d20dfed4f',"UserID":"Kevin","Usergroup":"Shopkeeper",
                "amount":"45€","status":"paid","AdminApproval":"pending"},
                {"Id":"3","SubscriptionNumber":'5eb8e906b6cd4cdb6c49deca',"UserID":"Tom","Usergroup":"Shopkeeper",
                "amount":"45€","status":"paid","AdminApproval":"pending"}]

class Subscription extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      subs: [],
      DeleteSub: [],
      Delstatus: 0
    }
  }
//   componentWillMount() {
//     this.props.GetAllSubscription();

//   }
//   componentWillReceiveProps(nextProps) {
//     if (nextProps.subs) {
//       this.setState({ subs: nextProps.subs[0] })
//     }
//   }
  handleDelete = (selected) => {
    var data = this.state.subs;
    selected.forEach(element => {
      this.props.DeletSubscription(element);
      for(var i = 0; i < data.length; i++) {
        if(data[i]._id == element) {
            data.splice(i, 1);
            break;
        }
      }
    }
    );
    this.setState({subs:data});
  }
    render(){
        return(
            <div>
            
                 <PageMenu heading="BU Ordered Subscriptions" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 btnhead={'Subscription'}
                 AddNewLink={'/subscription/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Table headCells={headCells} handleDelete={this.handleDelete} rows={data} editLink={'subscription'}/>
                 </div>
                 </div>
        )
    }
}
// const mapStateToProps = (state) => (
//   {
//     subs: state.SubReducer.subs,
//     DeleteSub: state.SubReducer.DeleteSub
//   });

// const mapDispatchToProps = dispatch => {
//   return {
//     GetAllSubscription: () => dispatch(actionCreators.GetAllSubscription()),
//     DeletSubscription: (id) => dispatch(actionCreators.DeletSubscription(id)),
//   }

// }
export default Subscription;