import React from "react";
import clsx from "clsx";
import './sidebar.css'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import SettingsInputComponentOutlinedIcon from "@material-ui/icons/SettingsInputComponentOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import CropOriginalOutlinedIcon from "@material-ui/icons/CropOriginalOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import MoneyOutlinedIcon from "@material-ui/icons/MoneyOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import SubscriptionsOutlinedIcon from "@material-ui/icons/SubscriptionsOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import GridOnOutlinedIcon from "@material-ui/icons/GridOnOutlined";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import BrokenImageOutlinedIcon from "@material-ui/icons/BrokenImageOutlined";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "240px",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar

        style={{ color: "#fff" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: props.open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Typography variant="h6" noWrap className="nav-heading">
              Couponcard Admin
            </Typography>
          </Link>
          <Link to="/profile" style={{ display: "contents" }}>
            <MenuItem className="admin-info">
              <Avatar alt="Admin" src="/static/images/avatar/1.jpg" />
            </MenuItem>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={props.open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={props.handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
                <ChevronLeftIcon />
              )}
          </IconButton>
        </div>
        <Divider />
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem button onClick={() => props.handleClick("dashbaord")}>
            <ListItemIcon>
              <BusinessCenterOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
            {props.dashbaordOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={props.dashbaordOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/home">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <BusinessOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Analytics" />
                </ListItem>
              </Link>

              <Link to="/insights">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <LoyaltyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Insights" />
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <ListItem button onClick={() => props.handleClick("orders")}>
            <ListItemIcon>
              <SettingsInputComponentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
            {props.orderOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={props.orderOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => props.handleClick("cu")}>
                <ListItemIcon>
                  <MonetizationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="CU" />
                {props.cuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.cuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/token">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <TouchAppOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tokens / Clicks" />
                    </ListItem>
                  </Link>
                  <Link to="/subscription">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <SubscriptionsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Abonnementen" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

              <ListItem button onClick={() => props.handleClick("bu")}>
                <ListItemIcon>
                  <MonetizationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="BU" />
                {props.buOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.buOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/ordered-token">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <TouchAppOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tokens / Clicks" />
                    </ListItem>
                  </Link>
                  <Link to="/order-subscription">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <SubscriptionsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Abonnementen" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </Collapse>
          <ListItem button onClick={() => props.handleClick("products")}>
            <ListItemIcon>
              <ShoppingBasketOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
            {props.productOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={props.productOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/provider-products">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <SupervisorAccountOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Provider Products" />
                </ListItem>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link to="/provider-reservation">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <SupervisorAccountOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Provider Reservations" />
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <ListItem button onClick={() => props.handleClick("Consument")}>
            <ListItemIcon>
              <ShoppingBasketOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Consument" />
            {props.consumentOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={props.consumentOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => props.handleClick("Poppingtime")}>
                <ListItemIcon>
                  <ShoppingBasketOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Poppingtime" />
                {props.poppingtimeOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.poppingtimeOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/poppingtime-users">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <SupervisorAccountOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                  </Link>
                  <Link to="/poppingtime-invoices">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ReceiptOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Invoices" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              {/* Loyal Sawan Consumer */}
              <ListItem button onClick={() => props.handleClick("Loyalswan")}>
                <ListItemIcon>
                  <ShoppingBasketOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Loyalswan" />
                {props.loyalswanOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.loyalswanOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/poppingtime-users">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <SupervisorAccountOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                  </Link>
                  <Link to="/poppingtime-users">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ReceiptOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Invoices" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>


              <Link to="/loyalty">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <LoyaltyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Loyalty" />
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <ListItem button onClick={() => props.handleClick("zakelijke")}>
            <ListItemIcon>
              <BusinessCenterOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Zakelijke" />
            {props.zakelijkeOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={props.zakelijkeOpen} timeout="auto" unmountOnExit>
            <ListItem button onClick={() => props.handleClick("provider")}>
              <ListItemIcon>
                <ShoppingBasketOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Provider" />
              {props.providerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={props.providerOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                <Link to="/provider-users">
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <SupervisorAccountOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </Link>

                <ListItem button onClick={() => props.handleClick("providerInvoices")}>
                  <ListItemIcon>
                    <ShoppingBasketOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Invoices" />
                  {props.providerInvoicesOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={props.providerInvoicesOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    <Link to="/poppingtime-users">
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <SupervisorAccountOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Subscription" />
                      </ListItem>
                    </Link>
                    <Link to="/poppingtime-users">
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <SupervisorAccountOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reservation" />
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </List>
            </Collapse>
            {/* Loyal Sawan Consumer */}
            <ListItem button onClick={() => props.handleClick("coupon")}>
              <ListItemIcon>
                <ShoppingBasketOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Coupon" />
              {props.couponOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={props.couponOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/poppingtime-users">
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <SupervisorAccountOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </Link>
                <Link to="/poppingtime-users">
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ReceiptOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Invoices" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>

            <List component="div" disablePadding>
             
              {/* <Link to="/provider-users">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <BusinessOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Provider Users" />
                </ListItem>
              </Link> */}

              {/* <ListItem button className={classes.nested}>
            <ListItemIcon>
              <LiveHelpOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="FAQs" />
          </ListItem> */}
              <Link to="/bu-Loyaliteit">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <LoyaltyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Loyaliteit" />
                </ListItem>
              </Link>

              {/* <ListItem button className={classes.nested}>
            <ListItemIcon>
              <MoneyOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Kassa bonnen" />
          </ListItem> */}

              {/* <ListItem button onClick={() => handleClick("pricing")}>
        <ListItemIcon>
          <MonetizationOnOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="pricing" />
        {pricingOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={pricingOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <TouchAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Tokens / Clicks" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <LocalOfferOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Aanbiedingen" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <SubscriptionsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Abonnementen" />
          </ListItem>
        </List>
        
      </Collapse> */}
            </List>
          </Collapse>
          <ListItem button onClick={() => props.handleClick("Instellingen")}>
            <ListItemIcon>
              <SettingsInputComponentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Instellingen" />
            {props.InstellingenOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={props.InstellingenOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button onClick={() => props.handleClick("tests")}>
                <ListItemIcon>
                  <HourglassEmptyOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Tests" />
                {props.testsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.testsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/question-answer">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ContactSupportOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Test Vragen" />
                    </ListItem>
                  </Link>

                  <Link to="/test">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <GridOnOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Test" />
                    </ListItem>
                  </Link>
                  <Link to="/result">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ImportContactsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Result" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

              <Link to="/csv-data">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <BackupOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Upload CSV" />
                </ListItem>
              </Link>

              {/* <ListItem button className={classes.nested}>
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Instellingen" />
          </ListItem> */}
              <Link to="/picture">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <BrokenImageOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Afbeeldingen" />
                </ListItem>
              </Link>
              <Link to="/extra-vragen">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ContactSupportOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Extra vragen" />
                </ListItem>
              </Link>
              <Link to="/categories">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <CategoryOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Categorieën" />
                </ListItem>
              </Link>
              <Link to="/sub-categories">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <SubjectOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Subcategorieën" />
                </ListItem>
              </Link>
              <Link to="/region">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <RoomOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Regio's" />
                </ListItem>
              </Link>
              <Link to="/user-group">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <GroupAddOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gebruikersgroepen" />
                </ListItem>
              </Link>

              <Link to="/faqs">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <LiveHelpOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="FAQs" />
                </ListItem>
              </Link>

              <ListItem button onClick={() => props.handleClick("pricing")}>
                <ListItemIcon>
                  <MonetizationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Pricing" />
                {props.pricingOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={props.pricingOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/token">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <TouchAppOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tokens / Clicks" />
                    </ListItem>
                  </Link>
                  <Link to="/offer">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <LocalOfferOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Aanbiedingen" />
                    </ListItem>
                  </Link>
                  <Link to="/subscription">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <SubscriptionsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Abonnementen" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          </Collapse>
        </List>
        <Link to="/chat">
          <ListItem
            style={{ paddingLeft: "17px" }}
            button
            className={classes.nested}
          >
            <ListItemIcon>
              <ChatOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Chat" />
          </ListItem>
        </Link>
      </Drawer>
    </div>
  );
}
