import React from 'react'
import { Input, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export default class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            punten: 0,
            basicInfo: true,
            priceField:true,
            value:"Ja"
        }
    }
    componentWillMount() {
        this.setState({ name: this.props.match?.params.id })
    }
    render() {
        return (
            <div>
                <div className="flex">

                    <FormControl className="formControl">
                        <InputLabel htmlFor="age-native-simple">ONDERWERP  </InputLabel>
                        <Select
                            native
                            value={this.props.topic}
                            onChange={(event)=>this.props.handleForm(event,"topic")}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {this.props.categories?.map(x=>
                             <option value={x._id}>{x.name}</option>
                                )}
                           
                        </Select>
                    </FormControl>
                </div>
                <div className="flex">

                    <FormControl className="formControl">
                        <InputLabel htmlFor="age-native-simple">SUBONDERWERP  </InputLabel>
                        <Select
                            native
                            value={this.props.sub_topic}
                            onChange={(event)=>this.props.handleForm(event,"sub_topic")}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {this.props.subcategories?.map(x=>
                             <option value={x._id}>{x.name}</option>
                                )}
                        </Select>
                    </FormControl>
                </div>
                <div className="flex">
                    <InputLabel>TITEL LEERBOEK </InputLabel>
                    <Input type="text"
                        value={this.props.txtBook}
                        onChange={(event) => this.props.handleForm(event, "txtBook")} />
                </div>
                <div className="flex">
                    <InputLabel>HOOFDSTUK</InputLabel>
                    <Input type="number"
                        value={this.props.ch}
                        onChange={(event) => this.props.handleForm(event, "ch")} />
                </div>
                <div className="flex">
                    <InputLabel>PARAGRAAF </InputLabel>
                    <Input type="number"
                        value={this.props.sec}
                        onChange={(event) => this.props.handleForm(event, "sec")} />
                </div>
                <div className="flex">
                    <InputLabel>VRAAG </InputLabel>
                    <Input type="text"
                        value={this.props.Q}
                        onChange={(event) => this.props.handleForm(event, "Q")} />
                </div>
                <InputLabel>OPENBAAR</InputLabel>

                <FormControl>
                    <RadioGroup value={this.props.value} onChange={this.props.handleRadio}>
                        <FormControlLabel value="Nee" control={<Radio color="primary" />} label="Nee" />
                        <FormControlLabel value="Ja" control={<Radio color="primary" />} label="Ja" />
                    </RadioGroup>
                </FormControl>
                {this.props.priceField?
                <div className="flex">
                    <InputLabel>PRICE</InputLabel>
                    <Input type="number"
                        value={this.props.price}
                        onChange={(event) => this.props.handleForm(event, "price")} />
                </div>
                :null}

                <div className="flex">
                    <InputLabel>TIJDSDUUR (SEC)     </InputLabel>
                    <Input type="number"
                        value={this.props.duration}
                        onChange={(event) => this.props.handleForm(event, "duration")} />
                </div>
                <div className="flex">
                    <InputLabel>PUNTEN     </InputLabel>
                    <Input type="number"
                        value={this.props.points}
                        onChange={(event) => this.props.handleForm(event, "points")} />
                </div>
                <div className="flex">
                    <InputLabel>BENODIGDE SCORE IN %</InputLabel>
                    <Input type="number"
                        value={this.props.score}
                        onChange={(event) => this.props.handleForm(event, "score")} />
                </div>
            </div>
        )
    }
}