import React from 'react'

import PageMenu from '../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../Tables/VragenTable'



const headCells = [
  { id: 'VRAAG', numeric: false, disablePadding: true, label: 'Actie' },
  { id: 'NUMMERANTWOORDEN', numeric: false, disablePadding: false, label: 'NUMMER ANTWOORDEN' },
  { id: 'CATEGORIEËN', numeric: false, disablePadding: false, label: 'CATEGORIEËN' },
  { id: 'REGIO', numeric: false, disablePadding: false, label: 'REGIO' },
  // { id: 'TOESTAND', numeric: true, disablePadding: true, label: 'TOESTAND' },
];



class ExtraVragen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      DeleteQuestion: [],
      Delstatus: 0,
      regions:[],
      categories:[],
    }
  }
  componentWillMount() {
    this.props.GetAllRegion();
    this.props.GetAllCategory();
    this.props.GetAllQuestions();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
    }
    if (nextProps.questions) {
      this.setState({ questions: nextProps.questions[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.questions;
    selected.forEach(element => {
      this.props.DeletQuestion(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ questions: data });
  }
  getLabelCategory = (value) => {
    const index = this.state.categories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.categories[index].name;
    }
    return value;
  };
  getLabelRegion = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.regions[index].Name;
    }
    return value;
  };
  render() {
    return (
      <div>
        <PageMenu heading="Extra Vragen"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={true}
          btnhead={'Vragen'}
          AddNewLink={'/extra-vragen/new'} />
        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
        {this.state.questions && this.state.categories?.length > 0 & this.state.regions?.length > 0 ?
          <Table headCells={headCells} handleDelete={this.handleDelete}
            rows={this.state.questions} 
            getLabelCategory={this.getLabelCategory}
            getLabelRegion={this.getLabelRegion}
            editLink={'extra-vragen'} />
            :null}
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    questions: state.ExtraVragenReducer.questions,
    DeleteQuestion: state.ExtraVragenReducer.DeleteQuestion,
    regions: state.ExtraVragenReducer.regions,
    categories: state.ExtraVragenReducer.categories,
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllQuestions: () => dispatch(actionCreators.GetAllQuestions()),
    DeletQuestion: (id) => dispatch(actionCreators.DeletQuestion(id)),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtraVragen))