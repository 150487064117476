import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import Switch from '@material-ui/core/Switch';
import { Input, InputLabel } from '@material-ui/core';
import UsergroupSelect from '../../Pictures/UsergroupSelect'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class AddHowWorks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Title: "",
            desc: "",
            priority: 0,
            usergroups: [],
            active: false,
            CU: false,
            BU: false,
            userType: "",
            UpdatedUserGroup: [],
            selectedUserGroup: [],
        }
    }
    componentWillMount() {
        this.props.GetAllUserGroup();
        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.props.GetWorkingById(this.props.match.params.id);
        }
        else {
            this.setState({
                OneWorking: {
                    "Title": "",
                    "desc": "",
                    "priority": 0,
                    "usergroups": [],
                    "active": false,
                    "CU": false,
                    "BU": false
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.usergroups) {
            this.setState({ usergroups: nextProps.usergroups[0] });
        }
        if (nextProps.OneWorking?.length > 0 & this.state.isNew) {
            this.setState({ OneWorking: nextProps.OneWorking[0] });
            this.setState({
                Title: nextProps.OneWorking[0]?.Title,
                desc: nextProps.OneWorking[0]?.desc,
                priority: nextProps.OneWorking[0]?.priority,
                UpdatedUserGroup: nextProps.OneWorking[0]?.usergroups,
                active: nextProps.OneWorking[0]?.active
            })
            if (this.state.selectedUserGroup.length < 1) {
                nextProps.OneWorking[0].usergroups.forEach(element => {
                    this.getLabelUserGroups(element)
                });
            }
            if(nextProps.OneWorking[0]?.userType === "bu-user"){
                this.setState({userType:"bu-user"})
            }
            else if(nextProps.OneWorking[0]?.userType === "cu-user"){
                this.setState({userType:"cu-user"})
            }
        }
    }
    saveHandler = () => {
        if (this.state.isNew) {
            var data = {
                "Id": this.props.match.params.id,
                "Title": this.state.Title,
                "desc": this.state.desc,
                "priority": this.state.priority,
                "usergroups": this.state.UpdatedUserGroup,
                "active": this.state.active,
                "userType": this.state.userType
                // "CU":this.state.CU,
                // "BU":this.state.BU
            }
            this.props.EditWorking(data);
        }
        else {
            var data = {
                "Title": this.state.Title,
                "desc": this.state.desc,
                "priority": this.state.priority,
                "usergroups": this.state.UpdatedUserGroup,
                "active": this.state.active,
                "userType": this.state.userType,
            }
            this.props.AddWorking(data);
        }
    }
    handleUsergroup = (ids) => {
        this.setState({ UpdatedUserGroup: ids });
    }
    handleForm = (event, val) => {
        if (val == "Title") {
            this.setState({ Title: event.target.value })
        }
        else if (val == "desc") {
            this.setState({ desc: event.target.value })
        }
        else if (val == "priority") {
            this.setState({ priority: event.target.value })
        }

    }
    handleChange = (event) => {
        if (event.target.value === "bu-user") {
            this.setState({ BU: true });
            this.setState({ CU: false });
            this.setState({ userType: "bu-user" })

        }
        if (event.target.value === "cu-user") {
            this.setState({ BU: false });
            this.setState({ CU: true });
            this.setState({ userType: "cu-user" })
        }
        if (event.target.name === "checkedA") {
            this.setState({ active: event.target.checked });
        }
    }
    getLabelUserGroups = (value) => {
        const index = this.state.usergroups?.findIndex((d) => d._id === value);
        var ref = this.state.selectedUserGroup;
        if (index !== -1) {
            ref.push(this.state.usergroups[index]._id);
        }
        this.setState({ selectedUserGroup: ref })
    };
    render() {
        return (<div>
            <PageMenu heading="Add Hoe het werkt!"
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                backArrow={true}
                backTitle={'Hoe het werkt!'}
                backUrl={'/faqs'}
                SaveBtn={true}
                saveHandler={this.saveHandler} />
            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span>Basic Info</span>
                </div>
                <div className="admin-form">
                    <div className="flex">
                        <InputLabel>Question</InputLabel>
                        <Input type="text"
                            value={this.state.Title}
                            onChange={(event) => this.handleForm(event, "Title")} />
                    </div>
                    <div className="flex">
                        <InputLabel>PRIORITEIT</InputLabel>
                        <Input type="number"
                            value={this.state.priority}
                            onChange={(event) => this.handleForm(event, "priority")} />
                    </div>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Switch checked={this.state.active} onChange={this.handleChange} color="primary" name="checkedA" />}
                            label="Active"
                        />
                    </FormGroup>

                    <InputLabel>BESTEMMING </InputLabel>

                    <FormControl>
                        <RadioGroup value={this.state.userType} onChange={this.handleChange}>
                            <FormControlLabel value="bu-user" control={<Radio color="primary" />} label="Bu" />
                            <FormControlLabel value="cu-user" control={<Radio color="primary" />} label="Cu" />
                        </RadioGroup>
                    </FormControl>
                    <div className="flex" style={{ marginTop: "20px" }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            value={this.state.desc}
                            onChange={(event) => this.handleForm(event, "desc")}
                            rows={4}
                            variant="outlined"
                        />
                    </div>
                    {this.state.usergroups?.length > 0 && this.state.selectedUserGroup ?
                        <UsergroupSelect handleRegion={this.handleUsergroup}
                            regions={this.state.usergroups}
                            selectedRegion={this.state.selectedUserGroup}
                            label="GEBRUIKERS GROEPEN" />
                        : null}

                </div>
            </div>

        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneWorking: state.FAQReducer.OneWorking,
        usergroups: state.FAQReducer.usergroups
    });

const mapDispatchToProps = dispatch => {
    return {
        GetWorkingById: (id) => dispatch(actionCreators.GetWorkingById(id)),
        EditWorking: (data) => dispatch(actionCreators.EditWorking(data)),
        AddWorking: (data) => dispatch(actionCreators.AddWorking(data)),
        GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddHowWorks))