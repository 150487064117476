import React from 'react';
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import BasicInfo from './ExtraBasicInfo';
import Image from './ExtraImage';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import AddExtraVragen from '../../extra-vragen/AddExtraVragen';
import axios,{post} from 'axios'

class AddQuestionAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ans: "",
            order: "",
            statement:"",
            isNew:false,
            OneTestAnswer:[],
            basicInfo:true,
            image:false,
            status:false,
            file:"",
            imageFileName:""
        }
    }
    componentWillMount() {
        if (this.props.match.params.aid != "new") {
            this.setState({ isNew: true });
            this.props.GetTestAnswerById({"Id": this.props.match.params.id,"AnsId":this.props.match.params.aid});
        }
        else {
            this.setState({
                OneTestAnswer: {
                    "ans":"",
                    "order":0,
                    "status":false,
                    "statement":""
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.OneTestAnswer.length > 0 & this.state.isNew) {
            this.setState({ OneTestAnswer: nextProps.OneTestAnswer[0] });
            console.log("nextProps.OneTestAnswer[0]",nextProps.OneTestAnswer[0])
            this.setState({ ans: nextProps.OneTestAnswer[0]?.ans,
                order: nextProps.OneTestAnswer[0]?.order,
                status: nextProps.OneTestAnswer[0]?.status,
                statement: nextProps.OneTestAnswer[0]?.statement,
                imageFileName: nextProps.OneTestAnswer[0]?.img,
                })
        }
    }
    onFormSubmit = (id) => {
        // Stop form submit
        this.fileUpload(this.state.file,id).then((response) => {
            console.log(response.data);
        })
    }
    fileUpload = (file,id) => {
        const url = `http://api.beelicensed.com/UploadTestAnswer/${this.props.match.params.id}/${id}`;
        var data = {
            Id:this.props.match.params.id,
            ans: this.state.ans,
            order:this.state.order,
            statement:this.state.statement,
            status:this.state.status
        }
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(url, formData, config)
    }

    handleFileAdded = (fil) => {
        this.setState({ file: fil })
    }

    handleForm = (event, val) => {
      
        if (val == "ans") {
            this.setState({ ans: event.target.value })
        }
        else if (val == "order") {
            this.setState({ order: event.target.value })
        }
        else if (val == "statement") {
            this.setState({ statement: event.target.value })
        }

    }
    handleChange = (event) => {
        this.setState({status:event.target.checked})
    };
    addNewData = () => {
        var data = {
            Id:this.props.match.params.id,
            ans: this.state.ans,
            order:this.state.order,
            statement:this.state.statement,
            status:this.state.status
        }
        axios.post(`http://api.beelicensed.com/AddTestAnswer`, data, {  
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            console.log(response.data);
            console.log(response.data?.data._id);
            this.onFormSubmit(response.data?.data[0]._id)
        }).catch(e => {

        })
    }
    editFile = () =>{
        var data = {
            Id: this.props.match.params.id,
            AnsId:this.props.match.params.aid,
            ans: this.state.ans,
            order:this.state.order,
            statement:this.state.statement,
            status:this.state.status
        }
        axios.post(`http://api.beelicensed.com/EditTestAnswer`, data, {  
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            if(this.state.file !== undefined){
            this.onFormSubmit(response.data?.data._id)
            }
        }).catch(e => {

        })
    }

    saveHandler = () => {
        if (this.state.isNew) {
              this.editFile();
        }
        else {
            console.log(this.state.file)
            if(this.state.file !== ""){
            this.addNewData();
            }
            else{
                alert("Please upload file and then try again!")
            }
            //this.onFormEditSubmit();
        }
    }
handleTabs = (str)=>{
        if(str ==="basic"){
         this.setState({basicInfo:true});
         this.setState({image:false});
        }
        else if(str ==="adv"){
            this.setState({basicInfo:false});
            this.setState({image:false});
        }
        else if(str ==="img"){
            this.setState({basicInfo:false});
            this.setState({image:true});
        }
        else if(str ==="make"){
            this.setState({basicInfo:false});
            this.setState({image:false});
        }
}

    render() {
        return (<div>

                <PageMenu heading="Add Answer"
                      open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                    backArrow={true}
                    backTitle={'Add Question'}
                    backUrl={'/question-answer/new'}
                    SaveBtn={true}
                    saveHandler={this.saveHandler}  />
                <div className={`upper-form ${this.props.open?"upper-form-open":""}`}>
                    <div className="form-head">
                        <span onClick={()=>this.handleTabs("basic")} className={`${this.state.basicInfo?"form-head-menu-selected":""} form-head-menu`}>Basic Info</span>
                        <span onClick={()=>this.handleTabs("img")}className={`${this.state.image?"form-head-menu-selected":""} form-head-menu`}>Photos</span>
                    </div>
                 
                    <div className="admin-form">
                   {this.state.basicInfo?
                   <BasicInfo
                   handleForm={this.handleForm}
                   statement={this.state.statement}
                   ans={this.state.ans}
                   status={this.state.status}
                   order={this.state.order}
                   handleChange={this.handleChange}
                   />
                :null}
                {this.state.image?
                       <Image isNew={this.state.isNew}
                       onFormSubmit={this.onFormSubmit}
                       onChange={this.onChange}
                       handleFileAdded={this.handleFileAdded}
                       file={this.state.file}
                       imageFileName={this.state.imageFileName} />
                :null}
                    </div>
                </div>
            
        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneTestAnswer: state.TestQuestionReducer.OneTestAnswer,
    });
  
  const mapDispatchToProps = dispatch => {
    return {
        GetTestAnswerById: (aid) => dispatch(actionCreators.GetTestAnswerById(aid)),
        EditTestAnswer: (data) => dispatch(actionCreators.EditTestAnswer(data)),
        AddTestAnswer: (data) => dispatch(actionCreators.AddTestAnswer(data)),
    }
  
  }
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddQuestionAnswer))