let defaultState = {
    usergroups: [],
    DeleteSub: [],
    OneSub: [],
    subs: [],
    regions: [],
}

const SubReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_SUB":
            return {
                ...state,
                DeleteSub: [action.DeleteSub]
            }
        case "GET_SUB":
            return {
                ...state,
                OneSub: [action.OneSub]
            }
        case "GET_ALL_SUB":
            return {
                ...state,
                subs: [action.subs]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
        case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }

        default:
            return {
                ...state
            }
    }
}
export default SubReducer;