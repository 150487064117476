import { axiosClient } from "../../../../../helper";
import { appStatusAction } from "../../../../../reducer";

// Loyality Actions
export function DeletSubCategory(id) {
  return (dispatch) => {
    return axiosClient()
      .delete(`/deleteSubCategory/${id}`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(DeletSubCategorySuccess(res.data.remove));
        dispatch(appStatusAction("Sub Categories Successfully Deleted"));
      });
  };
}

export function DeletSubCategorySuccess(data) {
  return {
    type: "DELETE_SUBCATEGORY",
    DeleteSubCategory: data,
  };
}

export function GetSubCategoryById(id) {
  return (dispatch) => {
    return axiosClient()
      .get(`/getSubCategory?id=${id}`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetSubCategoryByIdSuccess(...res.data.result));
      });
  };
}

export function GetSubCategoryByIdSuccess(data) {
  return {
    type: "GET_ONE_SUBCATEGORY",
    OneSubCategory: data,
  };
}
export function EditSubCategory(id, data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/editSubCategory/${id}`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(EditSubCategorySuccess(res.data.update));
        dispatch(appStatusAction("Sub Categories Successfully Edited"));
      });
  };
}

export function EditSubCategorySuccess(data) {
  return {
    type: "EDIT_SUBCATEGORY",
    OneSubCategory: data,
  };
}

export function AddSubCategory(data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/addSubCategory`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(AddSubCategorySuccess(res.data.result));
        dispatch(appStatusAction("Sub Categories Successfully Added"));
      });
  };
}

export function AddSubCategorySuccess(data) {
  return {
    type: "ADD_SUBCATEGORY",
    OneSubCategory: data,
  };
}
export function GetAllSubCategories() {
  return (dispatch) => {
    return axiosClient()
      .get(`/getSubCategory`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllSubCategoriesSuccess(res.data.result));
      });
  };
}

export function GetAllSubCategoriesSuccess(data) {
  return {
    type: "GET_SUBCATEGORY",
    subcategories: data,
  };
}
