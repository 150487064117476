import React from "react";

import PageMenu from "../../page-menu/PageMenu";
import Switch from "@material-ui/core/Switch";
import { Input, InputLabel } from "@material-ui/core";
import UsergroupSelect from "../../Pictures/UsergroupSelect";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class AddFAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "",
      desc: "",
      priority: 0,
      usergroups: [],
      active: false,
      CU: false,
      BU: false,
      userType: "",
      UpdatedUserGroup: [],
      selectedUserGroup: [],
    };
  }
  componentWillMount() {
    this.props.GetAllUserGroup();
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.props.GetFAQById(this.props.match.params.id);
    } else {
      this.setState({
        OneFAQ: {
          Title: "",
          desc: "",
          priority: 0,
          usergroups: [],
          active: false,
          userType: "",
        },
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.OneFAQ?.length > 0 && this.state.isNew && nextProps.OneFAQ[0]) {
      this.setState({ OneFAQ: nextProps.OneFAQ[0] });
      this.setState({
        Title: nextProps.OneFAQ[0]?.question,
        desc: nextProps.OneFAQ[0]?.answer,
        priority: nextProps.OneFAQ[0]?.priority,
        //selectedUserGroup: nextProps.OneFAQ[0]?.userGroup,
        UpdatedUserGroup: nextProps.OneFAQ[0]?.userGroup,
        active: nextProps.OneFAQ[0]?.isActive,
        userType: nextProps.OneFAQ[0]?.userType,
      });
      if(this.state.selectedUserGroup.length < 1){
        nextProps.OneFAQ[0].userGroup.forEach(element => {
              this.getLabelUserGroups(element)
            });
          }
      // if(nextProps.OneFAQ[0]?.BU){
      //     this.setState({userType:"bu-user"})
      //     this.setState({BU:true})
      // }
      // else if(nextProps.OneFAQ[0]?.userType == ){
      //     this.setState({userType:"cu-user"})
      //     this.setState({CU:true})
      // }
    }
  }
  saveHandler = () => {
    if (this.state.isNew) {
      var data = {
        Id: this.props.match.params.id,
        question: this.state.Title,
        answer: this.state.desc,
        priority: this.state.priority,
        userGroup: this.state.UpdatedUserGroup,
        isActive: this.state.active,
        userType: this.state.userType,
      };
      this.props.EditFAQ(data, this.props.match.params.id);
    } else {
      var data = {
        question: this.state.Title,
        answer: this.state.desc,
        priority: this.state.priority,
        userGroup: this.state.UpdatedUserGroup,
        isActive: this.state.active,
        userType: this.state.userType,
      };
      this.props.AddFAQ(data);
    }
  };
  handleUsergroup = (ids) => {
    this.setState({ UpdatedUserGroup: ids });
  };
  handleForm = (event, val) => {
    if (val == "Title") {
      this.setState({ Title: event.target.value });
    } else if (val == "desc") {
      this.setState({ desc: event.target.value });
    } else if (val == "priority") {
      this.setState({ priority: event.target.value });
    }
  };
  handleChange = (event) => {
    if (event.target.value === "bu-user") {
      this.setState({ BU: true });
      this.setState({ CU: false });
      this.setState({ userType: "bu-user" });
    }
    if (event.target.value === "cu-user") {
      this.setState({ BU: false });
      this.setState({ CU: true });
      this.setState({ userType: "cu-user" });
    }
    if (event.target.name === "checkedA") {
      this.setState({ active: event.target.checked });
    }
  };
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    var ref =  this.state.selectedUserGroup;
    if (index !== -1) {
      ref.push(this.state.usergroups[index]._id);
    }
    this.setState({selectedUserGroup:ref})
  };
  render() {
    return (
      <div>
        <PageMenu
          heading="Add FAQ"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          backArrow={true}
          backTitle={"FAQ"}
          backUrl={"/faqs"}
          SaveBtn={true}
          saveHandler={this.saveHandler}
        />
        <div
          className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}
        >
          <div className="form-head">
            <span>Basic Info</span>
          </div>
          <div className="admin-form">
            <div className="flex">
              <InputLabel>Question</InputLabel>
              <Input
                type="text"
                value={this.state.Title}
                onChange={(event) => this.handleForm(event, "Title")}
              />
            </div>
            <div className="flex">
              <InputLabel>PRIORITEIT</InputLabel>
              <Input
                type="number"
                value={this.state.priority}
                onChange={(event) => this.handleForm(event, "priority")}
              />
            </div>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.active}
                    onChange={this.handleChange}
                    color="primary"
                    name="checkedA"
                  />
                }
                label="Active"
              />
            </FormGroup>
            <InputLabel>BESTEMMING </InputLabel>

            <FormControl>
              <RadioGroup
                value={this.state.userType}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="bu-user"
                  control={<Radio color="primary" />}
                  label="Bu"
                />
                <FormControlLabel
                  value="cu-user"
                  control={<Radio color="primary" />}
                  label="Cu"
                />
              </RadioGroup>
            </FormControl>
            <div className="flex" style={{ marginTop: "20px" }}>
              <TextField
                id="outlined-multiline-static"
                label="Answer"
                value={this.state.desc}
                onChange={(event) => this.handleForm(event, "desc")}
                multiline
                rows={4}
                variant="outlined"
              />
            </div>
            {this.state.usergroups?.length > 0 &&
            this.state.selectedUserGroup ? (
              <UsergroupSelect
                handleRegion={this.handleUsergroup}
                regions={this.state.usergroups}
                selectedRegion={this.state.selectedUserGroup}
                label="GEBRUIKERS GROEPEN"
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  OneFAQ: state.FAQReducer.OneFAQ,
  usergroups: state.FAQReducer.usergroups,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetFAQById: (id) => dispatch(actionCreators.GetFAQById(id)),
    EditFAQ: (data, id) => dispatch(actionCreators.EditFAQ(data, id)),
    AddFAQ: (data) => dispatch(actionCreators.AddFAQ(data)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFAQ));
