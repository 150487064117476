import React from 'react'

import PageMenu from '../page-menu/PageMenu'
 
import Table from '../Tables/Table'
import './Consumer.scss'

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('John', 62928398, 'john@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Kevin', 62928398, 'Kevin@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Tom', 62928398, 'Tom@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Sam', 62928398, 'Sam@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Evan', 62928398, 'Evan@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Stall', 62928398, 'Stall@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Brent', 62928398, 'Brent@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
    createData('Holland', 62928398, 'Holland@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  ];

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Phone number' },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Last online' },
    { id: 'protein', numeric: true, disablePadding: false, label: 'Date joined' },
  ];

 

export default class Consumer extends React.Component{
    render(){
        return(
            <div>
            <div>
                 <PageMenu heading="Consumers" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'Consumer'}
                 AddNewLink={'/consumer/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Table headCells={headCells} rows={rows} editLink={'consumer'}/>
                 </div>
                 </div>
                 </div>
        )
    }
}