import React from "react";
import SideNavPage from "../../../Sidebar/SideNavPage";
import PageMenu from "../../../page-menu/PageMenu";
import { Container } from "reactstrap";
import { Input, InputLabel } from "@material-ui/core";
import { MultiCascader } from 'rsuite';
import MultipleSelect from "../../../Pictures/RegionSelect";
import SubcategoriesSelect from "../../../Pictures/SubcategoriesSelect";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UsergroupSelect from "../../../Pictures/UsergroupSelect";
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';

function randomString(length) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

class AddToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      no: 0,
      price: 0,
      taxPercent: 0,
      clicks: false,
      tokens: false,
      validDuration: 0,
      userType: "",
      OneToken: [],
      isNew: false,
      usergroups: [],
      regions: [],
      selectedRegions: [],
      SelectedUsergroups: [],
      subcategories: [],
      updatedRegion: [],
      updatedUsergroups: [],
      type: "",
      private: false,
      isActive: true,
      isNewstate: false,
      categories: [],
      OneCategory: [],
      option: [],
      tokenCategory: "",
      tokenSubcategory: "",
      isCSV:false,
      isPromo:false,
      CascaderValue: [],
      gift: false,
      uncheck: []
    };
  }
 
  componentWillMount() {
    this.props.GetAllCategory();
    this.props.GetAllUserGroup();
    this.props.GetAllRegion();
    this.props.GetAllSubCategories();
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.props.GetTokenClickById(this.props.match.params.id);
    } else {
      this.setState({
        OneToken: {
          name: "",
          no: 0,
          price: 0,
          isCSV:false,
          isPromo:false,
          clicks: false,
          tokens: false,
          userType: "",
          time: 0,
          regions: [],
          usergroups: [],
        },
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.subcategories) {
      this.setState({ subcategories: nextProps.subcategories[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
      this.CascaderHandler(nextProps.categories);
    }
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    this.setState({ isNewstate: false })
    if (nextProps.OneToken?.length > 0 & this.state.isNew) {
      // if(this.state.SelectedUsergroups.length < 1){
      //   nextProps.OneToken[0].userGroup.forEach(element => {
      //     this.getLabelUserGroups(element)
      //   });
      // }
      // if(this.state.selectedRegions.length < 1){
      //   nextProps.OneToken[0].regions.forEach(element => {
      //     this.getLabelRegions(element)
      //   });
      // }
      this.setState({ OneToken: nextProps.OneToken[0] });
      this.setState({
        name: nextProps.OneToken[0]?.name,
        no: nextProps.OneToken[0]?.number,
        price: nextProps.OneToken[0]?.price,
        type: nextProps.OneToken[0]?.type,
        taxPercent: nextProps.OneToken[0]?.taxPercent,
        time: nextProps.OneToken[0]?.validDuration,
        userType: nextProps.OneToken[0]?.userType,
        updatedUsergroups: nextProps.OneToken[0]?.userGroup,
        updatedRegion: nextProps.OneToken[0]?.regions,
        selectedRegions: nextProps.OneToken[0]?.regions,
        SelectedUsergroups: nextProps.OneToken[0]?.userGroup,
        isCSV:nextProps.OneToken[0]?.isCSV,
        discount: nextProps.OneToken[0]?.discount,
        private: nextProps.OneToken[0]?.isPrivate,
        uniqueKey: nextProps.OneToken[0]?.privateKeyValue,
        CascaderValue: nextProps.OneToken[0]?.subCategory,
        tokenSubcategory: nextProps.OneToken[0]?.subCategory,
        tokenCategory: nextProps.OneToken[0]?.category,
        isPromo: nextProps.OneToken[0]?.isPromo,
        gift: nextProps.OneToken[0]?.isGift,
        isNewstate: true
      });
    }
  }
  cascaderOnChange = (value) => {
    this.setState({ CascaderValue: value })
    this.setState({ tokenSubcategory: value })
    this.setState({ tokenCategory: "5f060dc768dd4e717535bbb3" })
  }
  CascaderSelect = (id) => {
    this.props.GetCategoryById(id);
  }
  CascaderHandler = (categories) => {
    if (categories[0]) {
      var option = [];
      var uncheck = [];
      var child = [];
      // var data = [];
      categories[0].forEach(element => {
        child = []
        element.subCategory.forEach(ele => {
          child.push({
            value: ele._id,
            label: ele.name,
          })
        })
        option.push({
          value: element._id,
          label: element.name,
          children: child
        })
        uncheck.push(element._id)
      })
      this.setState({ uncheck: uncheck })
      this.setState({ option: option })
    }
  }
  getSubCategoriesNames = (data) => {
    var ref = [];
    data.forEach(element => {
      const index = this.state.subcategories?.findIndex((d) => d._id === element);
      if (index !== -1) {
        ref.push(this.state.subcategories[index].name);
      }
    });
    return ref;

  }
  saveHandler = () => {
    var subCatName = this.getSubCategoriesNames(this.state.tokenSubcategory);
    if (this.state.isNew) {
      var data = {
        Id: this.props.match.params.id,
        name: this.state.name,
        number: parseInt(this.state.no),
        price: parseFloat(this.state.price),
        type: this.state.type,
        validDuration: parseInt(this.state.time),
        regions: this.state.updatedRegion,
        userGroup: this.state.updatedUsergroups,
        userType: this.state.userType,
        discount: this.state.discount,
        taxPercent: parseInt(this.state.taxPercent),
        isActive: this.state.isActive,
        isPrivate: this.state.private,
        isCSV:this.state.isCSV,
        privateKeyValue: this.state.uniqueKey,
        category: this.state.tokenCategory,
        subCategory: this.state.tokenSubcategory,
        subCategoryName: subCatName,
        isGift: this.state.gift,
        isPromo: this.state.isPromo

      };
      this.props.EditTokenClick(data);
    } else {
      var data = {
        name: this.state.name,
        number: parseInt(this.state.no),
        price: parseFloat(this.state.price),
        subCategoryName: subCatName,
        type: this.state.type,
        validDuration: parseInt(this.state.time),
        regions: this.state.updatedRegion,
        userGroup: this.state.updatedUsergroups,
        isCSV:this.state.isCSV,
        userType: this.state.userType,
        discount: this.state.discount,
        taxPercent: parseInt(this.state.taxPercent),
        isActive: this.state.isActive,
        isPrivate: this.state.private,
        privateKeyValue: this.state.uniqueKey,
        category: this.state.tokenCategory,
        subCategory: this.state.tokenSubcategory,
        isGift: this.state.gift,
        isPromo: this.state.isPromo
      };
      this.props.AddTokenClick(data);
    }
  };
  handleForm = (event, val) => {
    if (val === "name") {
      this.setState({ name: event.target.value });
    } else if (val === "no") {
      this.setState({ no: event.target.value });
    } else if (val === "price") {
      this.setState({ price: event.target.value });
    } else if (val === "time") {
      this.setState({ time: event.target.value });
    }
    else if (val === "taxPercent") {
      this.setState({ taxPercent: event.target.value });
    }
    else if (val === "discount") {
      this.setState({ discount: event.target.value });
    }

  };
  generateUniqueKey = () => {
    this.setState({ uniqueKey: randomString(3) + "-" + randomString(3) });
  }
  handleChange = (event) => {
    if (event.target.value === "click") {
      this.setState({ clicks: true });
      this.setState({ tokens: false });
      this.setState({ type: "click" });
    }
    if (event.target.name === "checkedB") {
      if (event.target.checked == true) {
        this.generateUniqueKey();
      }
      this.setState({ private: event.target.checked });
    }
    if (event.target.name === "promo") {
      this.setState({ isPromo: event.target.checked });
    }
    if (event.target.name === "gift") {
      this.setState({ gift: event.target.checked });
    }
    if (event.target.name === "isCSV") {
      this.setState({ isCSV: event.target.checked });
    }
    if (event.target.value === "token") {
      this.setState({ clicks: false });
      this.setState({ tokens: true });
      this.setState({ type: "token" });
    }
    if (event.target.value === "bu-user") {
      this.setState({ userType: "bu-user" });
    }
    if (event.target.value === "cu-user") {
      this.setState({ userType: "cu-user" });
    }
    // this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  handleRegion = (ids) => {
    this.setState({ updatedRegion: ids });
  };
  handleUsergroup = (ids) => {
    this.setState({ updatedUsergroups: ids });
  };
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    var ref = [];
    if (index !== -1) {
      ref.push(this.state.usergroups[index]._id);
    }
    this.setState({ SelectedUsergroups: ref })
  };
  getLabelRegions = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    var ref = [];
    if (index !== -1) {
      ref.push(this.state.regions[index]._id);
    }
    this.setState({ selectedRegions: ref })
  };
  render() {
    return (
      <div>
        <PageMenu
          heading="Add Token/Clicks"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          backArrow={true}
          backTitle={"Token/Clicks"}
          backUrl={"/token"}
          SaveBtn={true}
          saveHandler={this.saveHandler}
        />
        <div
          className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}
        >
          <div className="form-head">
            <span>Basic Info</span>
          </div>
          <div className="admin-form">
            <div className="flex">
              <InputLabel>Naam </InputLabel>
              <Input
                type="text"
                value={this.state.name}
                onChange={(event) => this.handleForm(event, "name")}
              />
            </div>
            <div className="flex">
              <InputLabel>Aantal</InputLabel>
              <Input
                type="number"
                value={this.state.no}
                onChange={(event) => this.handleForm(event, "no")}
              />
            </div>
            <div className="flex">
              <InputLabel>Prijs </InputLabel>
              <Input
                type="number"
                value={this.state.price}
                onChange={(event) => this.handleForm(event, "price")}
              />
            </div>
            <div className="flex">
              <InputLabel>Valid time(months) </InputLabel>
              <Input
                type="number"
                value={this.state.time}
                onChange={(event) => this.handleForm(event, "time")}
              />
            </div>
            <div className="flex">
              <InputLabel>Discount </InputLabel>
              <Input
                type="number"
                value={this.state.discount}
                onChange={(event) => this.handleForm(event, "discount")}
              />
            </div>
            <div style={{ display: "inline-block" }}>
              <InputLabel>Status</InputLabel>
              <div >
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={this.state.private} onChange={this.handleChange} color="primary" name="checkedB" />}
                    label="Private"
                  />
                </FormGroup>
              </div>
            </div>
            {this.state.private ?
              <div style={{ display: "inline-block" }}>
                <span style={{ border: "1px solid #eee" }}>{this.state.uniqueKey}</span>
              </div> : null}
            <div style={{ display: "block" }}>
              <InputLabel>Promotion status</InputLabel>
              <div >
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={this.state.isPromo} onChange={this.handleChange} color="primary" name="promo" />}
                    label="Promo"
                  />
                  <FormControlLabel
                    control={<Switch checked={this.state.gift} onChange={this.handleChange} color="primary" name="gift" />}
                    label="Gift"
                  />
                </FormGroup>

              </div>
            </div>
            <div className="flex">
              <InputLabel>Tax % </InputLabel>
              <Input
                type="number"
                value={this.state.taxPercent}
                onChange={(event) => this.handleForm(event, "taxPercent")}
              />
            </div>
            <div style={{ display: "block" }}>
              <InputLabel>Download option</InputLabel>
              <div >
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={this.state.isCSV} onChange={this.handleChange} color="primary" name="isCSV" />}
                    label="CSV"
                  />
                </FormGroup>

              </div>
              </div>
            <InputLabel>Type </InputLabel>

            <FormControl>
              <RadioGroup value={this.state.type} onChange={this.handleChange}>
                <FormControlLabel
                  value="click"
                  control={<Radio color="primary" />}
                  label="Click"
                />
                <FormControlLabel
                  value="token"
                  control={<Radio color="primary" />}
                  label="Token"
                />
              </RadioGroup>
            </FormControl>
            <InputLabel>User </InputLabel>

            <FormControl>
              <RadioGroup
                value={this.state.userType}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="bu-user"
                  control={<Radio color="primary" />}
                  label="Bu"
                />
                <FormControlLabel
                  value="cu-user"
                  control={<Radio color="primary" />}
                  label="Cu"
                />
              </RadioGroup>
            </FormControl>

            {this.state.regions?.length > 0 && this.state.selectedRegions ? (
              <MultipleSelect
                handleRegion={this.handleRegion}
                regions={this.state.regions}
                selectedRegion={this.state.selectedRegions}
                label="Select Region"
              />
            ) : null}

            {this.state.usergroups?.length > 0 &&
              this.state.SelectedUsergroups ? (
                <UsergroupSelect
                  handleRegion={this.handleUsergroup}
                  regions={this.state.usergroups}
                  selectedRegion={this.state.SelectedUsergroups}
                  label="UserGroup"
                />
              ) : null}
            {this.state.usergroups?.length > 0 &&
              this.state.SelectedUsergroups ? (
                // <SubcategoriesSelect
                //   handleRegion={this.handleUsergroup}
                //   regions={this.state.subcategories}
                //   selectedRegion={this.state.SelectedUsergroups}
                //   label="Subcategory"
                // />
                <MultiCascader
                  cascade={false}
                  style={{ width: 311 }}
                  data={this.state.option}
                  uncheckableItemValues={this.state.uncheck}
                  onChange={this.cascaderOnChange}
                  value={this.state.CascaderValue}
                />
              ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  usergroups: state.TokenReducer.usergroups,
  regions: state.TokenReducer.regions,
  OneToken: state.TokenReducer.OneToken,
  subcategories: state.TokenReducer.subcategories,
  categories: state.TokenReducer.categories,
  OneCategory: state.TokenReducer.OneCategory
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetTokenClickById: (id) => dispatch(actionCreators.GetTokenClickById(id)),
    EditTokenClick: (data) => dispatch(actionCreators.EditTokenClick(data)),
    AddTokenClick: (data) => dispatch(actionCreators.AddTokenClick(data)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
    GetAllSubCategories: () => dispatch(actionCreators.GetAllSubCategories()),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    GetCategoryById: (id) => dispatch(actionCreators.GetCategoryById(id)),


  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddToken)
);
