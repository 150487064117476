import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Input, InputLabel } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import UsergroupSelect from '../../Pictures/UsergroupSelect'

function randomString(length) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}


class AddRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      region: "",
      Products: "",
      deals: "",
      EZip: "",
      SZip: "",
      ExtraQ:0,
     DayLottery:0,
      POINTS: true,
      PERCENT: true,
      private: false,
      active: false,
      isNew: false,
      UpdatedUserGroup: [],
      uniqueKey: "",
      usergroups:[],
      selectedUserGroup:[]

    }
  }
  myfuc = () => {

    this.props.GetRegionById(this.props.match.params.id);
  }
  generateUniqueKey = () => {
    this.setState({ uniqueKey: randomString(3) + "-" + randomString(3) });
  }
  componentWillMount() {
    this.props.GetAllUserGroup();
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.setState(this.myfuc)
    }
    else {
      this.setState({
        OneRegion: {
          "Name": "",
          "Active": "",
          "region": "",
          "SZip": "",
          "EZip": "",
          "Products": "",
          "deals": "",
          "private": false,
          "code": "",
          "usergroups": [],
          "ExtraQ":0,
          "DayLottery":0
        }
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (this.state.isNew && nextProps.OneRegion[0]) {
      this.setState({ OneRegion: nextProps.OneRegion[0] });
      this.setState({ name: nextProps.OneRegion[0]?.Name,
         region: nextProps.OneRegion[0]?.region,
         active: nextProps.OneRegion[0]?.Active,
         SZip: nextProps.OneRegion[0]?.SZip,
         EZip: nextProps.OneRegion[0]?.EZip, 
         Products: nextProps.OneRegion[0]?.Products, 
         uniqueKey: nextProps.OneRegion[0]?.code, 
         deals: nextProps.OneRegion[0]?.deals, 
         private: nextProps.OneRegion[0]?.private,       
         ExtraQ:nextProps.OneRegion[0]?.ExtraQ,   
         DayLottery:nextProps.OneRegion[0]?.DayLottery,
         UpdatedUserGroup:nextProps.OneRegion[0]?.usergroups,
        })
      this.setState({ selectedRegion: nextProps.OneRegion[0]?.regions })
      if(this.state.selectedUserGroup.length < 1){
      nextProps.OneRegion[0].usergroups.forEach(element => {
            this.getLabelUserGroups(element)
          });
        }
   
    }
  }
  handleForm = (event, val) => {
    if (val === "name") {
      this.setState({ name: event.target.value })
    }
    else if (val === "region") {
      this.setState({ region: event.target.value })
    }
    else if (val === "Products") {
      this.setState({ Products: event.target.value })
    }
    else if (val === "deals") {
      this.setState({ deals: event.target.value })
    }
    else if (val === "SZip") {
      this.setState({ SZip: event.target.value })
    }
    else if (val === "EZip") {
      this.setState({ EZip: event.target.value })
    }
    else if (val === "question") {
      this.setState({ ExtraQ: event.target.value })
    }
  }
  handleChange = (event) => {
    // this.setState({ ...this.state, [event.target.name]: event.target.value });
    if (event.target.name === "checkedB") {
      if (event.target.checked == true) {
        this.generateUniqueKey();
      }
      this.setState({ private: event.target.checked });
    }
    else if (event.target.name === "checkedA") {
      this.setState({ active: event.target.checked });
    }
    else if(event.target.name === "DayLottery") {
      this.setState({ DayLottery: event.target.value });
    }

  };
  saveHandler = () => {
    if (this.state.isNew) {
      var data = {
        "Id": this.props.match.params.id,
        "Name": this.state.name,
        "Active": this.state.active,
        "region": this.state.region,
        "SZip": this.state.SZip,
        "EZip": this.state.EZip,
        "Products": this.state.Products,
        "deals": this.state.deals,
        "private": this.state.private,
        "code": this.state.uniqueKey,
        "usergroups": this.state.UpdatedUserGroup,
        "ExtraQ":this.state.ExtraQ,
        "DayLottery":this.state.DayLottery
      }
      this.props.EditRegionById(data);
    }
    else {
      var data = {
        "Name": this.state.name,
        "Active": this.state.active,
        "region": this.state.region,
        "SZip": this.state.SZip,
        "EZip": this.state.EZip,
        "Products": this.state.Products,
        "deals": this.state.deals,
        "private": this.state.private,
        "code": this.state.uniqueKey,
        "usergroups": this.state.UpdatedUserGroup,
        "ExtraQ":this.state.ExtraQ,
        "DayLottery":this.state.DayLottery
      }
      this.props.AddRegion(data);
    }
  }
  handleUsergroup = (ids) => {
    this.setState({ UpdatedUserGroup: ids });
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    var ref =  this.state.selectedUserGroup;
    if (index !== -1) {
      ref.push(this.state.usergroups[index]._id);
    }
    this.setState({selectedUserGroup:ref})
  };
  render() {
    return (<div>
      <PageMenu heading="Add Region"
        open={this.props.open ? "page-menu-open" : null}
        AddNew={false}
        backArrow={true}
        backTitle={'Region'}
        backUrl={'/region'}
        SaveBtn={true}
        saveHandler={this.saveHandler}
      />
      <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
        <div className="form-head">
          <span>Basic Info</span>
        </div>
        <div className="admin-form">

          <div className="flex">
            <InputLabel>NAAM</InputLabel>
            <Input type="text"
              value={this.state.name}
              onChange={(event) => this.handleForm(event, "name")} />
          </div>

          <div className="flex">
            <InputLabel>Regio</InputLabel>
            <Input type="text"
              value={this.state.region}
              onChange={(event) => this.handleForm(event, "region")} />
          </div>
          <div style={{ display: "inline-block" }}>
            <InputLabel>Status</InputLabel>
            <div >
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={this.state.active} onChange={this.handleChange} color="primary" name="checkedA" />}
                  label="Active"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.private} onChange={this.handleChange} color="primary" name="checkedB" />}
                  label="Private"
                />
              </FormGroup>

            </div>
          </div>

          {this.state.private ?
            <div style={{ display: "inline-block" }}>
              <span style={{ border: "1px solid #eee" }}>{this.state.uniqueKey}</span>
            </div> : null}

          <div className="flex">
            <InputLabel>PRODUCTEN PER REGIO</InputLabel>
            <Input type="number"
              value={this.state.Products}
              onChange={(event) => this.handleForm(event, "Products")} />
          </div>

          <div className="flex">
            <InputLabel>DEALS PER LOT</InputLabel>
            <Input type="number"
              value={this.state.deals}
              onChange={(event) => this.handleForm(event, "deals")} />
          </div>



          <div className="flex">
            <InputLabel>BEGIN POSTCODE</InputLabel>
            <Input type="text"
              value={this.state.SZip}
              onChange={(event) => this.handleForm(event, "SZip")} />
          </div>

          <div className="flex">
            <InputLabel>EINDIGENDE POSTCODE</InputLabel>
            <Input type="text"
              value={this.state.EZip}
              onChange={(event) => this.handleForm(event, "EZip")} />
          </div>
          <div className="flex" style={{ marginTop: "20px" }}>
            <InputLabel>Number of Extra Question </InputLabel>
            <Input type="number"
              value={this.state.ExtraQ}
              onChange={(event) => this.handleForm(event, "question")} />
          </div>


          <FormControl className="formControl">
            <InputLabel htmlFor="age-native-simple">Day in the month for lottery</InputLabel>
            <Select
              native
              value={this.state.DayLottery}
              onChange={this.handleChange}
              name="DayLottery"
            >
              <option aria-label="None" value="" />
              {Array.from(Array(28), (e, i) => {
           return <option value={i+1}>{i+1}</option>
                })}
            </Select>
          </FormControl>
          {this.state.usergroups.length>0 && this.state.selectedUserGroup?
                                <UsergroupSelect handleRegion={this.handleUsergroup}
                                 regions={this.state.usergroups}
                                 selectedRegion={this.state.selectedUserGroup}
                                  label="GEBRUIKERS GROEPEN"/>
                                    :null}
        </div>
      </div>

    </div>)
  }
}
const mapStateToProps = (state) => (
  {
    OneRegion: state.RegionReducer.OneRegion,
    regions: state.RegionReducer.regions,
    usergroups: state.RegionReducer.usergroups,

  });

const mapDispatchToProps = dispatch => {
  return {
    GetRegionById: (id) => dispatch(actionCreators.GetRegionById(id)),
    EditRegionById: (data) => dispatch(actionCreators.EditRegionById(data)),
    AddRegion: (data) => dispatch(actionCreators.AddRegion(data)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRegion))