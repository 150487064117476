let defaultState = {
    DeleteImg: [],
    images: [],
    OneImage: [],
    usergroups:[],
    products:[],
    StatusChanged:[],
    reservations:[],
    regions:[],
    categories:[]

    
}

const ProviderProductReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_IMG":
            return {
                ...state,
                DeleteImg: [action.DeleteImg]
            }
        case "GET_ONE_IMAGE":
            return {
                ...state,
                OneImage: [action.OneImage]
            }
            case "GET_UPDATED_DATA":
                return {
                    ...state,
                    StatusChanged: [action.StatusChanged]
                }
     
        case "GET_PROVIDER_PRODUCTS":
            return {
                ...state,
                products: [action.products]
            }
            case "GET_RESERVATION_FOR_ADMIN":
                return {
                    ...state,
                    reservations: [action.reservations]
                }
            case "GET_USERGROUP":
                return {
                    ...state,
                    usergroups: [action.usergroups]
                }
                case "GET_REGION":
                    return {
                        ...state,
                        regions: [action.regions]
                    }
                case "GET_CATEGORY":
                    return {
                        ...state,
                        categories: [action.categories]
                    }
        default:
            return {
                ...state
            }
    }
}
export default ProviderProductReducer;