import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../../Tables/ResultTable'



const headCells = [
    { id: 'TESTNAME', numeric: false, disablePadding: true, label: 'TEST NAME' },
    { id: 'SUBJECT', numeric: false, disablePadding: false, label: 'SUBJECT' },
    { id: 'DATE', numeric: false, disablePadding: true, label: 'DATE' },
    { id: 'TAKERNAME', numeric: false, disablePadding: true, label: 'TAKER NAME'},
    { id: 'ANSWERSRIGHT', numeric: false, disablePadding: true, label: 'ANSWERS RIGHT' },
    { id: 'RESULT', numeric: false, disablePadding: true, label: 'RESULT' },
    { id: 'ANSWERSWRONG', numeric: false, disablePadding: true, label: 'ANSWERS WRONG' },
  ];

class Result extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      DeleteSub: [],
      Delstatus: 0
    }
  }
  componentWillMount() {
    this.props.GetAllResults();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.results) {
      this.setState({ results: nextProps.results[0] })
    }
  }
  // handleDelete = (selected) => {
  //   var data = this.state.subs;
  //   selected.forEach(element => {
  //     this.props.DeletSubscription(element);
  //     for(var i = 0; i < data.length; i++) {
  //       if(data[i]._id == element) {
  //           data.splice(i, 1);
  //           break;
  //       }
  //     }
  //   }
  //   );
  //   this.setState({subs:data});
  // }
    render(){
        return(
            <div>
            

             
                 <PageMenu heading="Result" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 btnhead={'Result'}
                 AddNewLink={'/result/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Table headCells={headCells} rows={this.state.results} editLink={'result'}/>
                 </div>
                  
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    results: state.ResultReducer.results,
    // DeleteSub: state.SubReducer.DeleteSub
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllResults: () => dispatch(actionCreators.GetAllResults()),
    // DeletSubscription: (id) => dispatch(actionCreators.DeletSubscription(id)),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Result))