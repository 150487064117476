import { axiosClient } from '../../../../../../helper';
import { appStatusAction } from '../../../../../../reducer';


// Loyality Actions
export function DeleteTest(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletTest`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletTestSuccess(res.data));
            dispatch(appStatusAction("Test Successfully Deleted"));
        })
    }
}

export function DeletTestSuccess(data) {
    return {
        type: "DELETE_TEST",
        DeleteTest:data
    }
}

export function GetTestById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetTestById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetTestByIdSuccess(res.data.data));
        })
    }
}

export function GetTestByIdSuccess(data) {
    return {
        type: "GET_ONE_TEST",
        OneTest:data
    }
}
export function EditTest(data){
    return (dispatch) => {

        return axiosClient().post(`/EditTest`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditTestSuccess(res.data.data));
            dispatch(appStatusAction("Test Successfully Edited"));

        })
    }
}

export function EditTestSuccess(data) {
    return {
        type: "EDIT_TEST",
        OneTest:data
    }
}

export function AddResult(data){
    return (dispatch) => {

        return axiosClient().post(`/AddResult`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddResultSuccess(res.data.data));
            dispatch(appStatusAction("Result Successfully Added"));

        })
    }
}

export function AddResultSuccess(data) {
    return {
        type: "ADD_RESULT",
        OneResult:data
    }
}
export function GetAllResults() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllResults`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllResultsSuccess(res.data.data));
        })
    }
}

export function GetAllResultsSuccess(data) {
    return {
        type: "GET_RESULTS",
        results:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllUsergroups`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.data));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}