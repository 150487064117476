import { axiosClient } from '../../../../../../helper';
import { appStatusAction } from '../../../../../../reducer';


// Loyality Actions
export function DeleteTestQuestion(id){
    return (dispatch) => {

        return axiosClient().post(`/DeleteTestQuestion`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteTestQuestionSuccess(res.data));
            dispatch(appStatusAction("Test Question Successfully Deleted"));
        })
    }
}

export function DeleteTestQuestionSuccess(data) {
    return {
        type: "DELETE_TESTQUESTION",
        DeleteTestQuestion:data
    }
}

export function GetTestQuestionById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetTestQuestionById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetTestQuestionByIdSuccess(res.data.data));
        })
    }
}

export function GetTestQuestionByIdSuccess(data) {
    return {
        type: "GET_ONE_TESTQUESTION",
        OneTestQuestion:data
    }
}
export function EditTestQuestion(data){
    return (dispatch) => {

        return axiosClient().post(`/EditTestQuestion`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditTestQuestionSuccess(res.data.data));
            dispatch(appStatusAction("Test Question Successfully Edited"));
        })
    }
}

export function EditTestQuestionSuccess(data) {
    return {
        type: "EDIT_TESTQUESTION",
        OneTestQuestion:data
    }
}

export function AddTestQuestion(data){
    return (dispatch) => {

        return axiosClient().post(`/AddTestQuestion`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddTestQuestionSuccess(res.data.data));
            dispatch(appStatusAction("Test Question Successfully Added"));
        })
    }
}

export function AddTestQuestionSuccess(data) {
    return {
        type: "ADD_TESTQUESTION",
        OneTestQuestion:data
    }
}
export function GetAllTestQuestions() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllTestQuestions`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllTestQuestionsSuccess(res.data.data));
        })
    }
}

export function GetAllTestQuestionsSuccess(data) {
    return {
        type: "GET_TESTQUESTION",
        testQuestions:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}
export function GetAllSubCategories() {
    return (dispatch) => {
        return axiosClient().get(`/getSubCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllSubCategoriesSuccess(res.data.result));
        })
    }
}

export function GetAllSubCategoriesSuccess(data) {
    return {
        type: "GET_SUBCATEGORY",
        subcategories:data
    }
}


// 2nd Tab

export function DeletTestAnswer(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletTestAnswer`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletTestAnswerSuccess(res.data));
            dispatch(appStatusAction("Test Answer Successfully Deleted"));
        })
    }
}

export function DeletTestAnswerSuccess(data) {
    return {
        type: "DELETE_TESTANSWER",
        DeleteTestAnswer:data
    }
}

export function GetTestAnswerById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetTestAnswerById`,id).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetTestAnswerByIdSuccess(res.data.data));
        })
    }
}

export function GetTestAnswerByIdSuccess(data) {
    return {
        type: "ADD_TESTANSWER",
        OneTestAnswer:data
    }
}
export function EditTestAnswer(data){
    return (dispatch) => {

        return axiosClient().post(`/EditTestAnswer`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditTestAnswerSuccess(res.data.data));
            dispatch(appStatusAction("Test Answer Successfully Edited"));
        })
    }
}

export function EditTestAnswerSuccess(data) {
    return {
        type: "ADD_TESTANSWER",
        OneTestAnswer:data
    }
}

export function AddTestAnswer(data){
    return (dispatch) => {

        return axiosClient().post(`/AddTestAnswer`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddTestAnswerSuccess(res.data.data));
            dispatch(appStatusAction("Test Answer Successfully Added"));
        })
    }
}

export function AddTestAnswerSuccess(data) {
    return {
        type: "ADD_TESTANSWER",
        OneTestAnswer:data
    }
}
export function GetAllTestAnswer() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllTestAnswer`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllTestAnswerSuccess(res.data.data));
        })
    }
}

export function GetAllTestAnswerSuccess(data) {
    return {
        type: "GET_TESTANSWER",
        testAnswers:data
    }
}