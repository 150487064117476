import React, { useEffect, useState } from "react";
import PageMenu from "../../../page-menu/PageMenu";
import { axiosClient, BASE_URL } from "../../../../helper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CsvDownload from 'react-json-to-csv'

function Token(props) {
  const [tokenData, setTokenData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axiosClient()
      .get("/getUserTokensAdmin")
      .then((res) => {
        console.log(res.data.result);
        setTokenData(res.data.result);
      })
      .catch((err) => setError("Error in getting data... Please check."));
  }, []);

  const pdfReport = (token, username, orderNo, invoiceNo) => {
    axiosClient()
      .get(`/getPDFReport/${token}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${invoiceNo}_${orderNo}_${token}_${username}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        // setTimeout(() => window.URL.revokeObjectURL(url), 100);
      })
      .catch((err) => setError("Error in getting data... Please check."));
  };

  const pdfReportA4 = (token, username, orderNo, invoiceNo) => {
    axiosClient()
      .get(`/getPDFReportA4/${token}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${invoiceNo}_${orderNo}_${token}_${username}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        // setTimeout(() => window.URL.revokeObjectURL(url), 100);
      })
      .catch((err) => setError("Error in getting data... Please check."));
  };

  return (
    <div>
      <PageMenu
        heading="BU Ordered Tokens"
        open={props.open ? "page-menu-open" : null}
        AddNew={false}
        btnhead={"Subscription"}
        AddNewLink={"/subscription/new"}
      />
      <div className={`upper-table ${props.open ? "upper-table-open" : ""}`}>
        <Paper style={{ padding: "15px" }}>
          {error.length > 0 ? (
            <div
              style={{ color: "red", fontSize: "1.5rem", marginBottom: "15px" }}
            >
              {error}
            </div>
          ) : null}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(9, 1fr)",
              columnGap: "10px",
            }}
          >
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>
              Gebruikersnaam
            </div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>
              Factuur nr
            </div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Bestelnr</div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Token</div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Toestand</div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Token nrs</div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Prijs</div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Acties</div>
            <div style={{ fontSize: "1rem", fontWeight: "700" }}>Download</div>
          </div>

          {tokenData.length > 0 ? (
            tokenData.map((d) => (
              <div
                key={d._id}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(10, 1fr)",
                  justifyContent: "space-between",
                  columnGap: "10px",
                  marginTop: "20px",
                }}
              >
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.userName}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.invoiceNo}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.orderNo}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.genToken}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.paymentStatus}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.tokenNumber}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  {d.tokenPrice}
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  <Button
                    onClick={() =>
                      pdfReport(d.genToken, d.userName, d.orderNo, d.invoiceNo)
                    }
                  >
                    Print A3 PDF
                  </Button>
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  <Button
                    onClick={() =>
                      pdfReportA4(
                        d.genToken,
                        d.userName,
                        d.orderNo,
                        d.invoiceNo
                      )
                    }
                  >
                    Print A4 PDF
                  </Button>
                </div>
                <div style={{ fontSize: "1rem", fontWeight: "300" }}>
                  <CsvDownload data={d.cuTokens}
                   filename={"user_" + d.userName + "_data.csv"}
                   style={{
                     boxShadow:"inset 0px 1px 0px 0px #e184f3",
                     background:"linear-gradient(to bottom, #c123de 5%, #a20dbd 100%)",
                     backgroundColor:"#c123de",
                     borderRadius:"6px",
                     border:"1px solid #a511c0",
                     display:"inline-block",
                     cursor:"pointer","color":"#ffffff",
                     fontSize:"15px",
                     fontWeight:"bold",
                     padding:"6px 24px",
                     textDecoration:"none",
                     textShadow:"0px 1px 0px #9b14b3"
                     }}>Download CSV ✨</CsvDownload>
                </div>
              </div>
            ))
          ) : (
              <div
                style={{ color: "red", fontSize: "1.5rem", marginBottom: "15px" }}
              >
                No Data Found.
              </div>
            )}
        </Paper>
      </div>
    </div>
  );
}

// class Token extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       subs: [],
//       DeleteSub: [],
//       Delstatus: 0,
//     };
//   }

//   render() {
//     return (
//       <div>
//         <PageMenu
//           heading="BU Ordered Tokens"
//           open={this.props.open ? "page-menu-open" : null}
//           AddNew={false}
//           btnhead={"Subscription"}
//           AddNewLink={"/subscription/new"}
//         />
//         <div
//           className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}
//         >
//           <Table
//             headCells={headCells}
//             handleDelete={this.handleDelete}
//             rows={data}
//             editLink={"subscription"}
//           />
//         </div>
//       </div>
//     );
//   }

export default Token;
