import { axiosClient } from '../../../../../../helper';
import { appStatusAction } from '../../../../../../reducer';


// Loyality Actions
export function DeleteTest(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletTest`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletTestSuccess(res.data));
            dispatch(appStatusAction("Test Successfully Deleted"));
        })
    }
}

export function DeletTestSuccess(data) {
    return {
        type: "DELETE_TEST",
        DeleteTest:data
    }
}

export function GetTestById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetTestById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetTestByIdSuccess(res.data.data));
        })
    }
}

export function GetTestByIdSuccess(data) {
    return {
        type: "GET_ONE_TEST",
        OneTest:data
    }
}
export function EditTest(data){
    return (dispatch) => {

        return axiosClient().post(`/EditTest`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditTestSuccess(res.data.data));
            dispatch(appStatusAction("Test Successfully Edited"));
        })
    }
}

export function EditTestSuccess(data) {
    return {
        type: "EDIT_TEST",
        OneTest:data
    }
}

export function AddTest(data){
    return (dispatch) => {

        return axiosClient().post(`/AddTest`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddTestSuccess(res.data.data));
            dispatch(appStatusAction("Test Successfully Added"));
        })
    }
}

export function AddTestSuccess(data) {
    return {
        type: "ADD_TEST",
        OneTest:data
    }
}
export function GetAllTest() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllTest`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllTestSuccess(res.data.data));
        })
    }
}

export function GetAllTestSuccess(data) {
    return {
        type: "GET_TEST",
        tests:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllUsergroups`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.data));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}
export function GetAllSubCategories(data) {
    return (dispatch) => {
        return axiosClient().get(`/getCategory?id=${data.Name}&subCategory=true`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllSubCategoriesSuccess(res.data.result[0].subCategory));
        })
    }
}
export function GetAllSubCategorieswithoutFilter() {
    return (dispatch) => {
        return axiosClient().get(`/getSubCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllSubCategoriesSuccess(res.data.result));
        })
    }
}

export function GetAllSubCategoriesSuccess(data) {
    return {
        type: "GET_SUBCATEGORY",
        subcategories:data
    }
}
export function GetAllTestQuestions() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllTestQuestions`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllTestQuestionsSuccess(res.data.data));
        })
    }
}

export function GetAllTestQuestionsSuccess(data) {
    return {
        type: "GET_TESTQUESTION",
        testQuestions:data
    }
}
export function GetTestQuestionsByTopic(data) {
    return (dispatch) => {
        return axiosClient().get(`/GetAllTestQuestions?topic=${data.topic}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetTestQuestionsByTopicSuccess(res.data.data));
        })
    }
}

export function GetTestQuestionsByTopicSuccess(data) {
    return {
        type: "GET_TEST_QUESTION_BY_TOPIC",
        questionsByTopic:data
    }
}