import React from 'react'
import PageMenu from '../../page-menu/PageMenu'
import Table1 from '../../Tables/WorkingTable'
import Table from '../../Tables/FAQTable'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

const headCells = [
  { id: 'how', numeric: false, disablePadding: true, label: 'HOE HET WERKT!' },
  { id: 'usergroup', numeric: false, disablePadding: false, label: 'GEBRUIKERS GROEPEN' },
  { id: 'PRIO', numeric: false, disablePadding: true, label: 'PRIO' },
];
const headCells2 = [
  { id: 'how', numeric: false, disablePadding: true, label: 'FAQ' },
  { id: 'usergroup', numeric: false, disablePadding: false, label: 'GEBRUIKERS GROEPEN' },
  { id: 'PRIO', numeric: false, disablePadding: true, label: 'PRIO' },
];

class FAQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      table1: true,
      table2: false,
      FAQS: [],
      Working: [],
      DeleteWorking: [],
      usergroups:[],
    }
  }
  componentWillMount() {
    this.props.GetAllFAQS();
    this.props.GetAllWorking();
    this.props.GetAllUserGroup();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
  }
    if (nextProps.FAQS) {
      this.setState({ FAQS: nextProps.FAQS[0] })
    }
    if (nextProps?.Working?.length > 0) {
      this.setState({ Working: nextProps.Working[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.FAQS;
    selected.forEach(element => {
      this.props.DeleteFAQ(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ FAQS: data });
  }
  handleDeleteWorking = (selected) => {
    var data = this.state.Working;
    selected.forEach(element => {
      this.props.DeleteWorking(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ FAQS: data });
  }
  handleToggle = (str) => {
    if (str === "How") {
      this.setState({ table1: true });
      this.setState({ table2: false });
    }
    else if (str === "FAQs") {
      this.setState({ table1: false });
      this.setState({ table2: true });
    }
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].title;
    }
    return value;
  };
  render() {
    return (
      <div>
        <PageMenu heading={this.state.table1 ? "Hoe het werkt!" : "FAQs"}
          open={this.props.open ? "page-menu-open" : null}
          AddNew={true}
          handleToggle={this.handleToggle}
          toggle={true}
          //  toggleLeft={'How It Works'}
          toggleRight={this.state.table1 ? 'FAQs' : 'How'}
          btnhead={this.state.table1 ? "Hoe het werkt!" : "FAQs"}
          AddNewLink={this.state.table1 ? "/how-works/new" : "/faqs/new"} />
        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          {this.state.table1 ?
            <Table1 headCells={headCells} handleDelete={this.handleDeleteWorking}
              rows={this.state.Working} getLabelUserGroups={this.getLabelUserGroups} editLink={'how-works'} />
            : null}
          {this.state.table2 ?
            <Table headCells={headCells2} handleDelete={this.handleDelete}
              rows={this.state.FAQS} getLabelUserGroups={this.getLabelUserGroups} editLink={'faqs'} />
            : null}

        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    FAQS: state.FAQReducer.FAQS,
    DeleteFAQ: state.FAQReducer.DeleteFAQ,
    DeleteWorking: state.FAQReducer.DeleteWorking,
    Working: state.FAQReducer.Working,
    usergroups: state.FAQReducer.usergroups
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllFAQS: () => dispatch(actionCreators.GetAllFAQS()),
    DeleteFAQ: (id) => dispatch(actionCreators.DeleteFAQ(id)),
    GetAllWorking: () => dispatch(actionCreators.GetAllWorking()),
    DeleteWorking: (id) => dispatch(actionCreators.DeleteWorking(id)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup())
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQ))