import { axiosClient } from '../../../../helper';
import { appStatusAction } from '../../../../reducer';


// Loyality Actions
export function DeleteImg(id){
    return (dispatch) => {

        return axiosClient().post(`/DeleteUserImg`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteImgSuccess(res.data));
            dispatch(appStatusAction("User Img Deleted"));
        })
    }
}

export function DeleteImgSuccess(data) {
    return {
        type: "DELETE_IMG",
        DeleteImg:data
    }
}

export function GetBUImgById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetUserImgById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetBUImgByIdSuccess(res.data.data));
        })
    }
}

export function GetBUImgByIdSuccess(data) {
    return {
        type: "GET_ONE_IMAGE",
        OneImage:data
    }
}
export function EditBUImg(data){
    return (dispatch) => {

        return axiosClient().post(`/EditUserImg`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditBUImgSuccess(res.data.data));
            dispatch(appStatusAction("User Img Edited"));
        })
    }
}

export function EditBUImgSuccess(data) {
    return {
        type: "GET_ONE_IMAGE",
        OneImage:data
    }
}

export function UploadBUImg(data){
    return (dispatch) => {

        return axiosClient().post(`/UploadUserImg`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(UploadBUImgSuccess(res.data.data));
            dispatch(appStatusAction("User Img Uploaded"));
        })
    }
}

export function UploadBUImgSuccess(data) {
    return {
        type: "GET_ONE_IMAGE",
        OneImage:data
    }
}
export function GetAllBUImg() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllUserImg`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllBUImgSuccess(res.data.data));
        })
    }
}

export function GetAllBUImgSuccess(data) {
    return {
        type: "GET_BUIMG",
        images:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.data));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategoryByUserGroup/5f9a753ead9a6e0c578100ff`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
  }
  
  export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
  }