import React from 'react';
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import BasicInfo from './BasicInfo';
import Advance from './Advance';
import Image from './Image';
import Table from '../../../Tables/TestAnswerTable'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import axios, { post } from 'axios';


const headCells = [
    { id: 'how', numeric: false, disablePadding: false, label: 'Answer' },
    { id: 'usergroup', numeric: false, disablePadding: false, label: 'Order' }
];
class AddQuestionAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topic: "",
            sub_topic: "",
            txtBook: "",
            price: 0,
            ch: "",
            sec: "",
            public: true,
            Q: "",
            usergroups: [],
            active: false,
            duration: 0,
            points: 0,
            score: 0,
            basicInfo: true,
            advance: false,
            image: false,
            isNew: false,
            table1: true,
            table2: false,
            imageFileName: "",
            OneTestQuestion: [],
            subcategories: [],
            categories: [],
            priceField:true,
            value:"Ja",
            UpdatedUserGroup:[],
            selectedUserGroup:[],
            ACTIEF:"Ja",
            answers:[]
        }
    }

    componentWillMount() {
        this.props.GetAllUserGroup();
        this.props.GetAllCategory();
        this.props.GetAllSubCategories();

        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.props.GetTestQuestionById(this.props.match.params.id);
        }
        else {
            this.setState({
                OneTestQuestion: {
                    topic: "",
                    sub_topic: "",
                    txtBook: "",
                    price: 0,
                    ch: "",
                    sec: "",
                    public: true,
                    Q: "",
                    usergroups: [],
                    active: false,
                    duration: 0,
                    points: 0,
                    score: 0
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.subcategories) {
            this.setState({ subcategories: nextProps.subcategories[0] });
        }
        if (nextProps.categories) {
            this.setState({ categories: nextProps.categories[0] });
        }
        if (nextProps.usergroups) {
            console.log("usergroups",nextProps.usergroups)
            this.setState({ usergroups: nextProps.usergroups[0] });
        }
        if (nextProps.testAnswers) {
            this.setState({ testAnswers: nextProps.testAnswers[0] })
          }
        if (nextProps.OneTestQuestion?.length > 0 & this.state.isNew) {
            this.setState({ OneImage: nextProps.OneTestQuestion[0] });
            this.setState({
                topic: nextProps.OneTestQuestion[0].topic,
                sub_topic: nextProps.OneTestQuestion[0].sub_topic,
                txtBook: nextProps.OneTestQuestion[0].txtBook,
                price: nextProps.OneTestQuestion[0].price,
                ch: nextProps.OneTestQuestion[0].ch,
                sec: nextProps.OneTestQuestion[0].sec,
                public: nextProps.OneTestQuestion[0].public,
                Q: nextProps.OneTestQuestion[0].Q,
                selectedUserGroup: nextProps.OneTestQuestion[0].usergroups,
                active: nextProps.OneTestQuestion[0].active,
                duration: nextProps.OneTestQuestion[0].duration,
                points: nextProps.OneTestQuestion[0].points,
                score: nextProps.OneTestQuestion[0].score,
                imageFileName: nextProps.OneTestQuestion[0]?.image,
                answers:nextProps.OneTestQuestion[0].answers
            })
        }
    }
    handleDelete = (selected) => {
        var data = this.state.answers;
        selected.forEach(element => {
          this.props.DeleteTestAnswer(element);
          for(var i = 0; i < data.length; i++) {
            if(data[i]._id == element) {
                data.splice(i, 1);
                break;
            }
          }
        }
        );
        this.setState({answers:data});
      }
    onFormSubmit = (id) => {
        // Stop form submit
        this.fileUpload(this.state.file,id).then((response) => {
            console.log(response.data);
        })
    }
    handleForm = (event, val) => {
      
        if (val == "txtBook") {
            this.setState({ txtBook: event.target.value })
        }
        else if (val == "ch") {
            this.setState({ ch: event.target.value })
        }
        else if (val == "sec") {
            this.setState({ sec: event.target.value })
        }
        else if (val == "Q") {
            this.setState({ Q: event.target.value })
        }
        else if (val == "price") {
            this.setState({ price: event.target.value })
        }
        else if (val == "duration") {
            this.setState({ duration: event.target.value })
        }
        else if (val == "points") {
            this.setState({ points: event.target.value })
        }
        else if (val == "score") {
            this.setState({ score: event.target.value })
        }
        else if (val == "topic") {
            this.setState({ topic: event.target.value })
        }
        else if (val == "sub_topic") {
            this.setState({ sub_topic: event.target.value })
        }
    }

    handleRadio = (event)=>{
        this.setState({value:event.target.value});
        if(event.target.value ==="Nee"){
                this.setState({priceField:false});
                this.setState({price:0});
                this.setState({public:false})
        }
        else if(event.target.value === "Ja"){
            this.setState({priceField:true});
            this.setState({public:true})
        }
    }
    handleRadioACTIEF = (event)=>{
        this.setState({ACTIEF:event.target.value});
        if(event.target.value ==="Nee"){
            this.setState({active:false})
    }
    else if(event.target.value === "Ja"){
        this.setState({active:true})
    }
    }
    fileUpload = (file,id) => {
        const url = `http://api.beelicensed.com/UploadTestQuestion/${id}`;
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(url, formData, config)
    }

    handleFileAdded = (fil) => {
        this.setState({ file: fil })
    }


    addNewData = () => {
        var data = {
            topic: this.state.topic,
            sub_topic: this.state.sub_topic,
            txtBook: this.state.txtBook,
            price: this.state.price,
            ch: this.state.ch,
            sec: this.state.sec,
            public: this.state.public,
            Q: this.state.Q,
            usergroups: this.state.UpdatedUserGroup,
            active: this.state.active,
            duration: this.state.duration,
            points: this.state.points,
            score: this.state.score
        }
        axios.post(`http://api.beelicensed.com/AddTestQuestion`, data, {  
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            console.log(response.data);
            this.onFormSubmit(response.data?.data._id)
        }).catch(e => {

        })
    }
    editFile = () =>{
        var data = {
            Id: this.props.match.params.id,
            topic: this.state.topic,
            sub_topic: this.state.sub_topic,
            txtBook: this.state.txtBook,
            price: this.state.price,
            ch: this.state.ch,
            sec: this.state.sec,
            public: this.state.public,
            Q: this.state.Q,
            usergroups: this.state.UpdatedUserGroup,
            active: this.state.active,
            duration: this.state.duration,
            points: this.state.points,
            score: this.state.score
        }
        axios.post(`http://api.beelicensed.com/EditTestQuestion`, data, {  
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            if(this.state.file !== undefined){
            this.onFormSubmit(response.data?.data._id)
            }
        }).catch(e => {

        })
    }

    saveHandler = () => {
        if (this.state.isNew) {
              this.editFile();
        }
        else {
            if(this.state.file !== undefined){
            this.addNewData();
            }
            else{
                alert("Please upload file and then try again!")
            }
            //this.onFormEditSubmit();
        }
    }

    handleToggle = (str) => {
        if (str === "Question") {
            this.setState({ table1: true });
            this.setState({ table2: false });
        }
        else if (str === "Answer") {
            this.setState({ table1: false });
            this.setState({ table2: true });
        }
    }

    handleTabs = (str) => {
        if (str === "basic") {
            this.setState({ basicInfo: true });
            this.setState({ advance: false });
            this.setState({ image: false });
        }
        else if (str === "adv") {
            this.setState({ basicInfo: false });
            this.setState({ advance: true });
            this.setState({ image: false });
        }
        else if (str === "img") {
            this.setState({ basicInfo: false });
            this.setState({ advance: false });
            this.setState({ image: true });
        }
    }
    handleUsergroup = (ids) => {
        this.setState({ UpdatedUserGroup: ids });
      }
    render() {
        return (<div>
            <PageMenu heading={this.state.table1 ? "Add Question" : "Answers"}
                open={this.props.open ? "page-menu-open" : null}
                backArrow={this.state.table1 ? true : false}
                handleToggle={this.handleToggle}
                AddNew={this.state.table2 ? true : false}
                toggle={true}
                // toggleLeft={'Question'}
                toggleRight={this.state.table2 ? 'Question' : "Answer"}
                btnhead={this.state.table2 ? "Answers" : ""}
                AddNewLink={this.state.table2?`/extra-answer/${this.props.match.params.id}/new`:""}
                backTitle={this.state.table1 ? 'Question & Answers' : ""}
                backUrl={'/question-answer'}
                SaveBtn={this.state.table1 ? true : false}
                saveHandler={this.saveHandler} />


            {this.state.table1 ?
                <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                    <div className="form-head">
                        <span onClick={() => this.handleTabs("basic")} className={`${this.state.basicInfo ? "form-head-menu-selected" : ""} form-head-menu`}>Basic Info</span>
                        <span onClick={() => this.handleTabs("adv")} className={`${this.state.advance ? "form-head-menu-selected" : ""} form-head-menu`}>Advance</span>
                        <span onClick={() => this.handleTabs("img")} className={`${this.state.image ? "form-head-menu-selected" : ""} form-head-menu`}>Photos</span>
                    </div>

                    <div className="admin-form">
                        {this.state.basicInfo ?
                            <BasicInfo
                                categories={this.state.categories}
                                subcategories={this.state.subcategories}
                                OneTestQuestion={this.state.OneTestQuestion}
                                topic={this.state.topic}
                                sub_topic={this.state.sub_topic}
                                txtBook={this.state.txtBook}
                                price={this.state.price}
                                ch={this.state.ch}
                                sec={this.state.sec}
                                public={this.state.public}
                                Q={this.state.Q}
                                active={this.state.active}
                                duration={this.state.duration}
                                points={this.state.points}
                                score={this.state.score}
                                handleForm={this.handleForm}
                                priceField={this.state.priceField}
                                value={this.state.value}
                                handleRadio={this.handleRadio}
                            />
                            : null}
                        {this.state.advance ?
                            <Advance 
                             UpdatedUserGroup={this.state.UpdatedUserGroup}
                             selectedUserGroup={this.state.selectedUserGroup}
                             handleUsergroup={this.handleUsergroup}
                             usergroups={this.state.usergroups}
                             ACTIEF={this.state.ACTIEF}
                             handleRadioACTIEF={this.handleRadioACTIEF}
                             />
                            : null}
                        {this.state.image ?
                            <Image isNew={this.state.isNew}
                                onFormSubmit={this.onFormSubmit}
                                onChange={this.onChange}
                                handleFileAdded={this.handleFileAdded}
                                file={this.state.file}
                                imageFileName={this.state.imageFileName} />
                            : null}


                    </div>
                </div>
                : null}
            {this.state.table2 ?
                <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
                    <Table headCells={headCells}
                        rows={this.state.answers}
                        handleDelete={this.handleDelete}
                        editLink={`extra-answer/${this.props.match.params.id}`} />
                  
                </div>
                : null}

        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneTestQuestion: state.TestQuestionReducer.OneTestQuestion,
        usergroups: state.TestQuestionReducer.usergroups,
        subcategories: state.TestQuestionReducer.subcategories,
        categories: state.TestQuestionReducer.categories,
        DeleteTestAnswer:state.TestQuestionReducer.DeleteTestAnswer,
        testAnswers:state.TestQuestionReducer.testAnswers
    });

const mapDispatchToProps = dispatch => {
    return {
        GetTestQuestionById: (id) => dispatch(actionCreators.GetTestQuestionById(id)),
        EditTestQuestion: (data) => dispatch(actionCreators.EditTestQuestion(data)),
        GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
        GetAllSubCategories: () => dispatch(actionCreators.GetAllSubCategories()),
        GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
        DeletTestAnswer: (id) => dispatch(actionCreators.DeletTestAnswer(id)),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddQuestionAnswer))