import React from 'react';

import PageMenu from '../page-menu/PageMenu'
 
import {Button, Input,InputLabel } from '@material-ui/core';

export default class AddBusiness extends React.Component{
    constructor(props){
        super(props);
        this.state={
            name:"",
            phoneNumber:"",
            email: "",
            password: "",
        }
    }
    componentWillMount(){
        this.setState({name:this.props.match.params.id})
    }
    handleForm = (event,val)=>{
        if(val  === "name"){
            this.setState({name:event.target.value})
        }
        else if(val  === "phone"){
            this.setState({phoneNumber:event.target.value})
        }
        else if(val  === "password"){
            this.setState({password:event.target.value})
        }
        else if(val  === "email"){
            this.setState({email:event.target.value})
        }

    }

    render(){
        return(<div>
                 <PageMenu heading="Voeg bedrijf toe" 
                   open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 backArrow={true}
                 backTitle={'Zakelijke'}
                 backUrl={'/Zakelijke'}
                 SaveBtn={true}/>
                  <div className={`upper-form ${this.props.open?"upper-form-open":""}`}>
                      <div className="form-head">
                        <span>Basic Info</span>
                      </div>
                      <div className="admin-form">
                          <div className="flex">
                          <InputLabel>Name</InputLabel>
                          <Input type="text"
                           value={this.state.name}
                           onChange={(event)=>this.handleForm(event,"name")} />
                          </div>
                          <div className="flex">
                          <InputLabel>Email</InputLabel>
                          <Input type="text"
                           value={this.state.email}
                           onChange={(event)=>this.handleForm(event,"email")} />
                          </div>
                          <div className="flex">
                          <InputLabel>Phone Number</InputLabel>
                          <Input type="text"
                           value={this.state.phoneNumber}
                           onChange={(event)=>this.handleForm(event,"phone")} />
                          </div>
                          <div className="flex">
                          <InputLabel>Password</InputLabel>
                          <Input type="password"
                           value={this.state.password}
                           onChange={(event)=>this.handleForm(event,"password")} />
                          </div>
                          
                        
                      </div>
                  </div>
        </div>)
    }
}