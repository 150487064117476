import { axiosClient } from '../../../../../helper';
import { appStatusAction } from '../../../../../reducer';


// Loyality Actions
export function DeleteImg(id){
    return (dispatch) => {

        return axiosClient().post(`/DeleteUserImg`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteImgSuccess(res.data));
            dispatch(appStatusAction("User Img Deleted"));
        })
    }
}

export function DeleteImgSuccess(data) {
    return {
        type: "DELETE_IMG",
        DeleteImg:data
    }
}

export function GetBUImgById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetUserImgById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetBUImgByIdSuccess(res.data.data));
        })
    }
}

export function GetBUImgByIdSuccess(data) {
    return {
        type: "GET_ONE_IMAGE",
        OneImage:data
    }
}
export function EditBUImg(data){
    return (dispatch) => {

        return axiosClient().post(`/EditUserImg`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditBUImgSuccess(res.data.data));
            dispatch(appStatusAction("User Img Edited"));
        })
    }
}

export function EditBUImgSuccess(data) {
    return {
        type: "GET_ONE_IMAGE",
        OneImage:data
    }
}

export function AuthorizeProduct(data){
    return (dispatch) => {

        return axiosClient().post(`/AuthorizeProduct`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AuthorizeProductSuccess(res.data.data));
            dispatch(appStatusAction("Statuc Changes"));
        })
    }
}

export function AuthorizeProductSuccess(data) {
    return {
        type: "GET_UPDATED_DATA",
        StatusChanged:data
    }
}
export function GetAllReservationsForAdmin(){
    return (dispatch) => {

        return axiosClient().get(`/getAllReservationsForAdmin`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllReservationsForAdminSuccess(res.data.data));
            // dispatch(appStatusAction("Statuc Changes"));
        })
    }
}

export function GetAllReservationsForAdminSuccess(data) {
    return {
        type: "GET_RESERVATION_FOR_ADMIN",
        reservations:data
    }
}
export function GetAllProducts() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllProducts`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllProductsSuccess(res.data.data));
        })
    }
}

export function GetAllProductsSuccess(data) {
    return {
        type: "GET_PROVIDER_PRODUCTS",
        products:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.data));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}
export function GetAllRegion() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllRegionSuccess(res.data.data));
        })
    }
}

export function GetAllRegionSuccess(data) {
    return {
        type: "GET_REGION",
        regions:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}