import { axiosClient } from '../../../../helper';
import {appStatusAction} from '../../../../reducer'

// Loyality Actions
export function DeleteBULoyaltyById(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletCULoyalty`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteBULoyaltyByIdSuccess(res.data));
            dispatch(appStatusAction("CU Loyalty Successfully Deleted"));
        })
    }
}

export function DeleteBULoyaltyByIdSuccess(data) {
    return {
        type: "DELETE_LOYALTY",
        DeleteBuLoyalty:data
    }
}

export function GetBULoyaltyById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetCULoyaltyById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetBULoyaltyByIdSuccess(res.data.data));
        })
    }
}

export function GetBULoyaltyByIdSuccess(data) {
    return {
        type: "GET_ONE_LOYALTY",
        OneBuLoyalty:data
    }
}
export function EditBULoyaltyById(data){
    return (dispatch) => {

        return axiosClient().post(`/EditCULoyalty`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditBULoyaltyByIdSuccess(res.data.data));
            dispatch(appStatusAction("CU Loyalty Successfully Edited"));
        })
    }
}

export function EditBULoyaltyByIdSuccess(data) {
    return {
        type: "Edit_LOYALTY",
        OneBuLoyalty:data
    }
}
export function getBuLoyalty() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllCULoyalty`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(getBuLoyaltySuccess(res.data.data));
        })
    }
}
export function getPoppingtimeUsers() {
    return (dispatch) => {
        return axiosClient().get(`/getUsersByRole?role=bu-user`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(getPoppingtimeUsersSuccess(res.data.data));
        })
    }
}
export function getPoppingtimeUsersSuccess(data) {
    return {
        type: "GET_POPPINGTIMEUSERS",
        popigumUsers:data
    }
}
export function getBuLoyaltySuccess(data) {
    return {
        type: "GET_LOYALTY",
        BuLoyalty:data
    }
}

export function GetAllRegion() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllRegionSuccess(res.data.data));
        })
    }
}

export function GetAllRegionSuccess(data) {
    return {
        type: "GET_REGION",
        regions:data
    }
}
export function AddBULoyalty(data){
    return (dispatch) => {

        return axiosClient().post(`/AddCULoyalty`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddBULoyaltySuccess(res.data.data));
            dispatch(appStatusAction("CU Loyalty Successfully Added"));
        })
    }
}
export function poppingtimeInvoices(){
    let dataObj = [];
    let index = 0;
    return (dispatch) => {
         axiosClient().post(`/getAllReservations`,{id: "5ff312cf6cb7360d72c742ed"}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            res.data.data.forEach((element) => {
                let n = new Date(element.createdAt)
                let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
  
                let obj2 = {
                  key: index,
                  no: index + 1,
                  invoiceNumber: element.invoiceNumber,
                  orderNumber: element.orderNumber,
                  status: element.status,
                  grandTotal: element.grand_total,
                  createdAt: d,
                  type:"reservation",
                  CUpaymentStatus: element.CUpaymentStatus,
                  CU: element.CU,
                  grand_total: element.grand_total,
                  gst: element.gst,
                  _id: element._id,
                  total: element.total,
                  discount: element.discount,
                  BookedSlots: element.BookedSlots,
                  ArrivalTime: element.ArrivalTime,
                  ProductId: element.ProductId,
                }
                dataObj.push(obj2);
                index = index + 1;
              });
               dispatch(poppingtimeInvoicesSuccess(dataObj));
          //  dispatch(appStatusAction("CU Loyalty Successfully Added"));
        })
        axiosClient().get(`/getGiftsbyUserId/${"5ff312cf6cb7360d72c742ed"}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            if (res.data.data.length > 0) {
                res.data.data.forEach((element) => {
                  if (element?.paymentStatus === "paid") {
                    let n = new Date(element.createdAt)
                    let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
                    let obj2 = {
                      key: index,
                      no: index + 1,
                      invoiceNumber: element.invoiceNumber,
                      orderNumber: new Date(element.createdAt).getTime(),
                      status: element.paymentStatus,
                      grandTotal: element.discount,
                      createdAt: d,
                      CUpaymentStatus: element.paymentStatus,
                      CU: {},
                      grand_total: element.discount,
                      gst: 0,
                      type:"gift",
                      _id:element._id,
                      total: element.discount,
                      discount: 0,
                      BookedSlots: [],
                      ArrivalTime: 36000000,
                      ProductId: [],
                    }
                    dataObj.push(obj2);
                    index = index + 1;
                  }
                });
              }
               dispatch(poppingtimeInvoicesSuccess(dataObj));
          //  dispatch(appStatusAction("CU Loyalty Successfully Added"));
        })
    }
}
export function poppingtimeInvoicesSuccess(data) {
    return {
        type: "POPPINGUMTIME_INVOICE",
        InvoicesData:data
    }
}
export function AddBULoyaltySuccess(data) {
    return {
        type: "ADD_LOYALTY",
        OneBuLoyalty:data
    }
}