import React from 'react';
import PageMenu from '../page-menu/PageMenu'
import { Input, InputLabel } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MultipleSelect from './RegionSelect'
import Image from './Image'
import { MultiCascader } from 'rsuite';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import axios, { post } from 'axios'
var fileUrl = require('file-url');
class AddPicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: "",
            lottery: false,
            stock: false,
            regions: [],
            std: false,
            advert: false,
            CU: false,
            BU: false,
            userType: "",
            basicInfo: true,
            image: false,
            isNew: false,
            checkedA:false,
            subtitle: "",
            file: null,
            imageFileName: "",
            number: 5,
            OneImage: [],
            selectedRegion: [],
            active: false,
            pictureCategory: "",
            pictureSubcategory: "",
            CascaderValue: [],

        }
        // this.onFormSubmit = this.onFormSubmit.bind(this)
        // this.onChange = this.onChange.bind(this)
        // this.fileUpload = this.fileUpload.bind(this)
    }
    myfuc = () => {

        this.props.GetBUImgById(this.props.match.params.id);
    }
    componentWillMount() {
        // this.downloadPicture("http://api.beelicensed.com/uploads/1594439861329-dlkugiy.PNG")
        this.props.GetAllUserGroup();
        this.props.GetAllCategory();

        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.setState(this.myfuc)
        }
        else {
            this.setState({
                OneImage: {
                    Name: "",
                    lottery: false,
                    stock: false,
                    regions: [],
                    std: false,
                    advert: false,
                    userType: "",
                    active: false,
                    subtitle: ""
                }
            })
        }
    }
    downloadPicture(MY_URL) {
        var request = new XMLHttpRequest();
        request.open('GET', MY_URL, true);
        request.responseType = 'blob';
        request.onload = function () {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
                console.log('DataURL:', e.target.result);
            };
        };
        request.send();
    }
    CascaderHandler = (categories) => {
        if (categories[0]) {
            var option = [];
            var uncheck = [];
            var child = [];
            // var data = [];
            categories[0].forEach(element => {
                child = []
                element.subCategory.forEach(ele => {
                    child.push({
                        value: ele._id,
                        label: ele.name,
                    })
                })
                option.push({
                    value: element._id,
                    label: element.name,
                    children: child
                })
                uncheck.push(element._id)
            })
            this.setState({ uncheck: uncheck })
            this.setState({ option: option })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.usergroups) {
            this.setState({ regions: nextProps.usergroups[0] });
        }
        if (nextProps.categories) {
            this.setState({ categories: nextProps.categories[0] });
            this.CascaderHandler(nextProps.categories);
        }
        if (nextProps.OneImage?.length > 0 & this.state.isNew) {
            this.setState({ OneImage: nextProps.OneImage[0] });
            this.setState({
                std: nextProps.OneImage[0]?.std,
                stock: nextProps.OneImage[0]?.stock,
                checkedA: nextProps.OneImage[0]?.stock,
                active: nextProps.OneImage[0]?.active,
                imageFileName: nextProps.OneImage[0]?.img,
                Name: nextProps.OneImage[0]?.Name,
                lottery: nextProps.OneImage[0]?.lottery,
                advert: nextProps.OneImage[0]?.advert,
                UpdatedRegion: nextProps.OneImage[0]?.regions,
                userType: nextProps.OneImage[0]?.userType,
                subtitle: nextProps.OneImage[0]?.subtitle,
                CascaderValue:nextProps.OneImage[0]?.subCategory,
                pictureSubcategory:nextProps.OneImage[0]?.subCategory,
                pictureCategory:nextProps.OneImage[0]?.category,
            })
            nextProps.OneImage[0].regions.forEach(element => {
                this.getLabelUserGroups(element)
            });
            // if (nextProps.OneImage[0]?.BU) {
            //     this.setState({ userType: "bu-user" })
            //     this.setState({ BU: true })
            // }
            // else if (nextProps.OneImage[0]?.CU) {
            //     this.setState({ userType: "cu-user" })
            //     this.setState({ CU: true })
            // }
        }
    }

    onFormSubmit(id) {
        // Stop form submit
        console.log(this.state.number)
        this.fileUpload(this.state.file, id).then((response) => {
            console.log(response.data);
        })
    }
    onFormEditSubmit() {
        // Stop form submit
        console.log(this.state.number)
        if (this.state.file !== "") {
            this.fileUpload(this.state.file).then((response) => {
                console.log(response.data);
            })
        }
    }
    fileUpload = (file, id) => {
        const url = `http://api.beelicensed.com/cuImgUpload/${id}`;
        var data = {
            "Name": this.state.Name,
            "lottery": this.state.lottery,
            "active": this.state.active,
            "stock": this.state.stock,
            "regions": this.state.UpdatedRegion,
            "std": this.state.std,
            "advert": this.state.advert,
            "userType": this.state.userType,
            "subtitle": this.state.subtitle,
            "category": this.state.pictureCategory,
            "subCategory": this.state.pictureSubcategory
        }
        const formData = new FormData();
        formData.append('file', file)
        //formData.append('data', JSON.stringify(data));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(url, formData, config)
    }

    handleFileAdded = (fil) => {
        this.setState({ file: fil })
        this.setState({ number: 255 })
    }
    cascaderOnChange = (value) => {
        this.setState({ CascaderValue: value })
        this.setState({ pictureSubcategory: value })
        this.setState({ pictureCategory: "5f060dc768dd4e717535bbb3" })
    }
    addNewData = () => {
        var data = {
            "Name": this.state.Name,
            "lottery": this.state.lottery,
            "active": this.state.active,
            "stock": this.state.checkedA,
            "regions": this.state.UpdatedRegion,
            "std": this.state.std,
            "advert": this.state.advert,
            "userType": this.state.userType,
            "subtitle": this.state.subtitle,
            "category": this.state.pictureCategory,
            "subCategory": this.state.pictureSubcategory
        }
        axios.post(`http://api.beelicensed.com/UploadUserImg`, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            this.onFormSubmit(response.data?.data._id)
            console.log(response.data);

        }).catch(e => {

        })
    }
    editFile = () => {
        var data = {
            "Id": this.props.match.params.id,
            "Name": this.state.Name,
            "lottery": this.state.lottery,
            "active": this.state.active,
            "stock": this.state.checkedA,
            "regions": this.state.UpdatedRegion,
            "std": this.state.std,
            "advert": this.state.advert,
            "userType": this.state.userType,
            "subtitle": this.state.subtitle
        }
        axios.post(`http://api.beelicensed.com/EditUserImg`, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            if (this.state.file !== null) {
                this.onFormSubmit(response.data?.data._id)
            }
        }).catch(e => {

        })
    }

    saveHandler = () => {
        if (this.state.isNew) {
            this.editFile();
        }
        else {
            if (this.state.file !== null) {
                this.addNewData();
            }
            else {
                alert("Please upload file and then try again!")
            }
            //this.onFormEditSubmit();
        }
    }

    handleForm = (event, val) => {
        if (val == "name") {
            this.setState({ Name: event.target.value })
        }
        else if (val == "subtitle") {
            this.setState({ subtitle: event.target.value })
        }

    }
    handleTabs = (str) => {
        if (str === "basic") {
            this.setState({ basicInfo: true });
            this.setState({ image: false });
        }

        else if (str === "img") {
            this.setState({ basicInfo: false });
            this.setState({ image: true });
        }

    }

    handleChange = (event) => {
        console.log(event.target.name,event.target.checked )
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
        if (event.target.value === "bu-user") {

            this.setState({ stock: event.target.checked });
            this.setState({ userType: "bu-user" })
            this.setState({ BU: true })
            this.setState({ CU: false })
        }
        else if (event.target.value === "cu-user") {
            this.setState({ stock: false });
            this.setState({ userType: "cu-user" })
            this.setState({ CU: true })
            this.setState({ BU: false })
        }

    };
    handleUsergroup = (ids) => {
        this.setState({ UpdatedRegion: ids });
    }
    getLabelUserGroups = (value) => {
        const index = this.state.regions?.findIndex((d) => d._id === value);
        var ref = this.state.selectedRegion;
        if (index !== -1) {
            ref.push(this.state.regions[index]._id);
        }
        this.setState({ selectedRegion: ref })
    };
    render() {
        return (<div>
            <PageMenu heading="Afbeelding toevoegen"
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                backArrow={true}
                backTitle={'Picture'}
                backUrl={'/picture'}
                SaveBtn={true}
                saveHandler={this.saveHandler} />
            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span onClick={() => this.handleTabs("basic")} className={`${this.state.basicInfo ? "form-head-menu-selected" : ""} form-head-menu`}>Basic Info</span>
                    <span onClick={() => this.handleTabs("img")} className={`${this.state.image ? "form-head-menu-selected" : ""} form-head-menu`}>Photos</span>

                </div>
                <div className="admin-form">
                    {this.state.basicInfo ?
                        <div>
                            <div className="flex">
                                <InputLabel>Name</InputLabel>
                                <Input type="text"
                                    value={this.state.Name}
                                    onChange={(event) => this.handleForm(event, "name")} />
                            </div>
                            <div className="flex">
                                <InputLabel>Subtitle</InputLabel>
                                <Input type="text"
                                    value={this.state.subtitle}
                                    onChange={(event) => this.handleForm(event, "subtitle")} />
                            </div>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch checked={this.state.active} onChange={this.handleChange} color="primary" name="active" />}
                                    label="Active"
                                />
                            </FormGroup>
                            <div>
                                <InputLabel>Image Type</InputLabel>
                                <div>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Switch checked={this.state.std} onChange={this.handleChange} color="primary" name="std" />}
                                            label="STANDARD"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={this.state.lottery} onChange={this.handleChange} color="primary" name="lottery" />}
                                            label="LOTTERY"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={this.state.advert} onChange={this.handleChange} color="primary" name="advert" />}
                                            label="ADVERTISEMENT"
                                        />
                                    </FormGroup>

                                </div>
                                <InputLabel>Choose Settings</InputLabel>
                                <div>
                                    <RadioGroup value={this.state.userType} onChange={this.handleChange}>
                                        <FormControlLabel value="bu-user" control={<Radio color="primary" />} label="BU" />
                                        <FormControlLabel value="cu-user" control={<Radio color="primary" />} label="CU" />
                                    </RadioGroup>
                                </div>
                                {this.state.userType === "bu-user" ?
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch checked={this.state.checkedA} onChange={this.handleChange} color="primary" name="checkedA" />}
                                            label="Stock"
                                        />
                                    </FormGroup>
                                    : null}
                            </div>
                            {this.state.regions?.length > 0 && this.state.selectedRegion ?
                                <MultipleSelect handleRegion={this.handleUsergroup}
                                    regions={this.state.regions}
                                    selectedRegion={this.state.selectedRegion}
                                    label="Select Region" />
                                : null}
                            <MultiCascader
                                cascade={false}
                                style={{ width: 311 }}
                                data={this.state.option}
                                uncheckableItemValues={this.state.uncheck}
                                onChange={this.cascaderOnChange}
                                value={this.state.CascaderValue}
                            />

                        </div>
                        : null}
                    {this.state.image ?
                        <Image
                            onFormSubmit={this.onFormSubmit}
                            onChange={this.onChange}
                            handleFileAdded={this.handleFileAdded}
                            isNew={this.state.isNew}
                            file={this.state.file}
                            imageFileName={this.state.imageFileName}

                        /> : null}
                </div>
            </div>

        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneImage: state.BUImageReducer.OneImage,
        usergroups: state.BUImageReducer.usergroups,
        categories: state.BUImageReducer.categories,
    });

const mapDispatchToProps = dispatch => {
    return {
        GetBUImgById: (id) => dispatch(actionCreators.GetBUImgById(id)),
        UploadBUImg: (data) => dispatch(actionCreators.UploadBUImg(data)),
        GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
        EditBUImg: (data) => dispatch(actionCreators.EditBUImg(data)),
        GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPicture))