import React from "react";
import SideNavPage from "../../Sidebar/SideNavPage";
import PageMenu from "../../page-menu/PageMenu";
import { Container } from "reactstrap";
import { Input, InputLabel } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import MultipleSelect from "../../Pictures/RegionSelect";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class AddUserGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      isActive: false,
      std: false,
      provider:false,
      description: "",
      Status:"",
      userType:"",
      isNew: false,
      regions:[],
      updatedRegion:[],
      selectedRegions:[]
    };
  }
  myfuc = () => {
    this.props.GetUsergroupById(this.props.match.params.id);
  };
  componentWillMount() {
  //  this.props.GetAllRegion();
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.setState(this.myfuc);
    } else {
      this.setState({
        OneUsergroup: {
          Title:"",
          isActive:false,
          Std:false,
          provider:false,
          userType:"",
          Desc:""

        },
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    if (this.state.isNew) {
      this.setState({ OneUsergroup: nextProps.OneUsergroup[0] });
      this.setState({
        title: nextProps.OneUsergroup[0]?.title,
        description: nextProps.OneUsergroup[0]?.description,
        isActive: nextProps.OneUsergroup[0]?.isActive,
        userType:nextProps.OneUsergroup[0]?.userType,
        std: nextProps.OneUsergroup[0]?.std,
        provider:nextProps.OneUsergroup[0]?.provider,
      });
    }
  }
  handleForm = (event, val) => {
    if (val == "title") {
      this.setState({ title: event.target.value });
    } else if (val == "description") {
      this.setState({ description: event.target.value });
    }
  };
  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  saveHandler = () => {
    if (this.state.isNew) {
      var data = {
        Id: this.props.match.params.id,
        isActive: this.state.isActive,
        std: this.state.std,
        provider: this.state.provider,
        userType:this.state.userType,
        title: this.state.title,
        description: this.state.description,
      };
      this.props.UpdateUsergroup(this.props.match.params.id, data);
    } else {
      var data = {
        isActive: this.state.isActive,
        std: this.state.std,
        provider: this.state.provider,
        title: this.state.title,
        description: this.state.description,
        userType:this.state.userType
      };
      this.props.AddUsergroup(data);
    }
  };
  handleRegion = (ids) => {
    this.setState({ updatedRegion: ids });
  };
  handleRadio = (event)=>{
    this.setState({userType:event.target.value});
    // if(event.target.value ==="BU"){
    //         this.setState({BU:true});
    // }
    // else if(event.target.value === "CU"){
    //     this.setState({CU:true});
    // }
}
  render() {
    return (
      <div>
        <PageMenu
          heading="Add User Group"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          backArrow={true}
          backTitle={"User Group"}
          backUrl={"/user-group"}
          SaveBtn={true}
          saveHandler={this.saveHandler}
        />
        <div
          className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}
        >
          <div className="form-head">
            <span>Basic Info</span>
          </div>
          <div className="admin-form">
            <div className="flex">
              <InputLabel>GEBRUIKERSGROEP </InputLabel>
              <Input
                type="text"
                value={this.state.title}
                onChange={(event) => this.handleForm(event, "title")}
              />
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.isActive}
                      onChange={this.handleChange}
                      name="isActive"
                      color="primary"
                    />
                  }
                  label="ACTIEF"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.std}
                      onChange={this.handleChange}
                      name="std"
                      color="primary"
                    />
                  }
                  label="STANDAARD"
                />
                  <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.provider}
                      onChange={this.handleChange}
                      name="provider"
                      color="primary"
                    />
                  }
                  label="Provider"
                />
                <RadioGroup value={this.state.userType} onChange={this.handleRadio}>
                  <FormControlLabel
                    value="bu-user"
                    control={<Radio color="primary" />}
                    label="Bu"
                  />
                  <FormControlLabel
                    value="cu-user"
                    control={<Radio color="primary" />}
                    label="Cu"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="flex" style={{ marginTop: "20px" }}>
              <TextField
                id="outlined-multiline-static"
                label="OMSCHRIJVING"
                multiline
                value={this.state.description}
                onChange={(event) => this.handleForm(event, "description")}
                rows={4}
                variant="outlined"
              />
            </div>
            {/* {this.state.regions?.length > 0 && this.state.selectedRegions ? (
              <MultipleSelect
                handleRegion={this.handleRegion}
                regions={this.state.regions}
                selectedRegion={this.state.selectedRegions}
                label="Select Region"
              />
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  OneUsergroup: state.UsergroupReducer.OneUsergroup,
  regions: state.UsergroupReducer.regions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetUsergroupById: (id) => dispatch(actionCreators.GetUsergroupById(id)),
    UpdateUsergroup: (id, data) => dispatch(actionCreators.UpdateUsergroup(id, data)),
    AddUsergroup: (data) => dispatch(actionCreators.AddUsergroup(data)),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUserGroup)
);
