import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'ANOTHER REGION',
  'CADZAND',
  'CADZAND WEEKLY MARKET',
  'THE HAGUE',
  'THE HAGUE BABYLON',
  'VOORSCHOTEN WEEKLY MARKET',
  ' VOORSCHOTEN BEETHOVENLAAN',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(props.selectedRegion);

  const handleChange = (event) => {
    setPersonName(event.target.value);
    var ids = []
    event.target.value.forEach(element => {
      ids.push(element)
    });
    props.handleRegion(ids);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };
  const getLabelCategory = (value) => {
    const index = props.regions?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return props.regions[index].name;
    }
    return value;
  };
  return (
    <div>
      <FormControl className={classes.formControl}>
  <InputLabel id="demo-mutiple-chip-label">{props.label}</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
         
          id="demo-mutiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected?.map((value) => (
                <Chip key={value} label={getLabelCategory(value)} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          
          {props.regions?.map((name) => (
            <MenuItem  className="multi-chips"
             key={name._id} 
             value={name._id} 
            style={getStyles(name,personName, theme)} >
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
