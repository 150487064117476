import React from 'react'
import { Input, InputLabel, Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

export default class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            punten: 0,
            basicInfo: true,
        }
    }
    componentWillMount() {
        this.setState({ name: this.props.match?.params.id })
    }
    handleForm = (event, val) => {
        if (val == "name") {
            this.setState({ name: event.target.value })
        }
        else if (val == "punten") {
            this.setState({ punten: event.target.value })
        }

    }
    render() {
        return (
            <div>
                <div className="flex">
                    <InputLabel>Test Name </InputLabel>
                    <Input type="text"
                        value={this.props.Name}
                        onChange={(event) => this.props.handleForm(event, "Name")} />
                </div>
                <div className="flex">

                    <FormControl className="formControl">
                        <InputLabel htmlFor="age-native-simple">ONDERWERP  </InputLabel>
                        <Select
                            native
                            value={this.props.topic}
                            onChange={(event)=>this.props.handleForm(event,"topic")}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {this.props.categories?.map(x=>
                             <option value={x._id}>{x.name}</option>
                                )}
                           
                        </Select>
                    </FormControl>
                </div>
                <div className="flex">

                    <FormControl className="formControl">
                        <InputLabel htmlFor="age-native-simple">SUBONDERWERP  </InputLabel>
                        <Select
                            native
                            value={this.props.sub_topic}
                            onChange={(event)=>this.props.handleForm(event,"sub_topic")}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {this.props.subcategories?.map(x=>
                             <option value={x._id}>{x.name}</option>
                                )}
                        </Select>
                    </FormControl>
                </div>
              
                <div className="flex">
                    {[...Array(this.props.titleCounter)].map((x, i) =>
                        <div className="flex more-flex">
                            <InputLabel>Title </InputLabel>
                            <Input type="text"
                                
                                onChange={(event) => this.props.handleTitle(event, i)} />
                            <IconButton onClick={() => this.props.handleDelete("title")} disabled={i < 1 ? "disabled " : ""} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )} <Button className="more-btn" onClick={() => this.props.handleMore("title")}>More <ArrowDropDownIcon /></Button>
                </div>

                <div className="flex">
                    {[...Array(this.props.chapterCounter)].map((x, i) =>
                        <div className="flex more-flex">
                            <InputLabel>Chapter </InputLabel>
                            <Input type="text"
                                onChange={(event) => this.props.handleChapter(event, i)} />
                            <IconButton onClick={() => this.props.handleDelete("chapter")} disabled={i < 1 ? "disabled " : ""} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )} <Button className="more-btn" onClick={() => this.props.handleMore("chapter")}>More <ArrowDropDownIcon /></Button>
                </div>
                <div className="flex">
                    {[...Array(this.props.paragraphCounter)].map((x, i) =>
                        <div className="flex more-flex">
                            <InputLabel>Paragraph </InputLabel>
                            <Input type="text"
                                onChange={(event) => this.props.handleParagraph(event, i)} />
                            <IconButton onClick={() => this.props.handleDelete("paragraph")} disabled={i < 1 ? "disabled " : ""} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )} <Button className="more-btn" onClick={() => this.props.handleMore("paragraph")}>More <ArrowDropDownIcon /></Button>
                </div>
                <div className="flex">
                    <InputLabel>TIJDSDUUR (MINUTEN)</InputLabel>
                    <Input type="number"
                        value={this.props.min}
                        onChange={(event) => this.props.handleForm(event, "min")} />
                </div>
                <div className="flex">
                    <InputLabel>PUNTEN     </InputLabel>
                    <Input type="number"
                        value={this.props.points}
                        onChange={(event) => this.props.handleForm(event, "points")} />
                </div>

            </div>
        )
    }
}