import React from "react";
import SideNavPage from "../../Sidebar/SideNavPage";
import PageMenu from "../../page-menu/PageMenu";
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Table from "../../Tables/UserGroupTable";

function createData(
  a,
  p,
  REGIO,
  BIJNAAM,
  GEBRUIKERSGROEPEN,
  BEGINPOSTCODE,
  EINDIGENDEPOSTCODE,
  PRODUCTENPERREGIO
) {
  return {
    a,
    p,
    REGIO,
    BIJNAAM,
    GEBRUIKERSGROEPEN,
    BEGINPOSTCODE,
    EINDIGENDEPOSTCODE,
    PRODUCTENPERREGIO,
  };
}

const rows = [
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
  createData(
    "Ja",
    "Winkelier",
    "Voor winkeliers: Functionality of zakelijk.couponcard less: Instellingen kassa bonnen en Producten multi-producten",
    "ja",
    "bu"
  ),
];

const headCells = [
  { id: "A", numeric: false, disablePadding: true, label: "Active" },
  { id: "Naam", numeric: true, disablePadding: false, label: "Naam" },
  {
    id: "OMSCHRIJVING",
    numeric: true,
    disablePadding: false,
    label: "OMSCHRIJVING",
  },
  { id: "STD", numeric: true, disablePadding: false, label: "STD" },
  { id: "User", numeric: true, disablePadding: false, label: "User" },
];

class UserGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usergroups: [],
      DeleteUsergroup: [],
      Delstatus: 0,
    };
  }
  componentWillMount() {
    this.props.GetAllUserGroup();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
  }
  handleDelete = (selected) => {
    var data = this.state.usergroups;
    selected.forEach((element) => {
      this.props.DeleteUsergroupByID(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    });
    this.setState({ usergroups: data });
  };
  render() {
    return (
      <div>
        <PageMenu
          heading="User Group"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={true}
          btnhead={"Group"}
          AddNewLink={"/user-group/new"}
        />
        <div
          className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}
        >
          <Table
            handleDelete={this.handleDelete}
            rows={this.state.usergroups}
            headCells={headCells}
            editLink={"user-group"}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  usergroups: state.UsergroupReducer.usergroups,
  DeleteUsergroup: state.UsergroupReducer.DeleteUsergroup,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
    DeleteUsergroupByID: (id) =>
      dispatch(actionCreators.DeleteUsergroupByID(id)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserGroup)
);
