import React from 'react';

import PageMenu from '../page-menu/PageMenu'
 
import { MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ProfileMenu from './ProfileMenu';
import ChangePasswordForm from './ChangePasswordForm';
import './profile.scss'

class Profile extends React.Component{
    constructor(props){
        super(props);
        this.state={
            profile:true,
            chngPass:false
        }
    }

    handleListMenu = (val) =>{
       
        if(val == "profile"){
            this.setState({profile:true,chngPass:false});
        }
        else{
            this.setState({profile:false,chngPass:true});
        }

    }

    render(){
        return(
            <div>
                     <PageMenu heading="Maarten Soles"
                     open={this.props.open?"page-menu-open":null}/>


                     <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                     <div className="Muitabs">
                         <div className="Muitabs-inner">
                     <MenuItem onClick={() => this.handleListMenu("profile")}>
                     <Typography variant="h6" noWrap>
                          Profile
                     </Typography>
                     </MenuItem>
                     </div>
                     <div className="Muitabs-inner">
                     <MenuItem onClick={() => this.handleListMenu("password")}>
                     <Typography variant="h6" noWrap>
                          Change Password
                     </Typography>
                     </MenuItem>
                     </div>
                     </div>
                     <div className="profile-body">
                         {this.state.profile?
                         <div>
                             <ProfileMenu title="General Information"/>
                         </div>
                             :
                             <ChangePasswordForm title="Verander wachtwoord"/>}

                     </div>
                     </div>
            </div>
        )
    }
}
export default Profile;