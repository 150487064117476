import React from 'react';
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import BasicInfo from './BasicInfo';
import Advance from './Advance';
import Image from './Image';
import Make from './Make';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import axios, { post } from 'axios';

class AddTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topic: "",
            sub_topic: "",
            txtBook: [],
            min: 0,
            ch: [],
            sec: [],
            public: true,
            publicVal:"Ja",
            Name: "",
            usergroups: [],
            active: true,
            passing: 0,
            ask: false,
            askValue:"Nee",
            shuffle: false,
            ShuffleValue:"Nee",
            questions: [],
            basicInfo: true,
            advance: false,
            image: false,
            make: false,
            titleCounter: 1,
            chapterCounter: 1,
            paragraphCounter: 1,
            categories: [],
            subcategories: [],
            ACTIEF:"Ja",
            selectedUserGroup:[],
            UpdatedUserGroup:[],
            questionsByTopic:[]

        }
    }
    componentWillMount() {
        this.props.GetAllUserGroup();
        this.props.GetAllCategory();
        this.props.GetAllTestQuestions();

        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.props.GetTestById(this.props.match.params.id);
        }
        else {
            this.setState({
                OneTest: {
                    topic: "",
                    sub_topic: "",
                    txtBook: [],
                    min: 0,
                    ch: [],
                    sec: [],
                    price:0,
                    public: false,
                    Name: "",
                    usergroups: [],
                    active: false,
                    points:0,
                    passing: 0,
                    ask: false,
                    shuffle: false,
                    questions: [],
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.subcategories) {
            this.setState({ subcategories: nextProps.subcategories[0] });
        }
        if (nextProps.questionsByTopic) {
            this.setState({ questionsByTopic: nextProps.questionsByTopic[0] });
        }
        if (nextProps.categories) {
            this.setState({ categories: nextProps.categories[0] });
        }
        if (nextProps.usergroups) {
            this.setState({ usergroups: nextProps.usergroups[0] });
        }
        if (nextProps.testQuestions) {
            this.setState({ testQuestions: nextProps.testQuestions[0] });
        }
        if (nextProps.OneTest?.length > 0 & this.state.isNew) {
            // this.setState({ OneImage: nextProps.OneTest[0] });
            this.setState({
                topic: nextProps.OneTest[0]?.topic,
                sub_topic: nextProps.OneTest[0]?.sub_topic,
                txtBook: nextProps.OneTest[0]?.txtBook,
                min: nextProps.OneTest[0]?.min,
                ch: nextProps.OneTest[0]?.ch,
                sec: nextProps.OneTest[0]?.sec,
                public: nextProps.OneTest[0]?.public,
                Name: nextProps.OneTest[0]?.Name,
                selectedUserGroup: nextProps.OneTest[0]?.usergroups,
                UpdatedUserGroup: nextProps.OneTest[0]?.usergroups,
                active: nextProps.OneTest[0]?.active,
                passing: nextProps.OneTest[0]?.passing,
                ask: nextProps.OneTest[0]?.ask,
                shuffle: nextProps.OneTest[0]?.shuffle,
                questions: nextProps.OneTest[0]?.questions,
                price:nextProps.OneTest[0]?.price,
                points:nextProps.OneTest[0]?.points,
                imageFileName: nextProps.OneTest[0]?.img,
            })
        }
        if(nextProps.OneTest[0]?.active){
            this.setState({ACTIEF:"Jaa"})
        }
        else if(nextProps.OneTest[0]?.active){
            this.setState({ACTIEF:"Nee"})
        }
        if(nextProps.OneTest[0]?.ask){
            this.setState({askValue:"Jaa"})
        }
        else if(nextProps.OneTest[0]?.ask){
            this.setState({askValue:"Nee"})
        }
        if(nextProps.OneTest[0]?.shuffle){
            this.setState({ShuffleValue:"Jaa"})
        }
        else if(nextProps.OneTest[0]?.shuffle){
            this.setState({ShuffleValue:"Nee"})
        }
        if(nextProps.OneTest[0]?.public){
            this.setState({publicVal:"Jaa"})
        }
        else if(nextProps.OneTest[0]?.public){
            this.setState({publicVal:"Nee"})
        }
    }
    onFormSubmit = (id) => {
        // Stop form submit
        this.fileUpload(this.state.file,id).then((response) => {
            console.log(response.data);
        })
    }
    handleForm = (event, val) => {

        if (val == "Name") {
            this.setState({ Name: event.target.value })
        }
        else if (val == "topic") {
            this.setState({ topic: event.target.value })
            this.props.GetAllSubCategories({"Name":event.target.value});
            this.props.GetTestQuestionsByTopic({"topic":event.target.value});

        }
        else if (val == "sub_topic") {
            this.setState({ sub_topic: event.target.value })
        }
        else if (val == "min") {
            this.setState({ min: event.target.value })
        }
        else if (val == "price") {
            this.setState({ price: event.target.value })
        }
        else if (val == "points") {
            this.setState({ points: event.target.value })
        }
        else if (val == "passing") {
            this.setState({ passing: event.target.value })
        }
    }
    handleTitle = (event, current) => {
        var title = this.state.txtBook;
        if (title.length < current) {
            for (let index = title.length; index < current; index++) {
                title.push("");
            }
        }
        title[current] = event.target.value;
        this.setState({ txtBook: title })
    }
    handleChapter = (event, current) => {
        var ch = this.state.ch;
        if (ch.length < current) {
            for (let index = ch.length; index < current; index++) {
                ch.push("");
            }
        }
        ch[current] = event.target.value;
        this.setState({ ch: ch })
    }
    handleParagraph = (event, current) => {
        var sec = this.state.sec;
        if (sec.length < current) {
            for (let index = sec.length; index < current; index++) {
                sec.push("");
            }
        }
        sec[current] = event.target.value;
        this.setState({ sec: sec })
    }
    handleMore = (str) => {
        if (str === "title") {
            this.setState({ titleCounter: this.state.titleCounter + 1 })
        }
        else if (str === "chapter") {
            this.setState({ chapterCounter: this.state.chapterCounter + 1 })
        }
        else if (str === "paragraph") {
            this.setState({ paragraphCounter: this.state.paragraphCounter + 1 })
        }
    }
    handleDelete = (str) => {
        if (str === "title") {
            this.setState({ titleCounter: this.state.titleCounter - 1 })
            var title = this.state.txtBook;
            title.pop();
            this.setState({ txtBook: title })
        }
        else if (str === "chapter") {
            this.setState({ chapterCounter: this.state.chapterCounter - 1 })
            var ch = this.state.ch;
            ch.pop();
            this.setState({ ch: ch })
        }
        else if (str === "paragraph") {
            this.setState({ paragraphCounter: this.state.paragraphCounter - 1 })
            var sec = this.state.sec;
            sec.pop();
            this.setState({ sec: sec })
        }
    }
    handleRadio = (event) => {
        this.setState({ value: event.target.value });
        if (event.target.value === "Nee") {
            this.setState({ priceField: false });
            this.setState({ price: 0 });
            this.setState({ public: false })
        }
        else if (event.target.value === "Ja") {
            this.setState({ priceField: true });
            this.setState({ public: true })
        }
    }
    handleRadioACTIEF = (event) => {
        this.setState({ ACTIEF: event.target.value });
        if (event.target.value === "Nee") {
            this.setState({ active: false })
        }
        else if (event.target.value === "Ja") {
            this.setState({ active: true })
        }
    }
    handleRadioPublic = (event) => {
        this.setState({ publicVal: event.target.value });
        if (event.target.value === "Nee") {
            this.setState({ public: false })
        }
        else if (event.target.value === "Ja") {
            this.setState({ public: true })
        }
    }
    handleAskRadio = (event) => {
        this.setState({ askValue: event.target.value });
        if (event.target.value === "Nee") {
            this.setState({ ask: false })
        }
        else if (event.target.value === "Ja") {
            this.setState({ ask: true })
        }
    }
    handleRadioShuffle = (event) => {
        this.setState({ ShuffleValue: event.target.value });
        if (event.target.value === "Nee") {
            this.setState({ shuffle: false })
        }
        else if (event.target.value === "Ja") {
            this.setState({ shuffle: true })
        }
    }
    fileUpload = (file,id) => {
        const url = `http://api.beelicensed.com/UploadTest/${id}`;
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(url, formData, config)
    }

    handleFileAdded = (fil) => {
        this.setState({ file: fil })
    }


    addNewData = () => {
        var data = {
            topic: this.state.topic,
            sub_topic: this.state.sub_topic,
            txtBook: this.state.txtBook,
            min: this.state.min,
            ch: this.state.ch,
            sec: this.state.sec,
            public: this.state.public,
            Name: this.state.Name,
            usergroups: this.state.usergroups,
            active: this.state.active,
            passing: this.state.passing,
            ask: this.state.ask,
            shuffle: this.state.shuffle,
            price:this.state.price,
            questions: this.state.questions,
            points:this.state.points
        }
        axios.post(`http://api.beelicensed.com/AddTest`, data, {  
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            console.log(response.data);
            this.onFormSubmit(response.data?.data._id)
        }).catch(e => {

        })
    }
    editFile = () =>{
        var data = {
            Id: this.props.match.params.id,
            topic: this.state.topic,
            sub_topic: this.state.sub_topic,
            txtBook: this.state.txtBook,
            min: this.state.min,
            ch: this.state.ch,
            sec: this.state.sec,
            public: this.state.public,
            Name: this.state.Name,
            usergroups: this.state.usergroups,
            active: this.state.active,
            passing: this.state.passing,
            ask: this.state.ask,
            shuffle: this.state.shuffle,
            price:this.state.price,
            questions: this.state.questions,
            points:this.state.points
        }
        axios.post(`http://api.beelicensed.com/EditTest`, data, {  
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'accept': 'application/json'
            }
        }).then((response) => {
            if(this.state.file !== undefined){
            this.onFormSubmit(response.data?.data._id)
            }
        }).catch(e => {

        })
    }

    saveHandler = () => {
        if (this.state.isNew) {
              this.editFile();
        }
        else {
            if(this.state.file !== undefined){
            this.addNewData();
            }
            else{
                alert("Please upload file and then try again!")
            }
            //this.onFormEditSubmit();
        }
    }
    handleTabs = (str) => {
        if (str === "basic") {
            this.setState({ basicInfo: true });
            this.setState({ advance: false });
            this.setState({ image: false });
            this.setState({ make: false });
        }
        else if (str === "adv") {
            this.setState({ basicInfo: false });
            this.setState({ advance: true });
            this.setState({ image: false });
            this.setState({ make: false });
        }
        else if (str === "img") {
            this.setState({ basicInfo: false });
            this.setState({ advance: false });
            this.setState({ image: true });
            this.setState({ make: false });
        }
        else if (str === "make") {
            this.setState({ basicInfo: false });
            this.setState({ advance: false });
            this.setState({ image: false });
            this.setState({ make: true });
        }
    }
    handleUsergroup = (ids) => {
        this.setState({ UpdatedUserGroup: ids });
    }
    setQuestion=(q)=>{
        this.setState({questions:q})
    }
    render() {
        return (<div>
            



            <PageMenu heading="Add Test"
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                backArrow={true}
                backTitle={'Test'}
                backUrl={'/test'}
                SaveBtn={true}
                saveHandler={this.saveHandler} />
            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span onClick={() => this.handleTabs("basic")} className={`${this.state.basicInfo ? "form-head-menu-selected" : ""} form-head-menu`}>Basic Info</span>
                    <span onClick={() => this.handleTabs("adv")} className={`${this.state.advance ? "form-head-menu-selected" : ""} form-head-menu`}>Advance</span>
                    <span onClick={() => this.handleTabs("make")} className={`${this.state.make ? "form-head-menu-selected" : ""} form-head-menu`}>Maak Test</span>
                    <span onClick={() => this.handleTabs("img")} className={`${this.state.image ? "form-head-menu-selected" : ""} form-head-menu`}>Photos</span>
                </div>

                <div className="admin-form">
                    {this.state.basicInfo ?
                        <BasicInfo
                            Name={this.state.Name}
                            topic={this.state.topic}
                            sub_topic={this.state.sub_topic}
                            txtBook={this.state.txtBook}
                            ch={this.state.ch}
                            sec={this.state.sec}
                            min={this.state.min}
                            categories={this.state.categories}
                            subcategories={this.state.subcategories}
                            handleForm={this.handleForm}
                            handleTitle={this.handleTitle}
                            handleChapter={this.handleChapter}
                            handleParagraph={this.handleParagraph}
                            handleMore={this.handleMore}
                            handleDelete={this.handleDelete}
                            titleCounter={this.state.titleCounter}
                            chapterCounter={this.state.chapterCounter}
                            paragraphCounter={this.state.paragraphCounter}
                            points={this.state.points}



                        />
                        : null}
                    {this.state.advance ?
                        <Advance
                            public={this.state.public}
                            UpdatedUserGroup={this.state.UpdatedUserGroup}
                            selectedUserGroup={this.state.selectedUserGroup}
                            handleUsergroup={this.handleUsergroup}
                            usergroups={this.state.usergroups}
                            handleForm={this.handleForm}
                            ACTIEF={this.state.ACTIEF}
                            handleRadioACTIEF={this.handleRadioACTIEF}
                            handleRadioPublic={this.handleRadioPublic}
                            active={this.state.active}
                            price={this.state.price}
                            publicVal={this.state.publicVal}
                        />
                        : null}
                    {this.state.image ?
                        <Image isNew={this.state.isNew}
                            onFormSubmit={this.onFormSubmit}
                            onChange={this.onChange}
                            handleFileAdded={this.handleFileAdded}
                            isNew={this.state.isNew}
                            file={this.state.file}
                            imageFileName={this.state.imageFileName} />
                        : null}
                    {this.state.make ?
                        <Make
                            passing={this.state.passing}
                            handleForm={this.handleForm}
                            ask={this.state.ask}
                            shuffle={this.state.shuffle}
                            questions={this.state.questions}
                            testQuestions={this.state.testQuestions}
                            questionsByTopic={this.state.questionsByTopic}
                            setQuestion={this.setQuestion}
                            askValue={this.state.askValue}
                            handleAskRadio={this.handleAskRadio}
                            ShuffleValue={this.state.ShuffleValue}
                            handleRadioShuffle={this.handleRadioShuffle}
                        />
                        : null}

                </div>
            </div>

        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneTest: state.TestReducer.OneTest,
        usergroups: state.TestReducer.usergroups,
        subcategories: state.TestReducer.subcategories,
        categories: state.TestReducer.categories,
        testQuestions:state.TestReducer.testQuestions,
        questionsByTopic:state.TestReducer.questionsByTopic
    });
const mapDispatchToProps = dispatch => {
    return {
        GetTestById: (id) => dispatch(actionCreators.GetTestById(id)),
        EditTest: (data) => dispatch(actionCreators.EditTest(data)),
        GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
        GetAllSubCategories: (data) => dispatch(actionCreators.GetAllSubCategories(data)),
        GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
        GetAllTestQuestions: () => dispatch(actionCreators.GetAllTestQuestions()),
        GetTestQuestionsByTopic: (data) => dispatch(actionCreators.GetTestQuestionsByTopic(data)),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTest))