let defaultState = {
    usergroups: [],
    DeleteOffer: [],
    OneOffer: [],
    offers: [],
    regions: [],
    categories:[]
}

const OfferReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_OFFER":
            return {
                ...state,
                DeleteOffer: [action.DeleteOffer]
            }
        case "GET_OFFER":
            return {
                ...state,
                OneOffer: [action.OneOffer]
            }
        case "GET_ALL_OFFERS":
            return {
                ...state,
                offers: [action.offers]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
        case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }
            case "GET_CATEGORY":
                return {
                    ...state,
                    categories: [action.categories]
                }
        default:
            return {
                ...state
            }
    }
}
export default OfferReducer;