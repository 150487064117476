import { axiosClient } from '../../../../../helper';
import { appStatusAction } from '../../../../../reducer';


// FAQ Actions
export function DeleteFAQ(id){
    return (dispatch) => {

        return axiosClient().delete(`/DeleteFAQ/${id}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteFAQSuccess(res.data));
            dispatch(appStatusAction("FAQ Successfully Deleted"));
        })
    }
}

export function DeleteFAQSuccess(data) {
    return {
        type: "DELETE_FAQ",
        DeleteFAQ:data
    }
}

export function GetFAQById(id){
    return (dispatch) => {

        return axiosClient().get(`/getFaq?id=${id}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            console.log("res.data.data",res.data.result)
            dispatch(GetFAQByIdSuccess(...res.data.result));
        })
    }
}

export function GetFAQByIdSuccess(data) {
    return {
        type: "GET_ONE_FAQ",
        OneFAQ:data
    }
}
export function EditFAQ(data, id){
    return (dispatch) => {

        return axiosClient().post(`/editFaq/${id}`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditFAQSuccess(res.data.data));
            dispatch(appStatusAction("FAQ Successfully Edited"));
        })
    }
}

export function EditFAQSuccess(data) {
    return {
        type: "EDIT_FAQ",
        OneFAQ:data
    }
}

export function AddFAQ(data){
    return (dispatch) => {

        return axiosClient().post(`/addFaq`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddFAQSuccess(res.data.data));
            dispatch(appStatusAction("FAQ Successfully Added"));
        })
    }
}

export function AddFAQSuccess(data) {
    return {
        type: "ADD_FAQ",
        OneFAQ:data
    }
}
export function GetAllFAQS() {
    return (dispatch) => {
        return axiosClient().get(`/getFaq`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllFAQSSuccess(res.data.result));
        })
    }
}

export function GetAllFAQSSuccess(data) {
    return {
        type: "GET_FAQ",
        FAQS:data
    }
}



// Working Actions
export function DeleteWorking(id){
    return (dispatch) => {

        return axiosClient().post(`/DeleteWorking`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteWorkingSuccess(res.data));
            dispatch(appStatusAction("Working Successfully Deleted"));
        })
    }
}

export function DeleteWorkingSuccess(data) {
    return {
        type: "DELETE_Working",
        DeleteWorking:data
    }
}

export function GetWorkingById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetWorkingById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            console.log("res.data.data",res.data.data)
            dispatch(GetWorkingByIdSuccess(res.data.data));
        })
    }
}

export function GetWorkingByIdSuccess(data) {
    return {
        type: "GET_ONE_Working",
        OneWorking:data
    }
}
export function EditWorking(data){
    return (dispatch) => {

        return axiosClient().post(`/EditWorking`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            console.log(res.data.status,"res.data.status")
            if(res.data.status == 200){
            dispatch(EditWorkingSuccess(res.data.data));
            dispatch(appStatusAction("Working Successfully Edited"));
        }
        else{
            dispatch(appStatusAction(res.data.msg));
        }
        })
    }
}

export function EditWorkingSuccess(data) {
    return {
        type: "EDIT_Working",
        OneWorking:data
    }
}

export function AddWorking(data){
    return (dispatch) => {

        return axiosClient().post(`/AddWorking`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddWorkingSuccess(res.data.data));
            dispatch(appStatusAction("Working Successfully Added"));
        })
    }
}

export function AddWorkingSuccess(data) {
    return {
        type: "ADD_Working",
        OneWorking:data
    }
}
export function GetAllWorking() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllWorking`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllWorkingSuccess(res.data.data));
        })
    }
}

export function GetAllWorkingSuccess(data) {
    return {
        type: "GET_Working",
        Working:data
    }
}

export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}





