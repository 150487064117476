import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
 
import { Input,InputLabel } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MultipleSelect from '../../Pictures/RegionSelect'

export default class AddInterst extends React.Component{
    constructor(props){
        super(props);
        this.state={
            name:"",
            phoneNumber:"",
            email: "",
            password: "",
        }
    }
    componentWillMount(){
        this.setState({name:this.props.match.params.id})
    }
    handleForm = (event,val)=>{
        if(val  === "name"){
            this.setState({name:event.target.value})
        }
        else if(val  === "phone"){
            this.setState({phoneNumber:event.target.value})
        }
        else if(val  === "password"){
            this.setState({password:event.target.value})
        }
        else if(val  === "email"){
            this.setState({email:event.target.value})
        }

    }

    render(){
        return(<div>
              

            

                 <PageMenu heading="Add Interst" 
                   open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 backArrow={true}
                 backTitle={'Consumer Interst'}
                 backUrl={'/consumer-interst'}
                 SaveBtn={true}/>
                  <div className={`upper-form ${this.props.open?"upper-form-open":""}`}>
                      <div className="form-head">
                        <span>Basic Info</span>
                      </div>
                      <div className="admin-form">
                          <div className="flex">
                          <InputLabel>Beschrijving</InputLabel>
                          <Input type="text"
                           value={this.state.name}
                           onChange={(event)=>this.handleForm(event,"name")} />
                          </div>

                          <div>
                            <InputLabel>Status</InputLabel>
                            <div>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch checked={this.checkedA} onChange={this.handleChange} color="primary" name="checkedA" />}
                                        label="Active"
                                    />
                                </FormGroup>

                            </div>
                        </div>
                                <MultipleSelect label="Select Region"/>
                      </div>
                  </div>
        </div>)
    }
}