let defaultState = {
    usergroups: [],
    DeleteTest: [],
    OneTest: [],
    tests: [],
    categories: [],
    subcategories: [],
    testQuestions:[],
    questionsByTopic:[]
}

const TestReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_TEST":
            return {
                ...state,
                DeleteTest: [action.DeleteTest]
            }
        case "GET_ONE_TEST":
            return {
                ...state,
                OneTest: [action.OneTest]
            }
        case "EDIT_TEST":
            return {
                ...state,
                OneTest: [action.OneTest]
            }
        case "ADD_TEST":
            return {
                ...state,
                OneTest: [action.OneTest]
            }
        case "GET_TEST":
            return {
                ...state,
                tests: [action.tests]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
        case "GET_CATEGORY":
            return {
                ...state,
                categories: [action.categories]
            }
        case "GET_SUBCATEGORY":
            return {
                ...state,
                subcategories: [action.subcategories]
            }
            case "GET_TESTQUESTION":
                return {
                    ...state,
                    testQuestions: [action.testQuestions]
                }
                case "GET_TEST_QUESTION_BY_TOPIC":
                    return {
                        ...state,
                        questionsByTopic: [action.questionsByTopic]
                    }

        default:
            return {
                ...state
            }
    }
}
export default TestReducer;