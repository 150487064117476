import React from 'react';
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import { Container } from 'reactstrap'
import { Input, InputLabel } from '@material-ui/core';
import MultipleSelect from '../../../Pictures/RegionSelect'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class AddQuestionAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            punten: 0
        }
    }
    componentWillMount() {
        this.setState({ name: this.props.match.params.id })
    }
    handleForm = (event, val) => {
        if (val == "name") {
            this.setState({ name: event.target.value })
        }
        else if (val == "punten") {
            this.setState({ punten: event.target.value })
        }

    }

    render() {
        return (<div>
            

            

                <PageMenu heading="Add Extra Vragen"
                      open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                    backArrow={true}
                    backTitle={'Extra Vragen'}
                    backUrl={'/extra-vragen'}
                    SaveBtn={true} />
                <div className={`upper-form ${this.props.open?"upper-form-open":""}`}>
                    <div className="form-head">
                        <span>Basic Info</span>
                    </div>
                    <div className="admin-form">
                        <div className="flex">
                            <InputLabel>VRAAG </InputLabel>
                            <Input type="text"
                                value={this.state.name}
                                onChange={(event) => this.handleForm(event, "name")} />
                        </div>
                        <div className="flex">
                            <InputLabel>Order</InputLabel>
                            <Input type="number"
                                value={this.state.punten}
                                onChange={(event) => this.handleForm(event, "punten")} />
                        </div>
                        
                        <InputLabel>BESTEMMING </InputLabel>

                        <FormControl>
                            <RadioGroup>
                                <FormControlLabel value="Bu" control={<Radio color="primary" />} label="Bu" />
                                <FormControlLabel value="Cu" control={<Radio color="primary" />} label="Cu" />
                            </RadioGroup>
                        </FormControl>

                        <MultipleSelect label="Select Region" />
                        <MultipleSelect label="CATEGORIEËN " />
                    </div>
                </div>
            
        </div>)
    }
}