import React from 'react'
import * as actionCreators from "../store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PageMenu from '../../../page-menu/PageMenu'
import Table from '../../../Tables/ProviderReservationsTable'

function createData(name, calories, fat, carbs, protein,ad,status) {
    return { name, calories, fat, carbs, protein,ad,status };
  }

  const rows = [
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N'),
    createData('Sample', '12-1-2020', '12-02-2019', 'Test Regio', 'Testnew2','N','dsfdf'),
  ];

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'PRODUCTEN' },
    { id: 'calories', numeric: false, disablePadding: true, label: 'Bu Payment Status' },
    { id: 'fat', numeric: false, disablePadding: true, label: 'Cu Payment Status' },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Customer Id' },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Business User' },
    { id: 'protein', numeric: true, disablePadding: false, label: 'Total Payment' },
    { id: 'ad', numeric: true, disablePadding: false, label: 'Craeted At' },
  ];

 

class Consumer extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        reservations:[],
    };
  }
  componentWillMount() {
    this.props.GetAllReservationsForAdmin();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.reservations) {
      this.setState({ reservations: nextProps.reservations[0] });
    }
  }
//   handleStatusChanges=(data)=>{
//     this.props.AuthorizeProduct(data);

//   }
    render(){
        return(
            <div>
                 <PageMenu heading="Provider Reservations" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 btnhead={'Products'}
                 AddNewLink={'/provider-products/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Table headCells={headCells}
                     rows={this.state.reservations} 
                     editLink={'provider-product-detail'}
                     />
                 </div>
                 </div>
        )
    }
}
const mapStateToProps = (state) => ({
    reservations: state.ProviderProductReducer.reservations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllReservationsForAdmin: () => dispatch(actionCreators.GetAllReservationsForAdmin()),
  
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Consumer)
);