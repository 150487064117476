let defaultState = {
    DeleteSubCategory: [],
    subcategories: [],
    OneSubCategory: []
}

const SubCategoryReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_SUBCATEGORY":
            return {
                ...state,
                DeleteSubCategory: [action.DeleteSubCategory]
            }
        case "GET_ONE_SUBCATEGORY":
            return {
                ...state,
                OneSubCategory: [action.OneSubCategory]
            }
        case "EDIT_SUBCATEGORY":
            return {
                ...state,
                OneSubCategory: [action.OneSubCategory]
            }
        case "ADD_SUBCATEGORY":
            return {
                ...state,
                OneSubCategory: [action.OneSubCategory]
            }
        case "GET_SUBCATEGORY":
            return {
                ...state,
                subcategories: [action.subcategories]
            }
        default:
            return {
                ...state
            }
    }
}
export default SubCategoryReducer;