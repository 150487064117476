let defaultState = {
    DeleteUsergroup: [],
    usergroups: [],
    OneUsergroup: [],
    regions:[],
    sending:false,
    sendingMessage:"",
}

const UsergroupReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "GET_ONE_USERGROUP":
            return {
                ...state,
                OneUsergroup: [action.OneUsergroup]
            }
            case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }
        case "EDIT_USERGROUP":
            return {
                ...state,
                OneUsergroup: [action.OneUsergroup],
                sending:true,
                sendingMessage:[action.sendingMessage]
            }
        case "DELETE_USERGROUP":
            return {
                ...state,
                DeleteUsergroup: [action.DeleteUsergroup],
                sending:true,
                sendingMessage:[action.sendingMessage]
            }
        case "ADD_USERGROUP":
            return {
                ...state,
                OneUsergroup: [action.OneUsergroup],
                sending:true,
                sendingMessage:[action.sendingMessage]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
            case "FULFILLED":
            return {
                ...state,
                sending:false
            }
        default:
            return {
                ...state
            }
    }
}
export default UsergroupReducer;