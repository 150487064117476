import { axiosClient } from '../../../../../helper';
import { appStatusAction } from '../../../../../reducer';


// Loyality Actions
export function DeleteTest(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletTest`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletTestSuccess(res.data));
            dispatch(appStatusAction("Test Successfully Deleted"));

        })
    }
}

export function DeletTestSuccess(data) {
    return {
        type: "DELETE_TEST",
        DeleteTest:data
    }
}

export function GetTestById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetTestById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetTestByIdSuccess(res.data.data));
        })
    }
}

export function GetTestByIdSuccess(data) {
    return {
        type: "GET_ONE_TEST",
        OneTest:data
    }
}
export function EditCSVFile(data){
    return (dispatch) => {

        return axiosClient().post(`/EditCSVFile`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditCSVFileSuccess(res.data.data));
            dispatch(appStatusAction("CSV File Successfully Edited"));

        })
    }
}

export function EditCSVFileSuccess(data) {
    return {
        type: "EDIT_CSV",
        OneTest:data
    }
}

export function AddTest(data){
    return (dispatch) => {

        return axiosClient().post(`/AddTest`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddTestSuccess(res.data.data));
            dispatch(appStatusAction("Add Test Successfully Added"));
        })
    }
}

export function AddTestSuccess(data) {
    return {
        type: "ADD_TEST",
        OneTest:data
    }
}
export function GetAllCSV() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllCSV`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCSVSuccess(res.data.data));
        })
    }
}

export function GetAllCSVSuccess(data) {
    return {
        type: "GET_CSV",
        csvs:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllCategories`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.data));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}
export function GetAllSubCategories() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllSubCategories`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllSubCategoriesSuccess(res.data.data));
        })
    }
}

export function GetAllSubCategoriesSuccess(data) {
    return {
        type: "GET_SUBCATEGORY",
        subcategories:data
    }
}
export function GetAllTestQuestions() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllTestQuestions`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllTestQuestionsSuccess(res.data.data));
        })
    }
}

export function GetAllTestQuestionsSuccess(data) {
    return {
        type: "GET_TESTQUESTION",
        testQuestions:data
    }
}
