import React from 'react'
import DropFile from '../../../Pictures/DropFile';
import ImageCard from '../../../Pictures/ImageCard';

export default function BasicInfo(props){
    return(
        <div>
        {props.isNew?
        <div className="image-card">
            <ImageCard img={`http://api.beelicensed.com/uploads/${props.imageFileName}`}/>
        </div>
        :null}
    <div className="drop-file">
    <DropFile
    onFormSubmit={props.onFormSubmit} 
    onChange={props.onChange}
    handleFileAdded={props.handleFileAdded}
    file={props.file} />
    </div>
    
    </div>
    )
}