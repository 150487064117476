import React from 'react'
import PageMenu from '../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../Tables/PictureTable'
import './Picture.scss'


const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'Naam', numeric: false, disablePadding: true, label: 'Naam' },
  { id: 'image', numeric: false, disablePadding: true, label: 'Image' },
  { id: 'region', numeric: false, disablePadding: false, label: 'Region' },
  { id: 'std', numeric: false, disablePadding: false, label: 'STD' },
];

class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      DeleteCategory: [],
      Delstatus: 0,
      usergroups: []
    }
  }
  componentWillMount() {
    this.props.GetAllUserGroup();
    this.props.GetAllBUImg();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.images) {
      this.setState({ images: nextProps.images[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.images;
    selected.forEach(element => {
      this.props.DeleteImg(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ images: data });
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].Name;
    }
    return value;
  };
  render() {
    return (
      <div>   
        <PageMenu heading="Afbeeldingen"
        open={this.props.open ? "page-menu-open" : null}
        AddNew={true}
        btnhead={'Picture'}
        AddNewLink={'/picture/new'} />
        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          {this.state.images && this.state.usergroups?.length > 0 ?
            <Table headCells={headCells}
              handleDelete={this.handleDelete}
              rows={this.state.images}
              editLink={'picture'}
              getLabelUserGroups={this.getLabelUserGroups}
            />
            : null}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    images: state.BUImageReducer.images,
    DeleteCategory: state.BUImageReducer.DeleteCategory,
    usergroups: state.BUImageReducer.usergroups,
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllBUImg: () => dispatch(actionCreators.GetAllBUImg()),
    DeleteImg: (id) => dispatch(actionCreators.DeleteImg(id)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Picture))