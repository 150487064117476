import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../../Tables/OffersTable'


const headCells = [
    { id: 'PRODUCT', numeric: false, disablePadding: true, label: 'PRODUCT' },
    { id: 'PRIJS', numeric: false, disablePadding: false, label: 'PRIJS' },
    { id: 'REGIO', numeric: false, disablePadding: true, label: 'REGIO' },
    { id: 'CATEGORIE', numeric: false, disablePadding: true, label: 'CATEGORIE'},
    { id: 'GEBRUIKERSGROEP', numeric: false, disablePadding: true, label: 'GEBRUIKERS GROEP' },
  ];

 
class Offers extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      DeleteOffer: [],
      Delstatus: 0,
      usergroups:[],
      regions:[],
      categories:[]
    }
  }
  componentWillMount() {
    this.props.GetAllRegion();
    this.props.GetAllCategory();
    this.props.GetAllUserGroup();
    this.props.GetAllOffers();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      console.log("nextProps.usergroups",nextProps.usergroups)
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.regions) {
      console.log("nextProps.regions",nextProps.regions)
      this.setState({ regions: nextProps.regions[0] });
    }
    if (nextProps.categories) {
      console.log("nextProps.categories",nextProps.categories)
      this.setState({ categories: nextProps.categories[0] });
    }
    if (nextProps.offers) {
      this.setState({ offers: nextProps.offers[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.offers;
    selected.forEach(element => {
      this.props.DeleteOffers(element);
      for(var i = 0; i < data.length; i++) {
        if(data[i]._id == element) {
            data.splice(i, 1);
            break;
        }
      }
    }
    );
    this.setState({offers:data});
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].title;
    }
    return value;
  };
  getLabelRegions = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.regions[index].Name;
    }
    return value;
  };
  getLabelCategory = (value) => {
    const index = this.state.categories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.categories[index].name;
    }
    return value;
  };
    render(){
        return(
            <div>
                 <PageMenu heading="Offers" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'Offer'}
                 AddNewLink={'/offer/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                 {this.state.offers && this.state.usergroups && this.state.regions && this.state.categories?
                    <Table headCells={headCells}
                     handleDelete={this.handleDelete}
                     rows={this.state.offers} 
                     getLabelCategory={this.getLabelCategory}
                     getLabelRegions={this.getLabelRegions}
                     getLabelUserGroups={this.getLabelUserGroups}
                     editLink={'offer'}/>
                     :null}
                 </div>
                  
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    offers: state.OfferReducer.offers,
    DeleteOffer: state.OfferReducer.DeleteOffer,
    regions:state.OfferReducer.regions,
    categories:state.OfferReducer.categories,
    usergroups:state.OfferReducer.usergroups
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllOffers: () => dispatch(actionCreators.GetAllOffers()),
    DeleteOffers: (id) => dispatch(actionCreators.DeleteOffers(id)),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Offers))