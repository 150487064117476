let defaultState = {
    csvs:[],
}

const CSVReducer = (state = defaultState, action) => {
    switch (action.type) {
       
      
        case "GET_CSV":
            return {
                ...state,
                csvs: [action.csvs]
            }

        default:
            return {
                ...state
            }
    }
}
export default CSVReducer;