let defaultState = {
    DeleteQuestion: [],
    questions: [],
    OneQuestion: [],
    regions: [],
    categories: [],
    DeleteAnswer: [],
    OneAnswer: []
}

const ExtraVragenReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_QUESTION":
            return {
                ...state,
                DeleteQuestion: [action.DeleteQuestion]
            }
        case "DELETE_ANSWER":
            return {
                ...state,
                DeleteAnswer: [action.DeleteAnswer]
            }
        case "GET_ONE_QUESTION":
            return {
                ...state,
                OneQuestion: [action.OneQuestion]
            }
        case "GET_ONE_ANSWER":
            return {
                ...state,
                OneAnswer: [action.OneAnswer]
            }
        case "EDIT_QUESTION":
            return {
                ...state,
                OneQuestion: [action.OneQuestion]
            }
            case "EDIT_ANSWER":
                return {
                    ...state,
                    OneAnswer: [action.OneAnswer]
                }
        case "ADD_QUESTION":
            return {
                ...state,
                OneQuestion: [action.OneQuestion]
            }
        case "ADD_ANSWER":
            return {
                ...state,
                OneAnswer: [action.OneAnswer]
            }
        case "GET_QUESTIONS":
            return {
                ...state,
                questions: [action.questions]
            }
        case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }
        case "GET_CATEGORY":
            return {
                ...state,
                categories: [action.categories]
            }
        default:
            return {
                ...state
            }
    }
}
export default ExtraVragenReducer;