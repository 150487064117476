/**
 * Total Sales Widget
 */
import React from 'react';
import CountUp from 'react-countup';
import {  InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// chart
import TinyLineChart from './TinyLineChart';

// constants

// intl messages
// import IntlMessages from 'Util/IntlMessages';

// rct card box
import { RctCard, RctCardFooter, RctCardContent } from './RctCard';

const TotalSales = ({BU, usergroup,label,color, chartdata, labels }) => (
    <RctCard>
        <div className="rct-block-title d-flex justify-content-between">
            <div className="d-flex align-items-start">
            <h4>{label}</h4>
                {/* <IntlMessages id="widgets.totalSales" /> */}
            </div>
            <div className="align-items-end">
                <div className="flex">
                {usergroup?
                 <FormControl className="formControl">
                 <InputLabel htmlFor="age-native-simple">Usergroup  </InputLabel>
                 <Select
                     native
                    //  value={}
                    //  onChange={(event)=>this.props.handleForm(event,"topic")}
                     inputProps={{
                         name: 'age',
                         id: 'age-native-simple',
                     }}
                 >
                     <option aria-label="None" value="" />
                      <option value={1}>{"Usergroup 1"}</option>
                      <option value={2}>{"Usergroup 2"}</option>
                      <option value={3}>{"Usergroup 3"}</option>
                    
                 </Select>
             </FormControl>
             :null}
             {BU?
                <FormControl className="formControl">
                <InputLabel htmlFor="age-native-simple">BU  </InputLabel>
                <Select
                    native
                   //  value={}
                   //  onChange={(event)=>this.props.handleForm(event,"topic")}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple',
                    }}
                >
                    <option aria-label="None" value="" />
                     <option value={1}>{"BU 1"}</option>
                     <option value={2}>{"BU 2"}</option>
                     <option value={3}>{"BU 3"}</option>
                   
                </Select>
            </FormControl>:null}</div>
                {/* <span className="d-block text-muted counter-point"><CountUp start={0} end={1435} duration={3} useEasing={true} /></span> */}
            </div>
        </div>
        <RctCardContent noPadding>
            <TinyLineChart
                label={"h"}
                chartdata={chartdata}
                labels={labels}
                borderColor={color}
                pointBackgroundColor={color}
                height={100}
                pointBorderColor="#FFFFFF"
                borderWidth={4}
            />
        </RctCardContent>
        <RctCardFooter customClasses="d-flex justify-content-between">
            {labels && labels.map((label, key) => (
                <span className="fs-12 text-muted" key={key}>{label}</span>
            ))}
        </RctCardFooter>
    </RctCard>
);

export default TotalSales;
