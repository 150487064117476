import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../../Tables/TestTableTest'
import ReviewTable from '../../../Tables/ReviewTable'

function createData(NAAMVANTEST, ONDERWERP, SUBONDERWERP, AANTALVRAGEN, TOESTAND) {
  return { NAAMVANTEST, ONDERWERP, SUBONDERWERP, AANTALVRAGEN, TOESTAND };
}

function createData2(QUESTION, COMMENTAAR, ALME, NIVEAU, MOEILIJKHEIDSGRAAD, BEGRIP, NAAM) {
  return { QUESTION, COMMENTAAR, ALME, NIVEAU, MOEILIJKHEIDSGRAAD, BEGRIP, NAAM };
}

const rows = [
  createData('test', 'abcd', 'abcd', '2', 'openbar'),
  createData('test1', 'abcd', 'abcd', '2', 'openbar'),
  createData('tes2', 'abcd', 'abcd', '2', 'openbar'),
  createData('test3', 'abcd', 'abcd', '2', 'openbar'),
  createData('test4', 'abcd', 'abcd', '2', 'openbar'),
  createData('test5', 'abcd', 'abcd', '2', 'openbar'),
  createData('test6', 'abcd', 'abcd', '2', 'openbar'),
  createData('test8', 'abcd', 'abcd', '2', 'openbar'),
];

const headCells = [
  { id: 'NAAMVANTEST', numeric: false, disablePadding: true, label: 'NAAM VAN TEST' },
  { id: 'ONDERWERP', numeric: true, disablePadding: false, label: 'ONDERWERP' },
  { id: 'SUBONDERWERP', numeric: true, disablePadding: true, label: 'SUBONDERWERP' },
  { id: 'AANTALVRAGEN', numeric: true, disablePadding: true, label: 'AANTAL VRAGEN' },
  { id: 'TOESTAND', numeric: true, disablePadding: true, label: 'TOESTAND' },
];

const rows2 = [
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
  createData2('Adminopenbaar', 'goodie', '1', '1', '1', '1', 'Maarten'),
];

const headCells2 = [
  { id: 'QUESTION', numeric: false, disablePadding: true, label: 'QUESTION' },
  { id: 'COMMENTAAR', numeric: true, disablePadding: false, label: 'COMMENTAAR' },
  { id: 'ALME', numeric: true, disablePadding: true, label: 'AL MET AL UIT 10' },
  { id: 'NIVEAU', numeric: true, disablePadding: true, label: 'NIVEAU TARIEF VAN 5' },
  { id: 'MOEILIJKHEIDSGRAAD', numeric: true, disablePadding: true, label: 'MOEILIJKHEIDSGRAAD VAN 5' },
  { id: 'BEGRIP', numeric: true, disablePadding: true, label: 'BEGRIP VAN 5' },
  { id: 'NAAM', numeric: true, disablePadding: true, label: 'NAAM' },
];



class Tests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      table1: true,
      table2: false,
      tests: [],
      DeleteTest: [],
      Delstatus: 0,
      subcategories: [],
      categories: [],
    }
  }
  componentWillMount() {
    this.props.GetAllTest();
    this.props.GetAllCategory();
    this.props.GetAllSubCategorieswithoutFilter();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.subcategories) {
      this.setState({ subcategories: nextProps.subcategories[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
    }
    if (nextProps.tests) {
      this.setState({ tests: nextProps.tests[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.tests;
    selected.forEach(element => {
      this.props.DeleteTest(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ tests: data });
  }
  handleToggle = (str) => {
    if (str === "Test") {
      this.setState({ table1: true });
      this.setState({ table2: false });
    }
    else if (str === "Reviews") {
      this.setState({ table1: false });
      this.setState({ table2: true });
    }
  }
  getLabelCategory = (value) => {
    const index = this.state.categories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.categories[index].name;
    }
    return value;
  };
  getLabelSubCategory = (value) => {
    const index = this.state.subcategories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.subcategories[index].name;
    }
    return value;
  };
  render() {
    return (
      <div>
        <PageMenu heading={this.state.table1 ? "Test" : "Reviews"}
          open={this.props.open ? "page-menu-open" : null}
          AddNew={this.state.table1 ? true : false}
          filter={true}
          filterFrom={'NAAM VAN TEST'}
          handleToggle={this.handleToggle}
          toggle={true}
          //  toggleLeft={'Test'}
          toggleRight={this.state.table2 ? 'Test' : 'Reviews'}
          btnhead={this.state.table1 ? "Test" : ""}
          AddNewLink={this.state.table1 ? "/test/new" : ""} />


        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          {/* <Table headCells={headCells} rows={rows} editLink={'test'}/> */}

          {this.state.table1 && this.state.tests && this.state.categories?.length > 0 ?
            <Table headCells={headCells}
              handleDelete={this.handleDelete}
              rows={this.state.tests}
              getLabelCategory={this.getLabelCategory}
              getLabelSubCategory={this.getLabelSubCategory}
              editLink={'test'} />
            : null}
          {this.state.table2 ?
            <ReviewTable headCells={headCells2} rows={rows2} />
            : null}
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    tests: state.TestReducer.tests,
    DeleteTest: state.TestReducer.DeleteTest,
    subcategories: state.TestReducer.subcategories,
    categories: state.TestReducer.categories,
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllTest: () => dispatch(actionCreators.GetAllTest()),
    DeleteTest: (id) => dispatch(actionCreators.DeleteTest(id)),
    GetAllSubCategorieswithoutFilter: () => dispatch(actionCreators.GetAllSubCategorieswithoutFilter()),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tests))