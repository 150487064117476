let defaultState = {
    usergroups: [],
    DeleteTestQuestion: [],
    OneTestQuestion: [],
    testQuestions: [],
    categories: [],
    subcategories:[],
    testAnswers:[],
    OneTestAnswer:[],
    DeleteTestAnswer:[]
}

const TestQuestionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_TESTQUESTION":
            return {
                ...state,
                DeleteTestQuestion: [action.DeleteTestQuestion]
            }
        case "GET_ONE_TESTQUESTION":
            return {
                ...state,
                OneTestQuestion: [action.OneTestQuestion]
            }
        case "EDIT_TESTQUESTION":
            return {
                ...state,
                OneTestQuestion: [action.OneTestQuestion]
            }
        case "ADD_TESTQUESTION":
            return {
                ...state,
                OneTestQuestion: [action.OneTestQuestion]
            }
        case "GET_TESTQUESTION":
            return {
                ...state,
                testQuestions: [action.testQuestions]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
        case "GET_CATEGORY":
            return {
                ...state,
                categories: [action.categories]
            }
        case "GET_SUBCATEGORY":
            return {
                ...state,
                subcategories: [action.subcategories]
            }
            case "DELETE_TESTANSWER":
                return {
                    ...state,
                    DeleteTestAnswer: [action.DeleteTestAnswer]
                }
            case "ADD_TESTANSWER":
                return {
                    ...state,
                    OneTestAnswer: [action.OneTestAnswer]
                }
            case "GET_TESTANSWER":
                return {
                    ...state,
                    testAnswers: [action.testAnswers]
                }
        default:
            return {
                ...state
            }
    }
}
export default TestQuestionReducer;