import React from 'react';
import './profile.scss'


export default function ProfileMenu(props){
return(
    <div>
    <div className="title-bar">
        <h6>{props.title}</h6>
    </div>
    <div className="profile-Menu-body">
        <div>
        <label className="label">
            Email
        </label>
        <p>
            abc@xyz.com
        </p>
        </div>
        <div>
        <label className="label">
            Location
        </label>
        <p>
            Netherlands
        </p>
        </div>
    </div>
    </div>
)
}