import { axiosClient } from "../../../../../helper";
import { appStatusAction } from "../../../../../reducer";
// Loyality Actions


export function getBuLoyalty() {
  return (dispatch) => {
    return axiosClient()
      .get(`/GetAllBU`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        console.log("hola",res.data)
        dispatch(GetBULoyaltyByIdSuccess(res.data.data));
      });
  };
}
export function GetAllUserGroup() {
  return (dispatch) => {
      return axiosClient().get(`/getUserGroup`).then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          dispatch(GetAllUserGroupSuccess(res.data.result));
      })
  }
}

export function GetAllUserGroupSuccess(data) {
  return {
      type: "GET_USERGROUP",
      usergroups:data
  }
}

export function GetBULoyaltyByIdSuccess(data) {
  return {
    type: "GET_ALL_PROVIDER",
    Providers: data,
  };
}
export function ChangeBUStatusById(data){
  return (dispatch) => {

      return axiosClient().post(`/ChangeBUStatusById`,data).then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          dispatch(AuthorizeProductSuccess(res.data.data));
          dispatch(appStatusAction("Statuc Changes"));
      })
  }
}

export function AuthorizeProductSuccess(data) {
  return {
      type: "GET_UPDATED_DATA",
      StatusChanged:data
  }
}

