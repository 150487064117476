let defaultState = {
    DeleteImg: [],
    images: [],
    OneImage: [],
    usergroups: [],
    categories:[]
}

const BUImageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_IMG":
            return {
                ...state,
                DeleteImg: [action.DeleteImg]
            }
            case "GET_CATEGORY":
                return {
                    ...state,
                    categories: [action.categories],
                };
        case "GET_ONE_IMAGE":
            return {
                ...state,
                OneImage: [action.OneImage]
            }

        case "GET_BUIMG":
            return {
                ...state,
                images: [action.images]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }

        default:
            return {
                ...state
            }
    }
}
export default BUImageReducer;