import React, { useState, useEffect } from "react";
import Login from "./component/Login/Login";
import Dashboad from "./component/Dashbaord/Dashboard";
import Profile from "./component/user-profile/Profile";
import Consumer from "./component/Consumer/Consumer";
import PoppingtimeUsers from "./component/Consumer/popigumtime/users/Users";
import ProviderProduct from "./component/Products/ProviderProducts/ProviderProducts";
import AddConsumer from "./component/Consumer/AddConsumer";
import Business from "./component/Business/Business";
import AddBusiness from "./component/Business/AddBusiness";
import Picture from "./component/Pictures/Picture";
import AddPicture from "./component/Pictures/AddPicture";
import ConsumerInterst from "./component/Consumer/consumer-interst/ConsumerInterst";
import AddInterst from "./component/Consumer/consumer-interst/AddInterst";
import Loyalty from "./component/Consumer/loyalty/Loyalty";
import ProviderUser from './component/Business/Provider/Business'
import AddLoyalty from "./component/Consumer/loyalty/AddLoyalty";
import AddBuLoyalty from "./component/Business/loyalty/AddBuLoyalty";
import BuLoyalty from "./component/Business/loyalty/BuLoyalty";
import Region from "./component/general/region/Region";
import AddRegion from "./component/general/region/AddRegion";
import Categories from "./component/general/categories/Categories";
import AddCategories from "./component/general/categories/AddCategories";
import SubCategories from "./component/general/sub-categories/SubCategories";
import AddSubCategories from "./component/general/sub-categories/AddSubCategories";
import UserGroup from "./component/general/user-group/UserGroup";
import AddUserGroup from "./component/general/user-group/AddUserGroup";
import FAQ from "./component/general/faqs/FAQ";
import AddFAQ from "./component/general/faqs/AddFAQ";
import CSVData from "./component/general/upload-csv/CSVData";
import UploadCSV from "./component/general/upload-csv/UploadCSV";
import QuestionAnswer from "./component/general/tests/question-answer/QuestionAnswer";
import AddQuestionAnswer from "./component/general/tests/question-answer/AddQuestionAnswer";
import Test from "./component/general/tests/test/Test";
import AddTest from "./component/general/tests/test/AddTest";
import Result from "./component/general/tests/result/Result";
import AddResult from "./component/general/tests/result/AddResult";
import Token from "./component/general/pricing/token/Token";
import AddToken from "./component/general/pricing/token/AddToken";
import Subscription from "./component/general/pricing/subscription/Subscription";
import AddSubscription from "./component/general/pricing/subscription/AddSubscription";
import Offers from "./component/general/pricing/offers/Offers";
import AddOffers from "./component/general/pricing/offers/AddOffers";
import AddHowWorks from "./component/general/faqs/AddHowWorks";
import AddAnswer from "./component/general/extra-vragen/AddAnswer";
import ExtraAnswer from "./component/general/tests/question-answer/AddExtraAnswer";
import Chat from "./component/chat/Chat";
import Insight from "./component/Insights/Insight";
import OrderSubscription from "./component/Insights/order/BU/Subscription";
import OrderToken from "./component/Insights/order/BU/Token";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import ExtraVragen from "./component/general/extra-vragen/ExtraVragen";
import AddExtraVragen from "./component/general/extra-vragen/AddExtraVragen";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import SideNavPage from "./component/Sidebar/SideNavPage";
import Notification from "./component/snackbar/Notification";
import ProviderProductDetailPage from './component/Products/ProviderProducts/ProviderProductDetail';
import ProviderReservation from './component/Products/ProviderProducts/ProviderReservation/ProviderReservation'
import PoppingtimeInvoices from './component/Consumer/popigumtime/invoice/Invoice'
import { CheckAuthState } from "./reducer";

function App() {
  const store = useSelector((state) => state.appReducer, shallowEqual);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    open: true,
    consumentOpen: false,
    productOpen: false,
    zakelijkeOpen: false,
    loyalswanOpen: false,
    dashbaordOpen: false,
    pricingOpen: false,
    InstellingenOpen: false,
    testsOpen: false,
    orderOpen: false,
    buOpen: false,
    cuOpen: false,
    poppingtimeOpen: false,
    couponOpen: false,
    providerOpen: false,
    providerInvoicesOpen: false
  });

  useEffect(() => {
    dispatch(CheckAuthState());
  }, [dispatch]);

  const handleClick = (val) => {
    handleDrawerOpen();
    if (val === "Consument") {
      setState((prevState) => {
        return {
          ...prevState,
          consumentOpen: !state.consumentOpen,
          zakelijkeOpen: false,
          InstellingenOpen: false,
          dashbaordOpen: false,
          orderOpen: false,
        };
      });
    }
    else if (val === "products") {
      setState((prevState) => {
        return {
          ...prevState,
          productOpen: !state.productOpen,
          consumentOpen: false,
          zakelijkeOpen: false,
          InstellingenOpen: false,
          dashbaordOpen: false,
          orderOpen: false,
        };
      });
    }
    else if (val === "zakelijke") {
      setState((prevState) => {
        return {
          ...prevState,
          zakelijkeOpen: !state.zakelijkeOpen,
          consumentOpen: false,
          InstellingenOpen: false,
          dashbaordOpen: false,
          orderOpen: false,
        };
      });
    } else if (val === "dashbaord") {
      setState((prevState) => {
        return {
          ...prevState,
          dashbaordOpen: !state.dashbaordOpen,
          consumentOpen: false,
          InstellingenOpen: false,
          zakelijkeOpen: false,
          orderOpen: false,
        };
      });
    } else if (val === "pricing") {
      setState((prevState) => {
        return {
          ...prevState,
          pricingOpen: !state.pricingOpen,
        };
      });
    } else if (val === "cu") {
      setState((prevState) => {
        return {
          ...prevState,
          cuOpen: !state.cuOpen,
        };
      });
    } else if (val === "bu") {
      setState((prevState) => {
        return {
          ...prevState,
          buOpen: !state.buOpen,
        };
      });
    } else if (val === "Instellingen") {
      setState((prevState) => {
        return {
          ...prevState,
          InstellingenOpen: !state.InstellingenOpen,
          consumentOpen: false,
          zakelijkeOpen: false,
          dashbaordOpen: false,
          orderOpen: false,
        };
      });
    } else if (val === "orders") {
      setState((prevState) => {
        return {
          ...prevState,
          orderOpen: !state.orderOpen,
          consumentOpen: false,
          zakelijkeOpen: false,
          dashbaordOpen: false,
          InstellingenOpen: false,
        };
      });
    }
    else if (val === "Poppingtime") {
      setState((prevState) => {
        return {
          ...prevState,
          poppingtimeOpen: !state.poppingtimeOpen,
          zakelijkeOpen: false,
          dashbaordOpen: false,
          InstellingenOpen: false,
        };
      });
    } else if (val === "provider") {
      setState((prevState) => {
        return {
          ...prevState,
          providerOpen: !state.providerOpen,
          dashbaordOpen: false,
          InstellingenOpen: false,
        };
      });
    } else if (val === "coupon") {
      setState((prevState) => {
        return {
          ...prevState,
          couponOpen: !state.couponOpen,
          dashbaordOpen: false,
          InstellingenOpen: false,
        };
      });
    } else if (val === "providerInvoices") {
      setState((prevState) => {
        return {
          ...prevState,
          providerInvoicesOpen: !state.providerInvoicesOpen,
          dashbaordOpen: false,
          InstellingenOpen: false,
        };
      });
    } else if (val === "Loyalswan") {
      setState((prevState) => {
        return {
          ...prevState,
          poppingtimeOpen: false,
          zakelijkeOpen: false,
          dashbaordOpen: false,
          InstellingenOpen: false,
          loyalswanOpen: !state.loyalswanOpen
        };
      });
    } else if (val === "tests") {
      setState((prevState) => {
        return {
          ...prevState,
          testsOpen: !state.testsOpen,
        };
      });
    }
  };
  const handleDrawerOpen = () => {
    setState((prevState) => {
      return {
        ...prevState,
        open: true,
      };
    });
  };
  const handleDrawerClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        open: false,
        consumentOpen: false,
        zakelijkeOpen: false,
        InstellingenOpen: false,
        dashbaordOpen: false,
      };
    });
  };

  let routes = (
    <Switch>
      <Route path="/" exact>
        <Dashboad open={state.open} />
      </Route>
      <Route path="/home" exact>
        <Dashboad open={state.open} />
      </Route>
      <Route path="/insights" exact>
        <Insight open={state.open} />
      </Route>
      <Route path="/order-subscription" exact>
        <OrderSubscription open={state.open} />
      </Route>
      <Route path="/ordered-token" exact>
        <OrderToken open={state.open} />
      </Route>
      <Route path="/profile" exact>
        <Profile open={state.open} />
      </Route>
      <Route path="/consumer" exact>
        <Consumer open={state.open} />
      </Route>
      <Route path="/poppingtime-users" exact>
        <PoppingtimeUsers open={state.open} />
      </Route>
      <Route path="/poppingtime-invoices" exact>
        <PoppingtimeInvoices open={state.open} />
      </Route>
      <Route
        path="/Zakelijke/:id"
        exact
        render={(props) => <AddBusiness {...props} open={state.open} />}
      />
      <Route
        path="/consumer-interst"
        exact
        render={(props) => <ConsumerInterst {...props} open={state.open} />}
      />
      <Route
        path="/bu-Loyaliteit"
        exact
        render={(props) => <BuLoyalty {...props} open={state.open} />}
      />
      <Route
        path="/chat"
        exact
        render={(props) => <Chat {...props} open={state.open} />}
      />
      <Route
        path="/bu-Loyaliteit/:id"
        exact
        render={(props) => <AddBuLoyalty {...props} open={state.open} />}
      />
      <Route
        path="/loyalty"
        exact
        render={(props) => <Loyalty {...props} open={state.open} />}
      />
      <Route
        path="/question-answer"
        exact
        render={(props) => <QuestionAnswer {...props} open={state.open} />}
      />
      <Route
        path="/question-answer/:id"
        exact
        render={(props) => <AddQuestionAnswer {...props} open={state.open} />}
      />
      <Route
        path="/test"
        exact
        render={(props) => <Test {...props} open={state.open} />}
      />
      <Route
        path="/test/:id"
        exact
        render={(props) => <AddTest {...props} open={state.open} />}
      />
      <Route
        path="/result"
        exact
        render={(props) => <Result {...props} open={state.open} />}
      />
      <Route
        path="/result/:id"
        exact
        render={(props) => <AddResult {...props} open={state.open} />}
      />

      <Route
        path="/faqs"
        exact
        render={(props) => <FAQ {...props} open={state.open} />}
      />
      <Route
        path="/csv-data"
        exact
        render={(props) => <CSVData {...props} open={state.open} />}
      />
      <Route
        path="/upload-csv"
        exact
        render={(props) => <UploadCSV {...props} open={state.open} />}
      />
      <Route
        path="/faqs/:id"
        exact
        render={(props) => <AddFAQ {...props} open={state.open} />}
      />
      <Route
        path="/how-works/:id"
        exact
        render={(props) => <AddHowWorks {...props} open={state.open} />}
      />
      <Route
        path="/extra-vragen"
        exact
        render={(props) => <ExtraVragen {...props} open={state.open} />}
      />
      <Route
        path="/extra-vragen/:id"
        exact
        render={(props) => <AddExtraVragen {...props} open={state.open} />}
      />
      <Route
        path="/extra-answer/:id/:aid"
        exact
        render={(props) => <ExtraAnswer {...props} open={state.open} />}
      />

      <Route
        path="/region"
        exact
        render={(props) => <Region {...props} open={state.open} />}
      />
      <Route
        path="/user-group"
        exact
        render={(props) => <UserGroup {...props} open={state.open} />}
      />
      <Route
        path="/answer/:id/:aid"
        exact
        render={(props) => <AddAnswer {...props} open={state.open} />}
      />
      <Route
        path="/user-group/:id"
        exact
        render={(props) => <AddUserGroup {...props} open={state.open} />}
      />
      <Route
        path="/categories"
        exact
        render={(props) => <Categories {...props} open={state.open} />}
      />
      <Route
        path="/categories/:id"
        exact
        render={(props) => <AddCategories {...props} open={state.open} />}
      />
      <Route
        path="/sub-categories"
        exact
        render={(props) => <SubCategories {...props} open={state.open} />}
      />
      <Route
        path="/sub-categories/:id"
        exact
        render={(props) => <AddSubCategories {...props} open={state.open} />}
      />
      <Route
        path="/region/:id"
        exact
        render={(props) => <AddRegion {...props} open={state.open} />}
      />
      <Route
        path="/loyalty/:id"
        exact
        render={(props) => <AddLoyalty {...props} open={state.open} />}
      />
      <Route
        path="/interst/:id"
        exact
        render={(props) => <AddInterst {...props} open={state.open} />}
      />
      <Route
        path="/consumer/:id"
        exact
        render={(props) => <AddConsumer {...props} open={state.open} />}
      />
      <Route
        path="/token/:id"
        exact
        render={(props) => <AddToken {...props} open={state.open} />}
      />
      <Route
        path="/token"
        exact
        render={(props) => <Token {...props} open={state.open} />}
      />
      <Route
        path="/provider-products"
        exact
        render={(props) => <ProviderProduct {...props} open={state.open} />}
      />
      <Route
        path="/provider-product-detail/:id"
        exact
        render={(props) => <ProviderProductDetailPage {...props} open={state.open} />}
      />
      <Route
        path="/provider-reservation"
        exact
        render={(props) => <ProviderReservation {...props} open={state.open} />}
      />
      <Route
        path="/subscription"
        exact
        render={(props) => <Subscription {...props} open={state.open} />}
      />
      <Route
        path="/subscription/:id"
        exact
        render={(props) => <AddSubscription {...props} open={state.open} />}
      />
      <Route
        path="/offer"
        exact
        render={(props) => <Offers {...props} open={state.open} />}
      />
      <Route
        path="/offer/:id"
        exact
        render={(props) => <AddOffers {...props} open={state.open} />}
      />
      <Route
        path="/picture/:id"
        exact
        render={(props) => <AddPicture {...props} open={state.open} />}
      />
      <Route path="/Zakelijke" exact>
        <Business open={state.open} />
      </Route>
      <Route path="/provider-users" exact>
        <ProviderUser open={state.open} />
      </Route>
      <Route path="/picture" exact>
        <Picture open={state.open} />
      </Route>
      <Redirect to={"/"} />
    </Switch>
  );
  if (!store.accessToken && store.accessToken === "") {
    routes = (
      <Switch>
        <Route path={"/login"} component={Login} />
        <Redirect to={"/login"} />
      </Switch>
    );
  }

  return (
    <div>
      <Notification />
      {store.accessToken && store.accessToken !== "" ? (
        <SideNavPage
          handleClick={handleClick}
          consumentOpen={state.consumentOpen}
          productOpen={state.productOpen}
          zakelijkeOpen={state.zakelijkeOpen}
          providerOpen={state.providerOpen}
          couponOpen={state.couponOpen}
          providerInvoicesOpen={state.providerInvoicesOpen}
          cuOpen={state.cuOpen}
          loyalswanOpen={state.loyalswanOpen}
          buOpen={state.buOpen}
          orderOpen={state.orderOpen}
          dashbaordOpen={state.dashbaordOpen}
          pricingOpen={state.pricingOpen}
          poppingtimeOpen={state.poppingtimeOpen}
          InstellingenOpen={state.InstellingenOpen}
          testsOpen={state.testsOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          open={state.open}
        />
      ) : null}
      {routes}
    </div>
  );
}

export default App;
