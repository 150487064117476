import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../../Tables/TokenTable'


const headCells = [
    { id: 'NAAM', numeric: false, disablePadding: true, label: 'NAAM' },
    { id: 'NAAMVANTEST', numeric: false, disablePadding: true, label: 'TOKEN / CLICK' },
    { id: 'userType', numeric: false, disablePadding: true, label: 'USER TYPE' },
    { id: 'AANTAL', numeric: false, disablePadding: false, label: 'AANTAL' },
    { id: 'PRIJS', numeric: false, disablePadding: true, label: 'PRIJS' },
    { id: 'Discount', numeric: false, disablePadding: true, label: 'DISCOUNT' },
    { id: 'VALIDTIME', numeric: false, disablePadding: true, label: 'VALID TIME'},
    { id: 'REGIO', numeric: false, disablePadding: true, label: 'REGIO' },
    { id: 'GEBRUIKERS', numeric: false, disablePadding: true, label: 'GEBRUIKERS GROEP' },
  ];

 

class Token extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      tokens: [],
      DeleteToken: [],
      Delstatus: 0,
      regions:[],
      usergroups:[]
    }
  }
  componentWillMount() {
    this.props.GetAllRegion();
    this.props.GetAllUserGroup();
    this.props.GetAllTokenClick();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    if (nextProps.tokens) {
      this.setState({ tokens: nextProps.tokens[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.tokens;
    selected.forEach(element => {
      this.props.DeleteTokenClick(element);
      for(var i = 0; i < data.length; i++) {
        if(data[i]._id == element) {
            data.splice(i, 1);
            break;
        }
      }
    }
    );
    this.setState({tokens:data});
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].title;
    }
    return value;
  };
  getLabelRegions = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.regions[index].Name;
    }
    return value;
  };
    render(){
        return(
            <div>
            

             
                 <PageMenu heading="Token" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={' Token'}
                 AddNewLink={'/token/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                 {this.state.tokens && this.state.usergroups?.length > 0  && this.state.regions?.length > 0?
                    <Table headCells={headCells} 
                    handleDelete={this.handleDelete}
                    getLabelUserGroups={this.getLabelUserGroups}
                    getLabelRegions={this.getLabelRegions}
                    rows={this.state.tokens} editLink={'token'}/>
                 :null}
                 </div>
                  
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    tokens: state.TokenReducer.tokens,
    DeleteToken: state.TokenReducer.DeleteToken,
    regions: state.TokenReducer.regions,
    usergroups: state.TokenReducer.usergroups
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllTokenClick: () => dispatch(actionCreators.GetAllTokenClick()),
    DeleteTokenClick: (id) => dispatch(actionCreators.DeleteTokenClick(id)),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),

  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Token))