import React from 'react'
import { Input, InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


export default class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            punten: 0,
            basicInfo: true,
            priceField:true,
            value:"Ja"
        }
    }
    componentWillMount() {
        this.setState({ name: this.props.match?.params.id })
    }
  
    handleRadio = (event)=>{
        this.setState({value:event.target.value});
        if(event.target.value ==="Nee"){
                this.setState({priceField:false});
        }
        else if(event.target.value === "Ja"){
            this.setState({priceField:true});
        }
    }
    render() {
        return (
            <div>
            
                <div className="flex">
                    <InputLabel>Answer </InputLabel>
                    <Input type="text"
                        value={this.props.ans}
                        onChange={(event) => this.props.handleForm(event, "ans")} />
                </div>
                <div className="flex">
                    <InputLabel>Order</InputLabel>
                    <Input type="number"
                        value={this.props.order}
                        onChange={(event) => this.props.handleForm(event, "order")} />
                </div>
                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch checked={this.props.status} onChange={this.props.handleChange} color="primary" name="checkedA" />}
                                        label="Correct"
                                    />
                                    </FormGroup>
                <div className="flex" style={{ marginTop: "20px" }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Explanation"
                                onChange={(event) => this.props.handleForm(event, "statement")}
                                value={this.props.statement}
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </div>
            </div>
        )
    }
}