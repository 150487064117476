import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import './pageMenu.scss'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '16px'
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));




export default function PageMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={`pageMenu ${props.open}`}>

      <div className="pageMenuLeftItem">
        {props.backArrow ?
          <Link to={props.backUrl}>
            <div className="backArrow">
              <span>
                <ArrowBackIcon />
                <span>{props.backTitle}</span>
              </span>
            </div></Link> : null}
        <h3>{props.heading}</h3>
      </div>

      <div className="pageCenter">
        {props.toggle ?
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button onClick={() => props.handleToggle(props.toggleRight)}>{props.toggleRight}</Button>
            {/* <Button onClick={()=>props.handleToggle("two")}>{props.toggleRight}</Button> */}
          </ButtonGroup>
          : null}
      </div>



      {props.AddNew ?
        <div className="pageMenuRightItem addNewBtn">
          {props.filter ?
            <div>
              <Button style={{ marginRight: "20px", backgroundColor: "#3f51b5" }} type="button" onClick={handleOpen}>
                Filters
                            </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <div>  <form className={classes.root} noValidate autoComplete="off">
                    <TextField id="outlined-basic" label={props.filterFrom} variant="outlined" />

                    <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">ONDERWERP</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          // value={age}
          // onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">SUBONDERWERP</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          // value={age}
          // onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>

                      </form>
                      <form className={classes.root} noValidate autoComplete="off">
                      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">TITEL LEERBOEK</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          // value={age}
          // onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">HOOFDSTUK</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          // value={age}
          // onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">PARAGRAAF</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          // value={age}
          // onChange={handleChange}
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      
                      </form>
                      <Button onClick={handleClose} style={{ float: "left", backgroundColor: "#3f51b5", color: "#fff" }}>Cancel</Button>
                      <Button style={{ float: "right", backgroundColor: "#3f51b5", color: "#fff", marginLeft: "30px" }}>Apply</Button>
                    </div>
                  </div>
                </Fade>
              </Modal>

            </div> : null}
          <Link to={props.AddNewLink}>
            <Button>Add New {props.btnhead}</Button>
          </Link>
        </div>
        : null}
      {props.SaveBtn ?
        <div className="pageMenuRightItem saveBtn">
          {props.download ?
            <div>
              <Button style={{ marginRight: "20px", backgroundColor: "#3f51b5" }} type="button" onClick={handleOpen}>
                Download
                            </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <div> <FormControl>
                      <RadioGroup>
                        <FormControlLabel value="Bu" control={<Radio color="primary" />} label="User Data" />
                        <FormControlLabel value="Cu" control={<Radio color="primary" />} label="Question Data" />
                      </RadioGroup>
                    </FormControl>
                      <Button onClick={handleClose} style={{ float: "left", backgroundColor: "#3f51b5", color: "#fff" }}>Cancel</Button>
                      <Button style={{ float: "right", backgroundColor: "#3f51b5", color: "#fff", marginLeft: "30px" }}>Download</Button>
                    </div>
                  </div>
                </Fade>
              </Modal>

            </div> : null}
          <Button onClick={props.saveHandler}>Save</Button>
        </div>
        : null}
    </div>

  );
}