import { axiosClient } from "../../../../../helper";
import { appStatusAction } from "../../../../../reducer";

// Loyality Actions
export function DeleteUsergroupByID(id) {
  return (dispatch) => {
    return axiosClient()
      .delete(`/deleteUserGroup/${id}`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(DeleteUsergroupByIDSuccess(res.data.remove));
        dispatch(appStatusAction("Usergroup Successfully Deleted"));
      });
  };
}

export function DeleteUsergroupByIDSuccess(data) {
  return {
    type: "DELETE_USERGROUP",
    DeleteUsergroup: data,
    sendingMessage: "Usergroup Successfully Deleted",
  };
}

export function GetUsergroupById(id) {
  return (dispatch) => {
    return axiosClient()
      .get(`/getUserGroup?id=${id}`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetUsergroupByIdSuccess(...res.data.result));
      });
  };
}

export function GetUsergroupByIdSuccess(data) {
  return {
    type: "GET_ONE_USERGROUP",
    OneUsergroup: data,
  };
}
export function UpdateUsergroup(id, data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/editUserGroup/${id}`, data)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(UpdateUsergroupSuccess(res.data.update));
        dispatch(appStatusAction("Usergroup Successfully Updated"));
      });
  };
}

export function UpdateUsergroupSuccess(data) {
  return {
    type: "EDIT_USERGROUP",
    OneUsergroup: data,
    sendingMessage: "Usergroup Successfully Updated",
  };
}

export function AddUsergroup(data) {
  return (dispatch) => {
    return axiosClient()
      .post(`/addUserGroup`, data)
      .then((response) => {
        console.log(response.status);
        if (response.data.message === "record-added") {
          let res = JSON.parse(JSON.stringify(response));
          dispatch(AddUsergroupSuccess(res.data.result));
          dispatch(appStatusAction("Usergroup Successfully Added"));
        } else {
          dispatch(AddUsergroupFailed());
          dispatch(appStatusAction("Adding Usergroup Failed"));
        }
      });
    // .catch(
    //     console.log("catch pr adh"),
    //     dispatch(AddUsergroupFailed())
    // )
  };
}
export function AddUsergroupFailed() {
  return {
    type: "ADD_USERGROUP",
    OneUsergroup: [],
    sendingMessage: "Adding Usergroup Failed",
  };
}
export function AddUsergroupComplete() {
  return {
    type: "FULFILLED",
  };
}
export function AddUsergroupSuccess(data) {
  return {
    type: "ADD_USERGROUP",
    OneUsergroup: data,
    sendingMessage: "Usergroup Successfully Added",
  };
}
export function GetAllUserGroup() {
  return (dispatch) => {
    return axiosClient()
      .get(`/getUserGroup`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllUserGroupSuccess(res.data.result));
      });
  };
}

export function GetAllUserGroupSuccess(data) {
  return {
    type: "GET_USERGROUP",
    usergroups: data,
  };
}
export function GetAllRegion() {
  return (dispatch) => {
    return axiosClient()
      .get(`/GetAllRegion`)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response));
        dispatch(GetAllRegionSuccess(res.data.data));
      });
  };
}

export function GetAllRegionSuccess(data) {
  return {
    type: "GET_REGION",
    regions: data,
  };
}
