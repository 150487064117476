let defaultState = {
  DeleteCategory: [],
  categories: [],
  OneCategory: [],
  usergroups: [],
  subcategories: [],
};

const CategoryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "DELETE_CATEGORY":
      return {
        ...state,
        DeleteCategory: [action.DeleteCategory],
      };
    case "GET_ONE_CATEGORY":
      return {
        ...state,
        OneCategory: [action.OneCategory],
      };
    case "EDIT_CATEGORY":
      return {
        ...state,
        OneCategory: [action.OneCategory],
      };
    case "ADD_CATEGORY":
      return {
        ...state,
        OneCategory: [action.OneCategory],
      };
    case "GET_CATEGORY":
      return {
        ...state,
        categories: [action.categories],
      };
    case "GET_USERGROUP":
      return {
        ...state,
        usergroups: [action.usergroups],
      };
    case "GET_SUBCATEGORY":
      return {
        ...state,
        subcategories: [action.subcategories],
      };

    default:
      return {
        ...state,
      };
  }
};
export default CategoryReducer;
