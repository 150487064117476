let defaultState = {
    DeleteFAQ: [],
    FAQS: [],
    OneFAQ: [],
    OneWorking: [],
    DeleteWorking: [],
    Working: []
}

const FAQReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "DELETE_FAQ":
            return {
                ...state,
                DeleteFAQ: [action.DeleteFAQ]
            }
        case "GET_ONE_FAQ":
            return {
                ...state,
                OneFAQ: [action.OneFAQ]
            }
        case "EDIT_FAQ":
            return {
                ...state,
                OneFAQ: [action.OneFAQ]
            }
        case "ADD_FAQ":
            return {
                ...state,
                OneFAQ: [action.OneFAQ]
            }
        case "GET_FAQ":
            return {
                ...state,
                FAQS: [action.FAQS]
            }
        case "DELETE_Working":
            return {
                ...state,
                DeleteWorking: [action.DeleteWorking]
            }
        case "GET_ONE_Working":
            return {
                ...state,
                OneWorking: [action.OneWorking]
            }
        case "EDIT_Working":
            return {
                ...state,
                OneWorking: [action.OneWorking]
            }
        case "ADD_Working":
            return {
                ...state,
                OneWorking: [action.OneWorking]
            }
        case "GET_Working":
            return {
                ...state,
                Working: [action.Working]
            }
        case "GET_CATEGORY":
            return {
                ...state,
                categories: [action.categories]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }
        default:
            return {
                ...state
            }
    }
}
export default FAQReducer;