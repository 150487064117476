import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

class Reviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="reviews">
                <Box component="fieldset" mb={3} borderColor="transparent">
                    {/* <AccountCircleOutlinedIcon /> */}
                    <Typography component="legend">Maarten Soles <span className="reviews-date">{this.props.dateAdded}</span></Typography>
                    <Rating name="read-only" value={this.props.stars} readOnly />
                    <p>{this.props.Description}</p>
                </Box>
            </div>
        )
    }
}
export default Reviews;