import React from 'react'
import { Input,InputLabel,FormControl ,FormHelperText,Button } from '@material-ui/core';
import './profile.scss'

export default function ChangePasswordForm(props){

    return(
        <div>
        <div className="title-bar">
            <h6>{props.title}</h6>
        </div>
        <div className="profile-Menu-body">
            <div>
            <FormControl>
                <InputLabel htmlFor="my-input">HUIDIG WACHTWOORD</InputLabel>
                <Input type="password" id="my-input" aria-describedby="my-helper-text" />
                </FormControl>

                <FormControl>
                <InputLabel htmlFor="my-input">NIEUW WACHTWOORD</InputLabel>
                <Input type="password" id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">Enter a strong password</FormHelperText>
                </FormControl>

                <FormControl>
                <InputLabel htmlFor="my-input">BEVESTIG WACHTWOORD </InputLabel>
                <Input type="password" id="my-input" aria-describedby="my-helper-text" />
            </FormControl>
            <div className="subButton">
            <Button>Verander wachtwoord</Button>
            </div>
                </div>
                </div>
                </div>
    )
}