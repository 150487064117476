import React from 'react'

import PageMenu from '../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../Tables/RegionTable'


const headCells = [
    { id: 'a', numeric: false, disablePadding: true, label: 'Active' },
    { id: 'p', numeric: false, disablePadding: true, label: 'Private' },
    { id: 'REGIO', numeric: true, disablePadding: false, label: 'REGIO' },
    { id: 'Questions', numeric: true, disablePadding: false, label: 'Questions' },
    { id: 'Lotteryday', numeric: true, disablePadding: false, label: 'Lotteryday' },
    // { id: 'BIJNAAM', numeric: true, disablePadding: false, label: 'BIJNAAM' },
    { id: 'GEBRUIKERSGROEPEN', numeric: true, disablePadding: false, label: 'GEBRUIKERS GROEPEN' },
    { id: 'BEGINPOSTCODE', numeric: true, disablePadding: false, label: 'BEGIN POSTCODE' },
    { id: 'EINDIGENDEPOSTCODE', numeric: true, disablePadding: false, label: 'EINDIGENDE POSTCODE' },
    { id: 'PRODUCTENPERREGIO', numeric: true, disablePadding: false, label: 'PRODUCTEN PER REGIO' },
    { id: 'dayMonth', numeric: true, disablePadding: false, label: 'DEALS PER LOT' },
      ];
class Region extends React.Component{
  constructor(props){
    super(props);
    this.state={
      regions:[],
      DeleteRegion:[],
      Delstatus:0,
      usergroups:[]
    }
  }
  componentWillMount(){
    this.props.GetAllRegion();
    this.props.GetAllUserGroup()
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
      console.log("nextProps.usergroups",nextProps.usergroups)
    }
    if(nextProps.regions){
    this.setState({regions:nextProps.regions[0]})
    console.log("nextProps.regions",nextProps.regions)
    }
}
  handleDelete = (selected)=>{
    var data = this.state.regions;
    selected.forEach((element) => {
      this.props.DeleteRegionByID(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    });
    this.setState({ regions: data });
  }
   getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].title;
    }
    return value;
  };
 
    render(){
        return(
            <div>
               <PageMenu heading="Region" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'Region'}
                 AddNewLink={'/region/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                   {this.state.regions && this.state.usergroups?.length > 0?
                    <Table headCells={headCells} 
                     rows={this.state.regions} 
                     handleDelete={this.handleDelete}
                     getLabelUserGroups={this.getLabelUserGroups}
                    editLink={'region'}/>
                   :null}
                 </div>
                  
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    regions: state.RegionReducer.regions,
    DeleteBuLoyalty: state.RegionReducer.DeleteBuLoyalty,
    usergroups:state.RegionReducer.usergroups
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
    DeleteRegionByID: (id) => dispatch(actionCreators.DeleteRegionByID(id)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Region))