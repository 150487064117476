import React from 'react'

import PageMenu from '../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../Tables/CSVDataTable1'
import Table2 from '../../Tables/CSVDataTable2'

const headCells2 = [
  { id: 'Username', numeric: false, disablePadding: true, label: 'Username' },
  { id: 'usergroup', numeric: true, disablePadding: false, label: 'usergroup' },
  { id: 'discount', numeric: true, disablePadding: true, label: 'discount' },
];



const headCells = [
    { id: 'Onderwerp', numeric: false, disablePadding: true, label: 'Onderwerp' },
    { id: 'Subonderwerp', numeric: true, disablePadding: false, label: 'Subonderwerp' },
    { id: 'Titelleerboek', numeric: true, disablePadding: true, label: 'Titel leerboek' },
    { id: 'Hoofdstuk', numeric: true, disablePadding: true, label: 'Hoofdstuk'},
    { id: 'Vraag', numeric: true, disablePadding: true, label: 'Vraag' },
    { id: 'Openbaar', numeric: true, disablePadding: true, label: 'Openbaar' },
    { id: 'Price', numeric: true, disablePadding: true, label: 'Price' },
    { id: 'Usergroup', numeric: true, disablePadding: true, label: 'Usergroup' },
    { id: 'Actief', numeric: true, disablePadding: true, label: 'Actief' },
    { id: 'Tijdsduur', numeric: true, disablePadding: true, label: 'Tijdsduur' },
    { id: 'Punten', numeric: true, disablePadding: true, label: 'Punten' },
    { id: 'Benodigd', numeric: true, disablePadding: true, label: 'Benodigd %' },
    { id: 'Afbeeldingvraag', numeric: true, disablePadding: true, label: 'Afbeelding vraag' },
    { id: 'Verklaringvanvraag', numeric: true, disablePadding: true, label: 'Verklaring van vraag' },
    { id: 'Afbeeldingverklaring', numeric: true, disablePadding: true, label: 'Afbeelding verklaring' },
    { id: 'Antwoord1', numeric: true, disablePadding: true, label: 'Antwoord 1' },
    { id: 'Correct', numeric: true, disablePadding: true, label: 'Correct' },
    { id: 'volgorde', numeric: true, disablePadding: true, label: 'volgorde' },
    { id: 'Verklaringvanantwoord', numeric: true, disablePadding: true, label: 'Verklaring van antwoord' },
    { id: 'Antwoord2', numeric: true, disablePadding: true, label: 'Antwoord 2' },
    { id: 'Antwoord3', numeric: true, disablePadding: true, label: 'Antwoord 3' },
    { id: 'Antwoord4', numeric: true, disablePadding: true, label: 'Antwoord 4' },
    { id: 'Antwoord5', numeric: true, disablePadding: true, label: 'Antwoord 5' },
    { id: 'Antwoord6', numeric: true, disablePadding: true, label: 'Antwoord 6' },

  ];

class CSVData extends React.Component{
  constructor(props){
    super(props)
    this.state={
      table1:true,
      csvs: [],
      DeleteTest: [],
      Delstatus: 0
    }
  }
  componentWillMount() {
    this.props.GetAllCSV();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.csvs) {
      this.setState({ csvs: nextProps.csvs })
      console.log("nextProps.csvs[0]",nextProps.csvs[0][0].info);
    }
  }
  handleDelete = (selected) => {
    var data = this.state.tests;
    selected.forEach(element => {
      this.props.DeleteTest(element);
      for(var i = 0; i < data.length; i++) {
        if(data[i]._id == element) {
            data.splice(i, 1);
            break;
        }
      }
    }
    );
    this.setState({tests:data});
  }
    render(){
        return(
            <div>
            

             
             

<PageMenu heading={"CSV Data"} 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 filter={false}
                 btnhead={this.state.table1?"CSVs":""}
                 AddNewLink={this.state.table1?"/upload-csv":""}/>


                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    {/* <Table headCells={headCells} rows={rows} editLink={'test'}/> */}

                    {this.state.table1 & this.state.csvs.length > 0?
                    <Table headCells={headCells} handleDelete={this.handleDelete}  rows={this.state.csvs[0][0].info.data} editLink={''}/>
                    :null}
                     {this.state.table1 & this.state.csvs.length > 0?
                    <Table2 headCells={headCells2} handleDelete={this.handleDelete}  rows={this.state.csvs[0][1].info.data} editLink={''}/>
                    :null}
                 </div>
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    csvs: state.CSVReducer.csvs
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllCSV: () => dispatch(actionCreators.GetAllCSV()),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSVData))