import { axiosClient } from '../../../../../helper';
import { appStatusAction } from '../../../../../reducer';


// Loyality Actions
export function DeleteCategoryByID(id){
    return (dispatch) => {

        return axiosClient().delete(`/deleteCategory/${id}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteCategoryByIDSuccess(res.data.remove));
            dispatch(appStatusAction("Category Successfully Deleted"));
        }).catch(e => {
            dispatch(appStatusAction(e.response?.data?.message));
        })
    }
}

export function DeleteCategoryByIDSuccess(data) {
    return {
        type: "DELETE_CATEGORY",
        DeleteCategory:data
    }
}

export function GetCategoryById(id){
    return (dispatch) => {

        return axiosClient().get(`/getCategory?id=${id}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            console.log("res.data.data",res.data.result)
            dispatch(GetCategoryByIdSuccess(...res.data.result));
        })
    }
}

export function GetCategoryByIdSuccess(data) {
    return {
        type: "GET_ONE_CATEGORY",
        OneCategory:data
    }
}
export function UpdateCategory(id, data){
    return (dispatch) => {

        return axiosClient().post(`/editCategory/${id}`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(UpdateCategorypSuccess(res.data.update));
            dispatch(appStatusAction("Category Successfully Edited"));
        }).catch(e => {
            dispatch(appStatusAction(e.response?.data?.error[0]));
        })
    }
}

export function UpdateCategorypSuccess(data) {
    return {
        type: "EDIT_CATEGORY",
        OneCategory:data
    }
}

export function AddCategory(data){
    return (dispatch) => {

        return axiosClient().post(`/addCategory`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddCategorySuccess(res.data.result));
            dispatch(appStatusAction("Category Successfully Added"));
        }).catch(e => {
            dispatch(appStatusAction(e.response?.data?.error[0]));
        })
    }
}

export function AddCategorySuccess(data) {
    return {
        type: "ADD_CATEGORY",
        OneCategory:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            console.log(res.data.result)
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}
export function GetAllSubCategories() {
    return (dispatch) => {
        return axiosClient().get(`/getSubCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllSubCategoriesSuccess(res.data.result));
        })
    }
}

export function GetAllSubCategoriesSuccess(data) {
    return {
        type: "GET_SUBCATEGORY",
        subcategories:data
    }
}