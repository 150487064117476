import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import { Input, InputLabel } from '@material-ui/core';
import MultipleSelect from '../../Pictures/RegionSelect'
import * as actionCreators from '../store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class AddLoyalty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loyalty: "",
            points: 0,
            isNew: false,
            OneBuLoyalty: [],
            regions: [],
            selectedRegion: [],
            updatedRegion: [],
        }
    }
    myfuc = () => {

        this.props.GetBULoyaltyById(this.props.match.params.id);
    }
    componentWillMount() {

        this.props.GetAllRegion();
        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.setState(this.myfuc)
        }
        else {
            this.setState({
                OneBuLoyalty: {
                    "regions": [],
                    "loyalty": "",
                    "points": 0
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.regions) {
            this.setState({ regions: nextProps.regions[0] });
        }
        if (this.state.isNew & nextProps.OneBuLoyalty.length > 0) {
            this.setState({ OneBuLoyalty: nextProps.OneBuLoyalty[0] });
            this.setState({ loyalty: nextProps.OneBuLoyalty[0]?.loyalty, points: nextProps.OneBuLoyalty[0]?.points })
            this.setState({ updatedRegion: nextProps.OneBuLoyalty[0]?.regions })
            nextProps.OneBuLoyalty[0].regions.forEach(element => {
                this.getLabelUserGroups(element)
              });
        }
    }
    handleForm = (event, val) => {
        if (val === "loyalty") {
            this.setState({ loyalty: event.target.value })
        }
        else if (val === "points") {
            this.setState({ points: event.target.value })
        }

    }
    saveHandler = () => {
        if (this.state.isNew) {
            var data = {
                "Id": this.props.match.params.id,
                "loyalty": this.state.loyalty,
                "points": this.state.points,
                "regions": this.state.updatedRegion
            }
            this.props.EditBULoyaltyById(data);
        }
        else {
            var data = {
                "loyalty": this.state.loyalty,
                "points": this.state.points,
                "regions": this.state.updatedRegion
            }
            this.props.AddBULoyalty(data);

        }
    }
    handleRegion = (ids) => {
        this.setState({ updatedRegion: ids });
    }
    getLabelUserGroups = (value) => {
        const index = this.state.regions?.findIndex((d) => d._id === value);
        var ref = this.state.selectedRegion;
        if (index !== -1) {
            ref.push(this.state.regions[index]._id);
        }
        this.setState({ selectedRegion: ref })
    };

    render() {
        return (<div>
            <PageMenu heading="Add Loyalty"
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                backArrow={true}
                backTitle={'Loyalty'}
                backUrl={'/loyalty'}
                SaveBtn={true}
                saveHandler={this.saveHandler}
            />

            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span>Basic Info</span>
                </div>
                <div className="admin-form">
                    <div className="flex">
                        <InputLabel>Actie</InputLabel>
                        <Input type="text"
                            value={this.state.loyalty}
                            onChange={(event) => this.handleForm(event, "loyalty")} />
                    </div>
                    <div className="flex">
                        <InputLabel>Punten</InputLabel>
                        <Input type="number"
                            value={this.state.points}
                            onChange={(event) => this.handleForm(event, "points")} />
                    </div>

                    {this.state.regions.length > 0 && this.state.selectedRegion ?
                        <MultipleSelect handleRegion={this.handleRegion}
                            regions={this.state.regions}
                            selectedRegion={this.state.selectedRegion}
                            label="Select Region" />
                        : null}
                </div>
            </div>
        </div>)
    }
}
const mapStateToProps = (state) => (
    {

        OneBuLoyalty: state.ConsumerReducer.OneBuLoyalty,
        regions: state.ConsumerReducer.regions
    });

const mapDispatchToProps = dispatch => {
    return {
        GetBULoyaltyById: (id) => dispatch(actionCreators.GetBULoyaltyById(id)),
        EditBULoyaltyById: (data) => dispatch(actionCreators.EditBULoyaltyById(data)),
        GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
        AddBULoyalty: (data) => dispatch(actionCreators.AddBULoyalty(data)),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLoyalty))