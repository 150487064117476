import React from 'react'

import PageMenu from '../../page-menu/PageMenu'
import Table from '../../Tables/BuLoyalityTable'
import * as actionCreators from '../store/action/index';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import '../Business.scss'



const headCells = [
    { id: 'ACTIE', numeric: false, disablePadding: true, label: 'Actie' },
    { id: 'Punten', numeric: true, disablePadding: false, label: 'Punten' },
    { id: 'Percent', numeric: true, disablePadding: false, label: 'Percent' },
    { id: 'Bezoek', numeric: false, disablePadding: false, label: 'Bezoek' },
    { id: 'Per kaart', numeric: false, disablePadding: false, label: 'Per kaart' },
    { id: 'REGIO', numeric: false, disablePadding: false, label: 'Regio' },
  ];

 

class BuLoyalty extends React.Component{
  constructor(props){
    super(props);
    this.state={
      BuLoyalty:[],
      DeleteBuLoyalty:[],
      Delstatus:0,
      regions:[]
    }
  }
  componentWillMount(){
    this.props.GetAllRegion();
    this.props.getBuLoyalty();
    if(this.state.Delstatus == 200){

    }
  
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    if(nextProps.BuLoyalty){
    this.setState({BuLoyalty:nextProps.BuLoyalty[0]})
    }
}
  handleDelete = (selected) => {
    var data = this.state.BuLoyalty;
    selected.forEach((element) => {
      this.props.DeleteBULoyaltyById(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    });
    this.setState({ BuLoyalty: data });
  }
  getLabelUserGroups = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.regions[index].Name;
    }
    return value;
  };
 
    render(){
        return(
            <div>
            

             
                 <PageMenu heading="Business Loyalty" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'Loyalty'}
                 AddNewLink={'/bu-Loyaliteit/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                 {this.state.BuLoyalty && this.state.regions?.length > 0?
                    <Table 
                     headCells={headCells}
                     rows={this.state.BuLoyalty} 
                     handleDelete={this.handleDelete}
                     getLabelUserGroups={this.getLabelUserGroups}
                     editLink={'bu-Loyaliteit'}/>
                     :null}
                 </div>
                 </div>
        )
    }
}
const mapStateToProps=(state)=>(
  {
  BuLoyalty:state.BuReducer.BuLoyalty,
  DeleteBuLoyalty:state.BuReducer.DeleteBuLoyalty,
  regions:state.BuReducer.regions
});

const mapDispatchToProps = dispatch => {
  return {
    getBuLoyalty: () => dispatch(actionCreators.getBuLoyalty()),
    DeleteBULoyaltyById: (id) => dispatch(actionCreators.DeleteBULoyaltyById(id)),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion())
  }
  
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BuLoyalty))