import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import { Input, InputLabel } from '@material-ui/core';
import MultipleSelect from '../../Pictures/RegionSelect'
import CategorySelect from '../../Pictures/CategorySelect'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../Tables/ExtraVragenAnswerTable'


const headCells = [
  { id: 'how', numeric: false, disablePadding: true, label: 'Answer' },
  { id: 'usergroup', numeric: false, disablePadding: false, label: 'Order' }
];

class AddExtraVragen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Question: "",
      CU: false,
      BU: false,
      table1: true,
      table2: false,
      OneQuestion: [],
      regions: [],
      categories: [],
      selectedRegion: [],
      updatedRegion: [],
      selectedCategory: [],
      updatedCategory: [],
      isNew: false,
      userType: "",
      answers: "",
      isAnswer: false,
    }
  }
  myfuc = () => {
    this.props.GetQuestionById(this.props.match.params.id);
}
  componentWillMount() {
    this.props.GetAllRegion();
    this.props.GetAllCategory();
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.setState(this.myfuc)
    }
    else {
      this.setState({
        OneQuestion: {
          "regions": [],
          "categories": [],
          "userType": "",
          "Q": ""
        }
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
    }
    if (nextProps.OneQuestion.length > 0 & this.state.isNew) {
      this.setState({ OneQuestion: nextProps.OneQuestion[0] });
      this.setState({
        Question: nextProps.OneQuestion[0]?.Q,
        updatedRegion: nextProps.OneQuestion[0]?.regions,
        updatedCategory: nextProps.OneQuestion[0]?.categories,
        answers: nextProps.OneQuestion[0].answers,
        userType:nextProps.OneQuestion[0].userType
      })
  if(this.state.selectedRegion.length < 1){
      nextProps.OneQuestion[0].regions.forEach(element => {
        this.getLabelRegion(element)
    });
  }
  if(this.state.selectedCategory.length < 1){
    nextProps.OneQuestion[0].categories.forEach(element => {
      this.getLabelCategory(element)
  });
}
      if (nextProps.OneQuestion[0].answers?.length > 0) {
        this.setState({ isAnswer: true });
      }
      // if (nextProps.OneQuestion[0]?.BU) {
      //   this.setState({ value: "BU" });
      // }
      // else if (nextProps.OneQuestion[0]?.CU) {
      //   this.setState({ value: "CU" });
    //  }
    }
  }
  handleToggle = (str) => {
    if (str === "Add Extra Vragen") {
      this.setState({ table1: true });
      this.setState({ table2: false });
    }
    else if (str === "Answer") {
      console.log("under");
      this.setState({ table1: false });
      this.setState({ table2: true });
    }
  }
  saveHandler = () => {
    if (this.state.isNew) {
      var data = {
        "Id": this.props.match.params.id,
        "regions": this.state.updatedRegion,
        "categories": this.state.updatedCategory,
        "userType": this.state.userType,
        "Q": this.state.Question,
      }
      this.props.EditQuestion(data);
    }
    else {
      var data = {
        "regions": this.state.updatedRegion,
        "categories": this.state.updatedCategory,
        "userType": this.state.userType,
        "Q": this.state.Question,
      }
      this.props.AddQuestion(data);
    }
  }
  handleUsergroup = (ids) => {
    this.setState({ updatedRegion: ids });
  }
  handleCategory = (ids) => {
    this.setState({ updatedCategory: ids });
  }
  handleForm = (event, val) => {
    this.setState({ value: event.target.value });
    if (val === "Question") {
      this.setState({ Question: event.target.value })
    }
    else if (val === "punten") {
      this.setState({ punten: event.target.value })
    }

  }
  handleDelete = (selected) => {
    var data = this.state.answers;
    selected.forEach(element => {
      this.props.DeletAnswerEQ(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ answers: data });
  }
  handleRadio = (event) => {
    this.setState({ userType: event.target.value });
   
  }
  getLabelRegion = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    var ref =  this.state.selectedRegion;
    if (index !== -1) {
      ref.push(this.state.regions[index]._id);
    }
    this.setState({selectedRegion:ref})
  };
  getLabelCategory = (value) => {
    const index = this.state.categories?.findIndex((d) => d._id === value);
    var ref =  this.state.selectedCategory;
    if (index !== -1) {
      ref.push(this.state.categories[index]._id);
    }
    this.setState({selectedCategory:ref})
  };
  render() {
    return (<div>
      <PageMenu heading={this.state.table1 ? "Add Extra Vragen" : "Answers"}
        open={this.props.open ? "page-menu-open" : null}
        backArrow={this.state.table1 ? true : false}
        handleToggle={this.handleToggle}
        AddNew={this.state.table2 ? true : false}
        toggle={this.state.isAnswer ? true : false}
        toggleRight={this.state.table2 ? 'Add Extra Vragen' : 'Answer'}
        btnhead={this.state.table2 ? "Answer" : ""}
        AddNewLink={this.state.table2 ? `/answer/${this.props.match.params.id}/new` : ""}
        backTitle={this.state.table1 ? 'Extra Vragen' : ""}
        backUrl={'/extra-vragen'}
        SaveBtn={this.state.table1 ? true : false}
        saveHandler={this.saveHandler} />

      {this.state.table1 ?
        <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
          <div className="form-head">
            <span>Basic Info</span>
          </div>
          <div className="admin-form">
            <div className="flex">
              <InputLabel>VRAAG </InputLabel>
              <Input type="text"
                value={this.state.Question}
                onChange={(event) => this.handleForm(event, "Question")} />
            </div>
            {/* <div className="flex">
                            <InputLabel>Order</InputLabel>
                            <Input type="number"
                                value={this.state.punten}
                                onChange={(event) => this.handleForm(event, "punten")} />
                        </div> */}

            <InputLabel>BESTEMMING </InputLabel>

            <FormControl>
              <RadioGroup value={this.state.userType} onChange={this.handleRadio}>
                <FormControlLabel value="bu-user" control={<Radio color="primary" />} label="BU" />
                <FormControlLabel value="cu-user" control={<Radio color="primary" />} label="CU" />
              </RadioGroup>
            </FormControl>
            {this.state.regions?.length > 0 && this.state.selectedRegion ?
              <MultipleSelect handleRegion={this.handleUsergroup}
                regions={this.state.regions}
                selectedRegion={this.state.selectedRegion}
                label="Select Region" />
              : null}

            {this.state.categories?.length > 0 && this.state.selectedCategory ?
              <CategorySelect handleRegion={this.handleCategory}
                regions={this.state.categories}
                selectedRegion={this.state.selectedCategory}
                label="CATEGORIEËN" />
              : null}
          </div>
        </div>
        : null}
      {this.state.table2 && this.state.answers ?
        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          <Table headCells={headCells} rows={this.state.answers}
            handleDelete={this.handleDelete}
            editLink={`answer/${this.props.match.params.id}`} />
        </div>
        : null}

    </div>)
  }
}
const mapStateToProps = (state) => (
  {
    OneQuestion: state.ExtraVragenReducer.OneQuestion,
    regions: state.ExtraVragenReducer.regions,
    categories: state.ExtraVragenReducer.categories,
  });

const mapDispatchToProps = dispatch => {
  return {
    GetQuestionById: (id) => dispatch(actionCreators.GetQuestionById(id)),
    EditQuestion: (data) => dispatch(actionCreators.EditQuestion(data)),
    AddQuestion: (data) => dispatch(actionCreators.AddQuestion(data)),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
    DeletAnswerEQ: (id) => dispatch(actionCreators.DeletAnswerEQ(id)),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddExtraVragen))