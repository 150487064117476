import React from 'react'

import PageMenu from '../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../Tables/SubCategoriesTable'



const headCells = [
    { id: 'SUBCATEGORIE', numeric: false, disablePadding: false, label: 'SUBCATEGORIE' },
    { id: 'codeName', numeric: false, disablePadding: false, label: 'Code Name' },
    { id: 'TOONKALENDER', numeric: false, disablePadding: false, label: 'TOON KALENDER' },
    { id: 'PROVISIE', numeric: false, disablePadding: false, label: 'PROVISIE' },
      ];
class SubCategories extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      subcategories: [],
      DeleteSubCategory: [],
      Delstatus: 0
    }
  }
  componentWillMount() {
    this.props.GetAllSubCategories();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.subcategories) {
      this.setState({ subcategories: nextProps.subcategories[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.subcategories;
    selected.forEach(element => {
      this.props.DeletSubCategory(element);
      for(var i = 0; i < data.length; i++) {
        if(data[i]._id == element) {
            data.splice(i, 1);
            break;
        }
      }
    }
    );
    this.setState({subcategories:data});
  }
    render(){
        return(
            <div>
            

             
                 <PageMenu heading="SubCategories" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'SubCategories'}
                 AddNewLink={'/sub-categories/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Table headCells={headCells}  handleDelete={this.handleDelete} 
                     rows={this.state.subcategories}  editLink={'sub-categories'}/>
                 </div>
                  
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    subcategories: state.SubCategoryReducer.subcategories,
    DeleteSubCategory: state.SubCategoryReducer.DeleteSubCategory
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllSubCategories: () => dispatch(actionCreators.GetAllSubCategories()),
    DeletSubCategory: (id) => dispatch(actionCreators.DeletSubCategory(id)),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubCategories))