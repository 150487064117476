import { axiosClient } from '../../../../../../helper';
import { appStatusAction } from '../../../../../../reducer';


// Loyality Actions
export function DeleteOffers(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletOffers`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletOffersSuccess(res.data));
            dispatch(appStatusAction("Offers Successfully Deleted"));
        })
    }
}

export function DeletOffersSuccess(data) {
    return {
        type: "DELETE_OFFER",
        DeleteOffer:data
    }
}

export function GetOffersById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetOffersById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetOffersByIdSuccess(res.data.data));
        })
    }
}

export function GetOffersByIdSuccess(data) {
    return {
        type: "GET_OFFER",
        OneOffer:data
    }
}
export function EditOffers(data){
    return (dispatch) => {

        return axiosClient().post(`/EditOffers`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditOffersSuccess(res.data.data));
            dispatch(appStatusAction("Offers Successfully Edited"));
        })
    }
}

export function EditOffersSuccess(data) {
    return {
        type: "GET_OFFER",
        OneOffer:data
    }
}

export function AddOffers(data){
    return (dispatch) => {

        return axiosClient().post(`/AddOffers`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddOffersSuccess(res.data.data));
            dispatch(appStatusAction("Offers Successfully Added"));
        })
    }
}

export function AddOffersSuccess(data) {
    return {
        type: "GET_OFFER",
        OneOffer:data
    }
}
export function GetAllOffers() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllOffers`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllOffersSuccess(res.data.data));
        })
    }
}

export function GetAllOffersSuccess(data) {
    return {
        type: "GET_ALL_OFFERS",
        offers:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}
export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}

export function GetAllRegion() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllRegionSuccess(res.data.data));
        })
    }
}

export function GetAllRegionSuccess(data) {
    return {
        type: "GET_REGION",
        regions:data
    }
}
export function GetAllCategory() {
    return (dispatch) => {
        return axiosClient().get(`/getCategory`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllCategorySuccess(res.data.result));
        })
    }
}

export function GetAllCategorySuccess(data) {
    return {
        type: "GET_CATEGORY",
        categories:data
    }
}