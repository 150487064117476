import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Input, InputLabel } from '@material-ui/core';
import MultipleSelect from '../../Pictures/RegionSelect';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as actionCreators from '../store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class AddBuLoyalty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phoneNumber: "",
            email: "",
            password: "",
            PERCENT: false,
            active: false,
            POINTS: false,
            PER: false,
            KAART: false,
            setting: "",
            generalSetting: "",
            pointVal: 0,
            percentVal: 0,
            punten: 0,
            isNew: false,
            value:0,
            OneBuLoyalty: [],
            regions: [],
            selectedRegion: [],
            updatedRegion: [],
        }
    }
    myfuc = () => {

        this.props.GetBULoyaltyById(this.props.match.params.id);
    }
    componentWillMount() {

        this.props.GetAllRegion();
        if (this.props.match.params.id != "new") {
            this.setState({ isNew: true });
            this.setState(this.myfuc)
        }
        else {
            this.setState({
                OneBuLoyalty: {
                    "active": false,
                    "regions": [],
                    "loyalty": "",
                    "pointVal": 0,
                    "percentVal": 0,
                    "percent": false,
                    "points": false,
                    "perVisit": false,
                    "perCard": false
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.regions) {
            this.setState({ regions: nextProps.regions[0] });
        }
        if (this.state.isNew & nextProps.OneBuLoyalty.length > 0) {
            this.setState({ OneBuLoyalty: nextProps.OneBuLoyalty[0] });
            this.setState({
                name: nextProps.OneBuLoyalty[0]?.loyalty,
                pointVal: nextProps.OneBuLoyalty[0]?.pointVal,
                percentVal: nextProps.OneBuLoyalty[0]?.percentVal,
                PERCENT: nextProps.OneBuLoyalty[0]?.percent,
                POINTS: nextProps.OneBuLoyalty[0]?.points,
                active: nextProps.OneBuLoyalty[0]?.active,
                PER: nextProps.OneBuLoyalty[0]?.perVisit,
                KAART: nextProps.OneBuLoyalty[0]?.perCard
            })
            nextProps.OneBuLoyalty[0].regions.forEach(element => {
                this.getLabelUserGroups(element)
            });
            if (nextProps.OneBuLoyalty[0]?.percent) {
                this.setState({ setting: "PERCENT" })

            }
            else if (nextProps.OneBuLoyalty[0]?.points) {
                this.setState({ setting: "POINTS" })
            }
            if (nextProps.OneBuLoyalty[0]?.perVisit) {
                this.setState({ generalSetting: "PER" })
            }
            else if (nextProps.OneBuLoyalty[0]?.perCard) {
                this.setState({ generalSetting: "KAART" })
            }
            // this.setState({ selectedRegion: nextProps.OneBuLoyalty[0]?.regions })
            this.setState({ updatedRegion: nextProps.OneBuLoyalty[0]?.regions })
        }
    }
    handleForm = (event, val) => {
        if (val === "name") {
            this.setState({ name: event.target.value })
        }
        else if (val === "punten") {
            this.setState({ punten: event.target.value })
        }
        else if (val === "value" & this.state.PERCENT) {
            this.setState({ percentVal: event.target.value })
            this.setState({ pointVal:0 })
            this.setState({ value: event.target.value })
        }
        else if (val === "value" & this.state.POINTS) {
            this.setState({ pointVal: event.target.value })
            this.setState({ percentVal:0})
            this.setState({ value: event.target.value })
        }

    }
    handleChange = (event) => {
        if (event.target.value === "PERCENT") {
            this.setState({ PERCENT: true });
            this.setState({ POINTS: false });
            this.setState({ setting: "PERCENT" })

        }
        if (event.target.value === "POINTS") {
            this.setState({ PERCENT: false });
            this.setState({ POINTS: true });
            this.setState({ setting: "POINTS" })
        }
        if (event.target.name === "checkedA") {
            this.setState({ active: event.target.checked });
        }
        if (event.target.value === "PER") {
            this.setState({ PER: true });
            this.setState({ KAART: false });
            this.setState({ generalSetting: "PER" })

        }
        if (event.target.value === "KAART") {
            this.setState({ KAART: true });
            this.setState({ PER: false });
            this.setState({ generalSetting: "KAART" })
        }

        // this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };
    saveHandler = () => {
        if (this.state.isNew) {
            var data = {
                "Id": this.props.match.params.id,
                "active": this.state.active,
                "loyalty": this.state.name,
                "percentVal": this.state.percentVal,
                "pointVal": this.state.pointVal,
                "percent": this.state.PERCENT,
                "points": this.state.POINTS,
                "perVisit": this.state.PER,
                "perCard": this.state.KAART,
                "regions": this.state.updatedRegion
            }
            this.props.EditBULoyaltyById(data);
        }
        else {
            var data = {
                "active": this.state.active,
                "loyalty": this.state.name,
                "percentVal": this.state.percentVal,
                "pointVal": this.state.pointVal,
                "percent": this.state.PERCENT,
                "points": this.state.POINTS,
                "perVisit": this.state.PER,
                "perCard": this.state.KAART,
                "regions": this.state.updatedRegion
            }
            this.props.AddBULoyalty(data);

        }
    }
    handleRegion = (ids) => {
        this.setState({ updatedRegion: ids });
    }
    getLabelUserGroups = (value) => {
        const index = this.state.regions?.findIndex((d) => d._id === value);
        var ref = this.state.selectedRegion;
        if (index !== -1) {
            ref.push(this.state.regions[index]._id);
        }
        this.setState({ selectedRegion: ref })
    };

    render() {
        return (<div>




            <PageMenu heading="Add Business Loyalty"
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                backArrow={true}
                backTitle={'Business Loyalty'}
                backUrl={'/bu-Loyaliteit'}
                SaveBtn={true}
                saveHandler={this.saveHandler} />
            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span>Basic Info</span>
                </div>
                <div className="admin-form">
                    <div className="flex">
                        <InputLabel>Loyalty</InputLabel>
                        <Input type="text"
                            value={this.state.name}
                            onChange={(event) => this.handleForm(event, "name")} />
                    </div>
                    <div>
                        <InputLabel>Status</InputLabel>
                        <div>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch checked={this.state.active} onChange={this.handleChange} color="primary" name="checkedA" />}
                                    label="Active"
                                />
                            </FormGroup>

                        </div>
                    </div>

                    <div>
                        <InputLabel>Choose Settings</InputLabel>
                        <div>
                            <RadioGroup value={this.state.setting} onChange={this.handleChange}>
                                <FormControlLabel value="PERCENT" control={<Radio color="primary" />} label="PERCENT" />
                                <FormControlLabel value="POINTS" control={<Radio color="primary" />} label="POINTS" />
                            </RadioGroup>
                            <hr />
                            <RadioGroup value={this.state.generalSetting} onChange={this.handleChange}>
                                <FormControlLabel value="PER" control={<Radio color="primary" />} label="PER BEZOEK" />
                                <FormControlLabel value="KAART" control={<Radio color="primary" />} label="PER KAART" />
                            </RadioGroup>

                        </div>
                    </div>
                    {this.state.PERCENT ?

                        <div className="flex" style={{ marginTop: "20px" }}>
                            <InputLabel>STANDAARDPERCENTAGE</InputLabel>
                            <Input type="number"
                                value={this.state.percentVal}
                                placeholder="%"
                                onChange={(event) => this.handleForm(event, "value")} />
                        </div>
                        : null}

                    {this.state.POINTS ?
                        <div className="flex" style={{ marginTop: "20px" }}>
                            <InputLabel>STANDAARDPUNTEN</InputLabel>
                            <Input type="number"
                                value={this.state.pointVal}
                                placeholder="points"
                                onChange={(event) => this.handleForm(event, "value")} />
                        </div>
                        : null}

                    {this.state.regions.length > 0 && this.state.selectedRegion ?
                        <MultipleSelect handleRegion={this.handleRegion}
                            regions={this.state.regions}
                            selectedRegion={this.state.selectedRegion}
                            label="Select Region" />
                        : null}
                    {/* <MultipleSelect label="usergroup" /> */}
                </div>
            </div>
        </div>)
    }
}
const mapStateToProps = (state) => (
    {
        OneBuLoyalty: state.BuReducer.OneBuLoyalty,
        regions: state.BuReducer.regions
    });

const mapDispatchToProps = dispatch => {
    return {
        GetBULoyaltyById: (id) => dispatch(actionCreators.GetBULoyaltyById(id)),
        EditBULoyaltyById: (data) => dispatch(actionCreators.EditBULoyaltyById(data)),
        GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),
        AddBULoyalty: (data) => dispatch(actionCreators.AddBULoyalty(data)),
    }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBuLoyalty))