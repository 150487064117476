let defaultState = {
    regions: [],
    DeleteRegion: [],
    usergroups: [],
    OneRegion: [],
}

const RegionReducer = (state = defaultState, action) => {
    switch (action.type) {

        case "GET_REGION":
            return {
                ...state,
                regions: [action.regions]
            }
        case "GET_ONE_REGION":
            return {
                ...state,
                OneRegion: [action.OneRegion]
            }
        case "EDIT_REGION":
            return {
                ...state,
                OneRegion: [action.OneRegion]
            }
        case "DELETE_REGION":
            return {
                ...state,
                DeleteRegion: [action.DeleteRegion]
            }
        case "ADD_REGION":
            return {
                ...state,
                OneRegion: [action.OneRegion]
            }
        case "GET_USERGROUP":
            return {
                ...state,
                usergroups: [action.usergroups]
            }

        default:
            return {
                ...state
            }
    }
}
export default RegionReducer;