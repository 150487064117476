import { axiosClient } from '../../../../../helper';
import { appStatusAction } from '../../../../../reducer';


// Loyality Actions
export function DeleteRegionByID(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletRegion`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeleteRegionByIDSuccess(res.data));
            dispatch(appStatusAction("Region Successfully Deleted"));
        })
    }
}

export function DeleteRegionByIDSuccess(data) {
    return {
        type: "DELETE_REGION",
        DeleteRegion:data
    }
}

export function GetRegionById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetRegionById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetRegionByIdSuccess(res.data.data));
        })
    }
}

export function GetRegionByIdSuccess(data) {
    return {
        type: "GET_ONE_REGION",
        OneRegion:data
    }
}
export function EditRegionById(data){
    return (dispatch) => {

        return axiosClient().post(`/EditRegion`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditRegionByIdSuccess(res.data.data));
            dispatch(appStatusAction("Region Successfully Edited"));
        })
    }
}

export function EditRegionByIdSuccess(data) {
    return {
        type: "EDIT_REGION",
        OneRegion:data
    }
}

export function GetAllRegion() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllRegionSuccess(res.data.data));
        })
    }
}

export function GetAllRegionSuccess(data) {
    return {
        type: "GET_REGION",
        regions:data
    }
}
export function AddRegion(data){
    return (dispatch) => {

        return axiosClient().post(`/AddRegion`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddRegionSuccess(res.data.data));
            dispatch(appStatusAction("Region Successfully Added"));
        })
    }
}

export function AddRegionSuccess(data) {
    return {
        type: "ADD_REGION",
        OneRegion:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}

export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}