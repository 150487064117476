let defaultState = {
    Providers: [],
    usergroups:[]   
}

const ProviderReducer = (state = defaultState, action) => {
    switch (action.type) {

        case "GET_ALL_PROVIDER":
            return {
                ...state,
                Providers: [action.Providers]
            };
            case "GET_USERGROUP":
                return {
                  ...state,
                  usergroups: [action.usergroups],
                };
        default:
            return {
                ...state
            }
    }
}
export default ProviderReducer;