import React, { useState } from 'react'
import { InputLabel, Input } from '@material-ui/core';
import UsergroupSelect from '../../../Pictures/UsergroupSelect'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';



export default function BasicInfo(props) {
    const [value, setValue] = useState("Ja");
    const [name, setname] = useState("")

    function handleRadio(event) {
        setValue(event.target.value);
        props.handleRadioPublic(event)
    }

    return (
        <div>
            <InputLabel>OPENBAAR</InputLabel>

            <FormControl>
                <RadioGroup value={props.publicVal} onChange={handleRadio}>
                        <FormControlLabel value="Nee" control={<Radio color="primary" />} label="Nee" />
                        <FormControlLabel value="Ja" control={<Radio color="primary" />} label="Ja" />
                    </RadioGroup>




            </FormControl>
            {value === "Ja" ?
                <div className="flex">
                    <InputLabel>Price </InputLabel>
                    <Input type="number"
                        value={props.price}
                        onChange={(event) => props.handleForm(event, "price")} />
                </div> : null}

                {props.usergroups?.length > 0 && props.selectedUserGroup ?
                <UsergroupSelect handleRegion={props.handleUsergroup}
                    regions={props.usergroups}
                    selectedRegion={props.selectedUserGroup}
                    label="GEBRUIKERS GROEPEN" />
                : null}



            <InputLabel style={{ marginTop: "30px" }}>ACTIEF  </InputLabel>

            <FormControl>
                    <RadioGroup value={props.ACTIEF} onChange={props.handleRadioACTIEF}>
                        <FormControlLabel value="Nee" control={<Radio color="primary" />} label="Nee" />
                        <FormControlLabel value="Ja" control={<Radio color="primary" />} label="Ja" />
                    </RadioGroup>
                </FormControl>
        </div>
    )
}