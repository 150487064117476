import React from 'react'
import * as actionCreators from "./store/action/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PageMenu from '../../page-menu/PageMenu'
import Table from '../../Tables/ProviderProductTable'
import './Index.scss'

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'PRODUCTEN' },
  { id: 'calories', numeric: true, disablePadding: false, label: 'STARTDATUM' },
  { id: 'fat', numeric: true, disablePadding: false, label: 'EINDDATUM' },
  { id: 'carbs', numeric: true, disablePadding: false, label: 'REGIO' },
  { id: 'protein', numeric: true, disablePadding: false, label: 'CATEGORIE' },
  { id: 'ad', numeric: true, disablePadding: false, label: 'AD' },
  { id: 'status', numeric: false, disablePadding: false, label: 'STATUS' },
];

class Consumer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }
  componentWillMount() {
    this.props.GetAllProducts();
    this.props.GetAllRegion();
    this.props.GetAllCategory();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.regions) {
      this.setState({ regions: nextProps.regions[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
    }
    if (nextProps.products) {
      this.setState({ products: nextProps.products[0] });
    }
  }
  handleStatusChanges = (data) => {
    this.props.AuthorizeProduct(data);

  }
  getLabelCategory = (value) => {
    const index = this.state.categories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.categories[index].name;
    }
    return value;
  };
  getLabelRegion = (value) => {
    const index = this.state.regions?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.regions[index].Name;
    }
    return value;
  };
  render() {
    return (
      <div>
        <PageMenu heading="Provider Products"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          btnhead={'Products'}
          AddNewLink={'/provider-products/new'} />
          <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          {this.state.products && this.state.categories?.length > 0 & this.state.regions?.length > 0 ?
          <Table headCells={headCells}
            rows={this.state.products}
            editLink={'provider-product-detail'}
            handleStatusChanges={this.handleStatusChanges}
            getLabelCategory={this.getLabelCategory}
            getLabelRegion={this.getLabelRegion}
          />
          :null}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  products: state.ProviderProductReducer.products,
  StatusChanged: state.ProviderProductReducer.StatusChanged,
  regions: state.ProviderProductReducer.regions,
  categories: state.ProviderProductReducer.categories,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllProducts: () => dispatch(actionCreators.GetAllProducts()),
    AuthorizeProduct: (data) => dispatch(actionCreators.AuthorizeProduct(data)),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
    GetAllRegion: () => dispatch(actionCreators.GetAllRegion()),

  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Consumer)
);