import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 190,
  },
});

export default function ImageCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.img}
          title="Contemplative Reptile"
        />
      </CardActionArea>
      <CardActions style={{float:"right"}}>
        {/* <Button size="small" color="primary">
          Share
        </Button> */}
        <Button onClick={props.handleRemoveCard} size="small" color="primary">
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}
