import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import Table from '../../../Tables/QuestionAnswerTable'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";


const headCells = [
  { id: 'VRAAG', numeric: false, disablePadding: true, label: 'Actie' },
  { id: 'NUMMERANTWOORDEN', numeric: false, disablePadding: false, label: 'NUMMER ANTWOORDEN' },
  { id: 'ONDERWERP', numeric: false, disablePadding: true, label: 'ONDERWERP' },
  { id: 'SUBONDERWERP', numeric: false, disablePadding: true, label: 'SUBONDERWERP' },
  { id: 'TITLE', numeric: false, disablePadding: true, label: 'TITLE' },
  { id: 'CHAPTER', numeric: false, disablePadding: true, label: 'CHAPTER' },
  { id: 'PARAGRAPH', numeric: false, disablePadding: true, label: 'PARAGRAPH' },
  { id: 'TOESTAND', numeric: false, disablePadding: true, label: 'TOESTAND' },
  { id: 'PUNTEN', numeric: false, disablePadding: true, label: 'PUNTEN' },
];



class QuestionAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testQuestions: [],
      DeleteTestQuestion: [],
      Delstatus: 0,
      subcategories: [],
      categories: [],
    }
  }
  componentWillMount() {
    this.props.GetAllTestQuestions();
    this.props.GetAllCategory();
    this.props.GetAllSubCategories();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.subcategories) {
      this.setState({ subcategories: nextProps.subcategories[0] });
    }
    if (nextProps.categories) {
      this.setState({ categories: nextProps.categories[0] });
    }
    if (nextProps.testQuestions) {
      this.setState({ testQuestions: nextProps.testQuestions[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.testQuestions;
    selected.forEach(element => {
      this.props.DeleteTestQuestion(element);
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id == element) {
          data.splice(i, 1);
          break;
        }
      }
    }
    );
    this.setState({ testQuestions: data });
  }
  getLabelCategory = (value) => {
    const index = this.state.categories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.categories[index].name;
    }
    return value;
  };
  getLabelSubCategory = (value) => {
    const index = this.state.subcategories?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.subcategories[index].name;
    }
    return value;
  };
  render() {
    return (
      <div>
        <PageMenu heading="Question And Answers"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={true}
          filter={true}
          filterFrom={'Vraag'}
          btnhead={'Q&A'}
          AddNewLink={'/question-answer/new'} />
        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          {this.state.testQuestions && this.state.categories?.length > 0 ?
            <Table headCells={headCells}
              rows={this.state.testQuestions}
              editLink={'question-answer'}
              getLabelCategory={this.getLabelCategory}
              getLabelSubCategory={this.getLabelSubCategory}
              handleDelete={this.handleDelete}
            />
            : null}
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    testQuestions: state.TestQuestionReducer.testQuestions,
    DeleteTestQuestion: state.TestQuestionReducer.DeleteTestQuestion,
    subcategories: state.TestQuestionReducer.subcategories,
    categories: state.TestQuestionReducer.categories,

  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllTestQuestions: () => dispatch(actionCreators.GetAllTestQuestions()),
    DeleteTestQuestion: (id) => dispatch(actionCreators.DeleteTestQuestion(id)),
    GetAllSubCategories: () => dispatch(actionCreators.GetAllSubCategories()),
    GetAllCategory: () => dispatch(actionCreators.GetAllCategory()),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionAnswer))