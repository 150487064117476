import React from 'react';
import './Widget.scss'

function Widget1(props){
 

    return(
        <div className="widget">
            <div className="widgetHeading">
                <p className={props.color}>{props.heading}</p>
               <h4>{props.number}</h4>
            </div>

        </div>
    )
}
export default Widget1;