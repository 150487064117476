import React from 'react';

import PageMenu from '../../page-menu/PageMenu'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import DropFile from './DropFile'
import { post } from 'axios'

export default class UploadCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Qdata: false,
            Udata: false,
            dataType: "",
            file: []
        }
    }
    componentWillMount() {
        this.setState({ name: "Saif" })
    }


    onFormSubmit() {
        // Stop form submit
        console.log(this.state.number)
        this.fileUpload(this.state.file).then((response) => {
            console.log(response.data);
        })
    }



    fileUpload = (file) => {
        const url = 'http://api.beelicensed.com/UploadDataFile';
        var data = {
            "Qdata": this.state.Qdata,
            "Udata": this.state.Udata,
        }
        const formData = new FormData();
        formData.append('file', file)
        formData.append('data', JSON.stringify(data));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(url, formData, config)
    }

    handleFileAdded = (fil) => {
        console.log(fil)
        this.setState({ file: fil })
    }



    handleChange = (event) => {
        if (event.target.value === "Qdata") {
            this.setState({ Qdata: true });
            this.setState({ Udata: false });
            this.setState({ dataType: "Qdata" })

        }
        if (event.target.value === "Udata") {
            this.setState({ Qdata: false });
            this.setState({ Udata: true });
            this.setState({ dataType: "Udata" })
        }
    };
    saveHandler = () => {
        if (this.state.isNew) {
        }
        else {
            this.onFormSubmit();
        }
    }

    render() {
        return (<div>
            <PageMenu heading="Upload File"
                open={this.props.open ? "page-menu-open" : null}
                AddNew={false}
                download={false}
                backArrow={true}
                backTitle={'CSV Data '}
                backUrl={'/csv-data'}
                SaveBtn={true}
                saveHandler={this.saveHandler} />
            <div className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}>
                <div className="form-head">
                    <span>Basic Info</span>
                </div>
                <div className="admin-form">
                    <div>

                        <FormControl>
                            <RadioGroup value={this.state.dataType} onChange={this.handleChange}>
                                <FormControlLabel value="Udata" name="Udata" control={<Radio color="primary" />} label="User Data" />
                                <FormControlLabel value="Qdata" name="Qdata" control={<Radio color="primary" />} label="Question Data" />
                            </RadioGroup>
                        </FormControl>
                    </div>


                    <div className="drop-file">
                        <DropFile onFormSubmit={this.onFormSubmit}
                            onChange={this.onChange}
                            handleFileAdded={this.handleFileAdded}
                            file={this.state.file} />
                    </div>

                </div>
            </div>

        </div>)
    }
}