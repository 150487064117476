import React from 'react'
import DropFile from '../../../Pictures/DropFile';
import ImageCard from '../../../Pictures/ImageCard';

export default class Image extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowImage: true,
        }
    }
    handleRemoveCard = () => {
        this.setState({ isShowImage: false })
    }
    render() {
        return (
            <div>
            {this.props.isNew && this.state.isShowImage ?
                <div className="image-card">
                    <ImageCard handleRemoveCard={this.handleRemoveCard} img={`http://api.beelicensed.com/uploads/${this.props.imageFileName}`} />
                </div>
                : null}
            <div className="drop-file">
                <DropFile onFormSubmit={this.props.onFormSubmit}
                    onChange={this.props.onChange}
                    handleFileAdded={this.props.handleFileAdded}
                    file={this.props.file} />
            </div>

        </div>
    )
}
}