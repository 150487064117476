import React from "react";
import SideNavPage from "../../Sidebar/SideNavPage";
import PageMenu from "../../page-menu/PageMenu";
import { Input, InputLabel } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import * as actionCreators from "./store/action/index";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class AddSubCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calender: false,
      Name: "",
      commission: 0,
      OneSubCategory: [],
      codeName: "",
      isNew: false,
      isFixed:true,
      isPercentage:false,
      userType:"fix"
    };
  }
  componentWillMount() {
    if (this.props.match.params.id != "new") {
      this.setState({ isNew: true });
      this.props.GetSubCategoryById(this.props.match.params.id);
    } else {
      this.setState({
        OneCategory: {
          calender: false,
          Name: "",
          commission: 0,
          codeName: "",
        },
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if ((nextProps.OneSubCategory.length > 0) & this.state.isNew) {
      this.setState({ OneSubCategory: nextProps.OneSubCategory[0] });
      this.setState({
        Name: nextProps.OneSubCategory[0]?.name,
        calender: nextProps.OneSubCategory[0]?.calender,
        commission: nextProps.OneSubCategory[0]?.commission,
        codeName: nextProps.OneSubCategory[0]?.codeName,
        userType:nextProps.OneSubCategory[0]?.isFixed?"fix":"per",
        isFixed:nextProps.OneSubCategory[0]?.isFixed,
        isPercentage:nextProps.OneSubCategory[0]?.isPercentage,
      });
    }
  }
  handleRadio = (event) => {
    this.setState({ userType: event.target.value });
    if (event.target.value === "fix") {
      this.setState({ isFixed: true });
      this.setState({ isPercentage: false });
    }
    else if (event.target.value === "per") {
      this.setState({ isFixed: false });
      this.setState({ isPercentage: true });
    }
  }
  saveHandler = () => {
    if (this.state.isNew) {
      var data = {
        Id: this.props.match.params.id,
        calender: this.state.calender,
        name: this.state.Name,
        commission: parseFloat(this.state.commission),
        codeName: this.state.codeName,
        isFixed:this.state.isFixed,
        isPercentage:this.state.isPercentage
      };
      this.props.EditSubCategory(this.props.match.params.id, data);
    } else {
      var data = {
        calender: this.state.calender,
        name: this.state.Name,
        commission: parseFloat(this.state.commission),
        codeName: this.state.codeName,
        isFixed:this.state.isFixed,
        isPercentage:this.state.isPercentage
      };
      this.props.AddSubCategory(data);
    }
  };
  handleForm = (event, val) => {
    if (val === "Name") {
      this.setState({ Name: event.target.value });
    } else if (val === "commission") {
      this.setState({ commission: event.target.value });
    } else if (val === "codeName") {
      this.setState({ codeName: event.target.value });
    }
  };
  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  render() {
    return (
      <div>
        <PageMenu
          heading="Add SubCategories"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          backArrow={true}
          backTitle={"SubCategories"}
          backUrl={"/sub-categories"}
          SaveBtn={true}
          saveHandler={this.saveHandler}
        />
        <div
          className={`upper-form ${this.props.open ? "upper-form-open" : ""}`}
        >
          <div className="form-head">
            <span>Basic Info</span>
          </div>
          <div className="admin-form">
            <div className="flex">
              <InputLabel>SUBCATEGORIE </InputLabel>
              <Input
                type="text"
                value={this.state.Name}
                onChange={(event) => this.handleForm(event, "Name")}
              />
            </div>
            <div className="flex">
              <InputLabel>CODE NAME </InputLabel>
              <Input
                type="text"
                value={this.state.codeName}
                onChange={(event) => this.handleForm(event, "codeName")}
              />
            </div>
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.calender}
                      onChange={this.handleChange}
                      color="primary"
                      name="calender"
                    />
                  }
                  label="TOON KALENDER"
                />
              </FormGroup>
            </div>
            <RadioGroup value={this.state.userType} onChange={this.handleRadio}>
              <FormControlLabel
                value="fix"
                control={<Radio color="primary" />}
                label="Fixed"
              />
              <FormControlLabel
                value="per"
                control={<Radio color="primary" />}
                label="Percentage (%)"
              />
            </RadioGroup>
            <div className="flex">
              <InputLabel>PROVISIE </InputLabel>
              <Input
                type="number"
                value={this.state.commission}
                onChange={(event) => this.handleForm(event, "commission")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  OneSubCategory: state.SubCategoryReducer.OneSubCategory,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GetSubCategoryById: (id) => dispatch(actionCreators.GetSubCategoryById(id)),
    EditSubCategory: (id, data) => dispatch(actionCreators.EditSubCategory(id, data)),
    AddSubCategory: (data) => dispatch(actionCreators.AddSubCategory(data)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddSubCategories)
);
