import React from 'react'
import PageMenu from '../../page-menu/PageMenu'
import Table from '../../Tables/ProviderUserTable'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import './Business.scss'

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('John', 62928398, 'john@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Kevin', 62928398, 'Kevin@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Tom', 62928398, 'Tom@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Sam', 62928398, 'Sam@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Evan', 62928398, 'Evan@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Stall', 62928398, 'Stall@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Brent', 62928398, 'Brent@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
  createData('Holland', 62928398, 'Holland@coupancard.com', 'Thursday 2:20 12-12-2019', 'Thursday 2:20 12-12-2017'),
];

const headCells = [
  { id: 'firstname', numeric: false, disablePadding: true, label: 'firstname' },
  { id: 'lastname', numeric: true, disablePadding: false, label: 'lastname' },
  { id: 'user_name', numeric: true, disablePadding: false, label: 'User Name' },
  { id: 'Country', numeric: true, disablePadding: false, label: 'Country' },
  { id: 'email', numeric: true, disablePadding: false, label: 'email' },
  { id: 'Usergroup', numeric: true, disablePadding: false, label: 'Usergroup' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];
class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Providers: [],
      usergroups:[]
    }
  }
  componentWillMount() {
    this.props.getBuLoyalty();
    this.props.GetAllUserGroup();
  }
  handleStatusChanges = (data) => {
    this.props.ChangeBUStatusById(data);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups && nextProps.usergroups.length > 0) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.Providers && nextProps.Providers.length > 0) {
      this.setState({ Providers: nextProps.Providers[0] });
    }
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      console.log(this.state.usergroups)
      return this.state.usergroups[index].title;
    }
    return value;
  };
  render() {
    return (
      <div>
        <PageMenu heading="Provider Users"
          open={this.props.open ? "page-menu-open" : null}
          AddNew={false}
          btnhead={'Zakelijke'}
          AddNewLink={'/Zakelijke/new'}
        />
        <div className={`upper-table ${this.props.open ? "upper-table-open" : ""}`}>
          <Table headCells={headCells} rows={this.state.Providers}
            handleStatusChanges={this.handleStatusChanges} getLabelUserGroups={this.getLabelUserGroups} editLink={''} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => (
  {
    Providers: state.ProviderReducer.Providers,
    usergroups: state.ProviderReducer.usergroups,
  });

const mapDispatchToProps = dispatch => {
  return {
    getBuLoyalty: () => dispatch(actionCreators.getBuLoyalty()),
    ChangeBUStatusById: (data) => dispatch(actionCreators.ChangeBUStatusById(data)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),

  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Business))