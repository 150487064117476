import React from 'react'

import PageMenu from '../../page-menu/PageMenu'
 
import Table from '../../Tables/Table'
import '../Consumer.scss'

function createData(name, calories, fat) {
    return { name, calories, fat };
  }

  const rows = [
    createData('huis', 'Ja', 'Cadzand, Regio Leiden'),
    createData('huis', 'Ja', 'Cadzand, Regio Leiden'),
    createData('huis', 'Ja', 'Cadzand, Regio Leiden'),
    createData('huis', 'Ja', 'Cadzand, Regio Leiden'),
    createData('huis', 'Ja', 'Cadzand, Regio Leiden'),
    createData('huis', 'Ja', 'Cadzand, Regio Leiden'),
  ];

const headCells = [
    { id: 'BELANG', numeric: false, disablePadding: true, label: 'Belang' },
    { id: 'ACTIEF', numeric: true, disablePadding: false, label: 'Actief' },
    { id: 'REGIO', numeric: true, disablePadding: true, label: 'Regio' },
  ];

 

export default class ConsumerInterst extends React.Component{
    render(){
        return(
            <div>
            

             
                 <PageMenu heading="Consumer Interst" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'Interst'}
                 AddNewLink={'/interst/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                    <Table headCells={headCells} rows={rows} editLink={'interst'}/>
                 </div>
                 </div>
        )
    }
}