import React from 'react';

import PageMenu from '../page-menu/PageMenu'
import ChatSidebar from './ChatSidebar'
import ChatApp from './ChatApp';

import './Chat.scss'

const chatData = [{name:"Saif",date:"23-4-2020"},
                 {name:"Lee",date:"23-4-2020"},
                 {name:"Tom",date:"23-4-2020"}]

 const chatDataContact = [{name:"John",date:"23-4-2020"},
                 {name:"Lorem",date:"23-4-2020"},
                 {name:"Steve",date:"23-4-2020"}]



class Chat extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selectedName:"",
            chat:[{type:"sender",message:"Hola Amigo!",data:"23,03,2020"},
            {type:"reciver",message:"Ah, How are you.",data:"23,03,2020"},
            {type:"sender",message:"YOO! Im fine",data:"23,03,2020"},
            {type:"sender",message:"Hows doing?",data:"23,03,2020"},
            {type:"reciver",message:"Eating",data:"23,03,2020"},
            {type:"sender",message:"Hola Amigo!",data:"23,03,2020"},
            {type:"reciver",message:"Ah, How are you.",data:"23,03,2020"},
            {type:"sender",message:"YOO! Im fine",data:"23,03,2020"},
            {type:"sender",message:"Hows doing?",data:"23,03,2020"},
            {type:"reciver",message:"Eating",data:"23,03,2020"},
            {type:"reciver",message:"Eating",data:"23,03,2020"},
            {type:"reciver",message:"Eating",data:"23,03,2020"},
        ] ,
        value:"",
        isSelected:false,
        toggle:false
        }
    }
    handleChat=(name)=>{
        this.setState({selectedName:name});
        this.setState({isSelected:true});
        this.setState({toggle:false})
    }
    handleSend=()=>{
        var newChat = this.state.chat;
        newChat.push({type:"reciver",message:this.state.value,data:"23,03,2020"})
        this.setState({chat:newChat})
    }
    handleInput=(event)=>{
        this.setState({value:event.target.value});
    }
    handleToggle=()=>{
            this.setState({toggle:true});
    }
    handleToggleClose=()=>{
        this.setState({toggle:false});
}
render(){
    return(
        <div>
         <div>
                 <PageMenu heading="" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={false}
                 btnhead={''}
                 AddNewLink={''}/>


                <div className={`chat-app upper-table ${this.props.open?"chat-app-open":""}`}>

                <ChatSidebar
                 chatDataContact={chatDataContact}
                 toggle={this.state.toggle}
                 handleToggleClose={this.handleToggleClose}
                 chatData={chatData}
                 handleChat={this.handleChat}
                 />
                <ChatApp value={this.state.value} 
                toggle={this.state.toggle}
                handleToggleClose={this.handleToggleClose}
                handleToggle={this.handleToggle}
                handleInput={this.handleInput}
                handleSend={this.handleSend}
                chat={this.state.isSelected?this.state.chat:[]}
                name={this.state.selectedName}
                isSelected={this.state.isSelected}/>
                    </div>
        </div>
             </div>
    )
}

}
export default Chat;