import { axiosClient } from '../../../../../../helper';
import { appStatusAction } from '../../../../../../reducer';


// Loyality Actions
export function DeletSubscription(id){
    return (dispatch) => {

        return axiosClient().post(`/DeletSubscription`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(DeletSubscriptionSuccess(res.data));
            dispatch(appStatusAction("Subscription Successfully Deleted"));
        })
    }
}

export function DeletSubscriptionSuccess(data) {
    return {
        type: "DELETE_SUB",
        DeleteSub:data
    }
}

export function GetSubscriptionById(id){
    return (dispatch) => {

        return axiosClient().post(`/GetSubscriptionById`,{"Id":id}).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetSubscriptionByIdSuccess(res.data.data));
        })
    }
}

export function GetSubscriptionByIdSuccess(data) {
    return {
        type: "GET_SUB",
        OneSub:data
    }
}
export function EditSubscription(data){
    return (dispatch) => {

        return axiosClient().post(`/EditSubscription`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(EditSubscriptionSuccess(res.data.data));
            dispatch(appStatusAction("Subscription Successfully Edited"));
        })
    }
}

export function EditSubscriptionSuccess(data) {
    return {
        type: "GET_SUB",
        OneSub:data
    }
}

export function AddSubscription(data){
    return (dispatch) => {

        return axiosClient().post(`/AddSubscription`,data).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(AddSubscriptionSuccess(res.data.data));
            dispatch(appStatusAction("Subscription Successfully Added"));
        })
    }
}

export function AddSubscriptionSuccess(data) {
    return {
        type: "GET_SUB",
        OneSub:data
    }
}
export function GetAllSubscription() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllSubscription`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllSubscriptionSuccess(res.data.data));
        })
    }
}

export function GetAllSubscriptionSuccess(data) {
    return {
        type: "GET_ALL_SUB",
        subs:data
    }
}
export function GetAllUserGroup() {
    return (dispatch) => {
        return axiosClient().get(`/getUserGroup`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllUserGroupSuccess(res.data.result));
        })
    }
}
export function GetAllUserGroupSuccess(data) {
    return {
        type: "GET_USERGROUP",
        usergroups:data
    }
}

export function GetAllRegion() {
    return (dispatch) => {
        return axiosClient().get(`/GetAllRegion`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            dispatch(GetAllRegionSuccess(res.data.data));
        })
    }
}

export function GetAllRegionSuccess(data) {
    return {
        type: "GET_REGION",
        regions:data
    }
}