import React from 'react';
import ReactDOM from 'react-dom';
import DropzoneComponent from 'react-dropzone-component';
import axios,{post} from 'axios'

export default class Example extends React.Component {
    constructor(props) {
        super(props);

        // For a full list of possible configurations,
        // please consult http://www.dropzonejs.com/#configuration
        this.djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: "text/csv,.csv",
            autoProcessQueue: false
        };
        this.componentConfig = {
            iconFiletypes: ['.csv'],
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
    }
    

    render() {
        const config = this.componentConfig;
        const djsConfig = this.djsConfig;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            addedfile: this.props.handleFileAdded.bind(this),
            thumbnail:this.props.file
        }

        return(<div> 
            
            <DropzoneComponent  config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} />

            </div>)
    }
}