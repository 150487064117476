import React from 'react'
import SideNavPage from '../../../Sidebar/SideNavPage'
import PageMenu from '../../../page-menu/PageMenu'
import * as actionCreators from './store/action/index';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Table from '../../../Tables/SubscriptionTable'



const headCells = [
    { id: 'BESCHRIJVING', numeric: false, disablePadding: true, label: 'BESCHRIJVING' },
    { id: 'MAANDEN', numeric: false, disablePadding: false, label: 'MAANDEN' },
    { id: 'MAAND', numeric: false, disablePadding: true, label: '€ PER MAAND' },
    { id: 'GEBRUIKERS', numeric: false, disablePadding: true, label: 'GEBRUIKERS GROEP'},
  ];

 

class Subscription extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      subs: [],
      DeleteSub: [],
      Delstatus: 0,
      usergroups:[]
    }
  }
  componentWillMount() {
    this.props.GetAllSubscription();
    this.props.GetAllUserGroup();

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.usergroups) {
      this.setState({ usergroups: nextProps.usergroups[0] });
    }
    if (nextProps.subs) {
      this.setState({ subs: nextProps.subs[0] })
    }
  }
  handleDelete = (selected) => {
    var data = this.state.subs;
    selected.forEach(element => {
      this.props.DeletSubscription(element);
      for(var i = 0; i < data.length; i++) {
        if(data[i]._id == element) {
            data.splice(i, 1);
            break;
        }
      }
    }
    );
    this.setState({subs:data});
  }
  getLabelUserGroups = (value) => {
    const index = this.state.usergroups?.findIndex((d) => d._id === value);
    if (index !== -1) {
      return this.state.usergroups[index].title;
    }
    return value;
  };
    render(){
        return(
            <div>
            

             
                 <PageMenu heading="Subscription" 
                 open={this.props.open?"page-menu-open":null}
                 AddNew={true}
                 btnhead={'Subscription'}
                 AddNewLink={'/subscription/new'}/>
                 <div className={`upper-table ${this.props.open?"upper-table-open":""}`}>
                 {this.state.subs && this.state.usergroups?.length > 0?
                    <Table headCells={headCells}
                     handleDelete={this.handleDelete}
                      rows={this.state.subs}
                      getLabelUserGroups={this.getLabelUserGroups}
                       editLink={'subscription'}/>
                       :null}
                 </div>
                 </div>
        )
    }
}
const mapStateToProps = (state) => (
  {
    subs: state.SubReducer.subs,
    DeleteSub: state.SubReducer.DeleteSub,
    usergroups:state.SubReducer.usergroups
  });

const mapDispatchToProps = dispatch => {
  return {
    GetAllSubscription: () => dispatch(actionCreators.GetAllSubscription()),
    DeletSubscription: (id) => dispatch(actionCreators.DeletSubscription(id)),
    GetAllUserGroup: () => dispatch(actionCreators.GetAllUserGroup()),
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscription))